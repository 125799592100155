import { Component, OnInit, ViewChild, Renderer2, ElementRef, Inject, ChangeDetectorRef, OnDestroy, Input, QueryList, EventEmitter, Output, HostListener, ViewChildren, OnChanges, SimpleChanges, AfterViewInit} from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { MessageService } from 'src/app/services/message/message.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import { MatButton, MatOption, MatSelect } from '@angular/material';
import {forkJoin, merge, Observable, of as observableOf, Subject, Subscription} from 'rxjs';
import {catchError, map, startWith, switchMap, takeUntil} from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import { requiredFileType } from '../../../app-file-upload/upload-file-validators';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {ErrorStateMatcher} from '@angular/material/core';
import { ConfirmationDialogComponent } from '../../../components/shared/confirmation-dialog/confirmation-dialog.component';
import { Lightbox } from 'ngx-lightbox';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { SubjectsListComponent } from '../subjects-list/subjects-list.component';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { SocketService } from 'src/app/services/socket/socket.service';
import { Message } from 'src/app/services/socket/model/message';
import { Event } from 'src/app/services/socket/model/event';
import { AbstractControl, FormArray} from '@angular/forms';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DomSanitizer } from '@angular/platform-browser';
import { OutsideClickDirective } from './outsideClickDirective';
import { v4 as uuidv4 } from 'uuid';

import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { OtherOptionsComponent } from '../other-options/other-options.component';
import { LoadingScreenService } from 'src/app/services/loading-screen/loading-screen.service';
import { DashboardDialogNotif, DashboardDialogProjectNotif } from 'src/app/components/dashboard-menu/dashboard-menu.component';
import { SubjectDuppComponent } from '../subject-dupp/subject-dupp.component';
import { element } from 'protractor';
import { ProfileComponent, croppedimagedialog } from 'src/app/pages/profile/profile.component';
import { TransferComponent } from './transfer/transfer.component';
import { CoreServiceService } from 'src/app/services/core/core-service.service';


export interface searchFilter {
  transmitter: string;
  recipient: string;
  copy: string;
  projectId: string;
  dateBefore: any;
  dateDeadline: any;
  dateDeadlineAfter: any;
  dateAfter: any;
  tag: string;
  statut: boolean;
  type: string;
  keyWord: string;
  smartNumber: string;
  drive?: boolean;
}

export interface searchFilterInstant {
  transmitterinstant: string;
  typeinstant: string;
  dateBeforeinstant: any;
  dateAfterinstant: any;
  keyWordinstant: string;
  smartNumber: string;
}

@Component({
  selector: 'app-single-project',
  templateUrl: './single-project.component.html',
  styleUrls: ['./single-project.component.scss'],
  animations: [
    trigger('rotateIcon', [
      state('normal', style({
        transform: 'rotate(0deg)'
      })),
      state('rotated', style({
        transform: 'rotate(135deg)'
      })),
      transition('normal => rotated', animate('0.2s')),
      transition('rotated => normal', animate('0.2s'))
    ])
  ]
})
export class SingleProjectComponent implements OnInit, OnDestroy {
 
  displayedColumns: string[] = ['Message'];
  @Input() ged: boolean = false;
  public minDate = new Date();
  deadline: Date | null;
  deadlineRelanceOne: Date | null;

  isLoadingResults = true;
  isRateLimitReached = false;
  instanttype: string = 'Valeur de mon champ';
  private _album = [];
  searchContact: any;
  writeMode = -1; // 1 = write answer, 2 = comment
  openWriteFace = false;
  showMessages = true;
  divopened = false;
  icons = ["ok.png", "merci.png", "non.png", "pas-dac.png"]

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('divHeaderTable', {static: false}) divHeaderTable: ElementRef;
  @ViewChild('divComSpecMsg', {static: false}) divComSpecMsg: ElementRef;
  @ViewChild('theForm', {static: true}) theForm: FormGroupDirective;
  @ViewChild('theFormInstant', {static: true}) theFormInstant: FormGroupDirective;
  @ViewChild('inputMedia', {static: false}) inputMedia: ElementRef;
  @ViewChild('scrollMechat', { static: false }) scrollMechat: ElementRef;
  @ViewChild('readmsgdiv', { static: false }) readmsgdiv: ElementRef;
  @ViewChild('scrollResultchat', { static: false }) scrollResultchat: ElementRef;
  //@ViewChild('writeMsg') writeMsg: ElementRef;
  inCopy = new FormControl();
  @ViewChild('allSelected', {static: false}) private allSelected: MatOption;
  @ViewChild('selectRecipient', {static: true}) private selectRecipient: MatSelect;
  @ViewChild('selectCopies', {static: true}) private selectCopies: MatSelect;
  @ViewChild('selectSubject', {static: false}) private selectSubject: MatSelect;
  @ViewChild('oneToOneOption', {static: true}) private oneToOneOption: MatOption;
  @ViewChild('descp', {static: false}) descp: ElementRef;
  @ViewChild('commenttext', {static: false}) commenttext: ElementRef;
  @ViewChild('commentresponsetext', {static: false}) commentresponsetext: ElementRef;
  @ViewChild('inputMessage2', {static: false}) inputMessage2: ElementRef;
  @ViewChild('sendmsgdiv', {static: false}) sendmsgdiv: ElementRef;
  @ViewChild('allmsg', {static: false}) allmsg: ElementRef;
  @ViewChild('topface', {static: false}) topface: ElementRef;
  @ViewChild('typeprojectin', {static: false}) typeprojectin: ElementRef;
  @ViewChild('allmsg2', {static: false}) allmsg2: ElementRef;
  @ViewChild('typeproject', {static: false}) checkproject: ElementRef;
  @ViewChild('messageBloc', {static: false}) messageBloc: ElementRef;
  @ViewChild('missionname', {static: false}) missionname: ElementRef;
  @ViewChild('generaluser', {static: true}) private general: MatOption;
  @ViewChild('General', {static: true}) private generalmission: MatOption;
  @ViewChild("selectNotify", {static: true}) selectNotify: MatSelect;
  selectedoption = false;
  

  hideSelectCopy = true;
  hideSelectCopyMenu = false;
  resultsLength = 0;
  perPage = 30;
  limitmsg = 20;
  offsetmsg = 0;
  pageIndex = 0;
  dataloaded = false;
  loadingMessagesTop = false;
  startedPoint = false;
  scollheight = 0;
  scrollposition = 0;
  canload = false;
  nbrloadmsg = 0;
  userAdmin = false;
  dataSource : any[];
  messagesInstant : any[];
  messagesInstantSent : any[];
  messagesResults : any[];//ss
  firstDate : any = "";
  specMsg: any;
  specCom: any;
  specComment = false;
  openmsg: any;
  idmsg: any;
  opened = false;
  specMsgType: any;
  ID: any;
  projectFull: any;
  projectUsers = [];
  userscolor: Map<string, any> = new Map();
  projectmembers = "";
  currentuserProject : any;
  arrayUserCopy = [];
  projectTags  = [];
  arrayQuickAnswer = [];
  newTagsArray: any;
  project: string;
  isCommentActivated = true;
  messageInfo: any;
  chosenFilterProject = null;
  expanded: boolean = true;
  readmsg = 0;
  visibleDivIndex: number | null = null;
  @Input() ongletId: string;
  index_msg = 0;
  limit: number = 190;
  limitcomment: number = 105;
  hasMessage = false;
  currentUser: any;
  currentUserInfo: any;
  sendMessageForm: FormGroup;
  tempMember = [];
  temporarySelectedValues: any[] = [];
  allSelect = false
  sendMessageFormInstant: FormGroup;
  msgsent = false;
  facesent = false;
  sendanswerForm: FormGroup;
  formComment: FormGroup;
  formCommentResponse: FormGroup;
  currentDate = null;
  localDate : String;
  yesterdayDate : String;
  submitted = false;
  menuOuvert = false;
  popupVisible = false;
  @ViewChild('toggleLink', {static: false}) toggleLink: ElementRef;
  private closeTimeout: any;
  typefileselected = "photo";
  secteur = "";
  firstConnection = null;
  files: any = [];
  filesinstant: any = [];
  msgAnswered: any = null;
  numdoc: number = null;
  selectedPdf = null;
  tempMemberUser = [];
  lastFaceNumber = 1;
  showRedBar = false;
  showTool = false;
  hiddenSearchEngin = true;
  hiddenSearchEngindrive  = true;
  ISendMessage = false;
  senderror = false;
  copiesusers = [];
  copyvalidate = false;
  addmissionclicked = 0;
  newProjectSubject = "";
  selectedOptiontag = "";
  selectAll = false;
  searchResults: number[] = [];
  currentIndex = 0; 
  search = false;
  state = 'normal';
  notificationsCount = 0;
  msgInstantRetry = null;
  filesInstantRetry : any = [];
  inviteBadge = 10;
  sentNotifCount = 0;
  notifiedmode = false;
  receiveNotifCount = 0;
  copyNotifCount = 0;
  commentNotifCount = 0;
  sentNotifProjectCount = 0;
  total = 0;
  receiveNotifProjectCount = 0;
  copyNotifProjectCount = 0;
  commentNotifProjectCount = 0;
  private sentNotifCountSubscription: Subscription;
  notifyValue = "ALL";
  updclicked = false;
  updateSubject = "";
  @ViewChildren('bullContaine') myDivs!: QueryList<ElementRef>;
  showEmojiPicker: boolean = false;

  /*private envoyerObs = new Subject();
  public envoyerObs$ = this.envoyerObs.asObservable();*/

  private refreshPageObs = new Subject();
  public refreshPageObs$ = this.refreshPageObs.asObservable(); 
  public isReady:boolean = false;
  lang='fr';
  public curUser = null;


  public iDProjet: any;

  @Output() EventLaunchSearch = new EventEmitter();
  @Output() EventLaunchSearchDoc = new EventEmitter();

  transmitter = null;
  recipient = null;
  copy = null;
  projectId = null;
  dateBefore = null;
  dateDeadline = null;
  dateDeadlineAfter = null;
  dateAfter = null;
  tag = null;
  statut = null;
  type = null;
  keyWord = null;
  smartNumber = null;
  modeDoc = false;
  isSearch = false;
  sujectPlaceholder : any;
  optionselected : any;
  typeproject = "MESSAGES INSTANTANES";
  subscription: Subscription;
  
  transmitterinstant: string;
  typeinstant: string;
  dateBeforeinstant: any;
  dateAfterinstant: any;
  keyWordinstant = "";
  //contacts: Array<any> = [];
  contactsExp: Array<any> = [];
  contactsDest: Array<any> = [];
  projectLists: Array<any> = [];
  userData : any
  chosenFilter: searchFilter;
  chosenFilterInstant: searchFilterInstant;
  public recipientCtrl: FormControl = new FormControl();
  public copyCtrl: FormControl = new FormControl();
  public recipientSearch: FormControl = new FormControl();
  public copySearch: FormControl = new FormControl();
  public themeCtrl: FormControl = new FormControl();
  public transmitterCtrl: FormControl = new FormControl();
  public subjectCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
  saveCtc = null;
  saveCopySearch = null;
  saveCtcTransmitter = null;
  saveRecipient = null;
  saveCopy = null;
  saveTheme = null;
  saveSubject = null;
  echeance = null;
  private = true;
  selectprivate = false;
  selectcopies = false;
  selectteam = false;
  nbrcopies = 0;
  hideSelectCopy2 = true;
  relanceOne = null;
  relanceTwo = null;
  relanceOneDate = null;
  relanceTwoDate = null;
  firstLoad = true;
  socket_service_connection: Subscription;
  socket_service_disconnected: Subscription;
  socket_service_onMessage: Subscription;
  ioConnection: any;
  project_isparent = "PARENT";
  arraySubject = [];
  isUpdate : any;
  isAdmin = false;
  focus_text = true;
  allcopies = false;
  generalselected = false;
  selectedOptions: any[] = [];
  options = this.getUserListWithNoCurrentUser().map(item => item.user.id);
  selectedUsers: any[] = [];
  showselect: boolean = true;
  showsubject: boolean = true;
  libsubject = "";
  instant = false;
  searchinstant = false;
  drive = false;
  messages : any = [];
  searchItem = false;
  tooltipVisible = false;
  showScroll: boolean = true;
  hideNav = Number(localStorage.getItem('hideNav'));

  // Fonction pour passer au résultat suivant
  nextResult() {
    if (this.currentIndex < this.searchResults.length - 1) {
      this.currentIndex++;
      var messageId = this.searchResults[this.currentIndex]['id'];
      this.scrollToMessage(messageId);
    }
  }
  /*
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollHeight = window.scrollY;
    console.log('Scroll Height:', scrollHeight);
    
    // Ajoutez votre logique ici si nécessaire
  }

  @HostListener('window:click', ['$event'])
  detecterClic(event: Event) {
    console.log('Clic détecté!');
    // Ajoutez votre logique ici si nécessaire
  }
  */

  toggleDiv(index: number): void {
    console.log("index : "+index)
    if (this.visibleDivIndex === index) {
      // Si le div est déjà visible, le masquer
      this.visibleDivIndex = null;
    } else {
      // Sinon, afficher le div
      this.visibleDivIndex = index;
    }
  }

  openProfileDialog(){
    const dialogRef = this.dialog.open(ProfileupdDialog, {
      panelClass: 'dialogmember-container',
      data:{idProjet: this.ID, libelle : this.projectFull.libelle, img : this.projectFull.imageUrl}
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed result '+result);
      if(result){
        //TO Do dialog ajout membre 
        //this.fctAddMember();
        (<HTMLInputElement>document.getElementById('project_'+this.ID)).innerHTML = result.libelle;
        if (result.imagechanged) {
          const imageElement = document.getElementById('project_' + this.ID + '_images') as HTMLImageElement;
          if(imageElement) imageElement.src = this.getAvatarlUrl(result.imageUrl);
        }
          this.messageService.setLibelleProject(result.libelle);
          this.projectFull = result;
          this.projectLists.forEach((value,index)=>{
            if(value.id==this.ID) this.projectLists[index].libelle = result.libelle;
          });
      } 
    });
  }

  onDeleteMsg(msgId){
    this.authService.deleteMessageInstant(msgId).subscribe((res) => {
      for (var i = 0; i < this.messagesInstant.length; i++) {
        if(msgId ===  this.messagesInstant[i]['id'])
          this.messagesInstant.splice(i, 1); 
      }
    });
  }

  onDeleteMsgByfake(msgId){
    for (var i = this.messagesInstant.length - 1; i >= 0 ; i--) {
      console.log("parcours");
        if(msgId ===  this.messagesInstant[i]['fakeId']){
          this.messagesInstant.splice(i, 1); 
          console.log("deleted fake")
          break;
        }
    }
  }

  redirectToAnswer(message: any){
    var id = message.parentItem.id;
    if(message.indexdoc){
      id = message.parentItem.filegeneric[message.indexdoc].id;
    }
    // message_ddef970e-07f9-4ca9-8835-8c9bc0e439d7
    /*
    const diff = this.readmsgdiv.nativeElement.parentElement.offsetTop;
    console.log("position : "+diff);
    setTimeout(() => {
      this.scrollMechat.nativeElement.scrollTop = diff;
    }, 200);*/
    alert("id du msg : "+id)
  }

  scrollToUnread() {
    console.log("scrolled into read msg : "+this.scrollMechat.nativeElement.scrollHeight);
    //console.log("scrolled into read msg 2 : "+this.readmsgdiv.scrollHeight);
    //var messageContainer = document.getElementById("scrollMechat");
    //var unreadIndicator = document.getElementById("readmsgdiv");110
    const diff = this.readmsgdiv.nativeElement.parentElement.offsetTop;
    console.log("position : "+diff);
    setTimeout(() => {
      this.scrollMechat.nativeElement.scrollTop = diff;
    }, 200);

    // Scroll to the top of the unread indicator
    //messageContainer.scrollTop = unreadIndicator.offsetTop;
    //this.scrollMechat.nativeElement.scrollTop = this.scrollMechat.nativeElement.scrollHeight - this.readmsgdiv.nativeElement.scrollHeight;
    //this.scrollMechat.nativeElement.scrollTop = 200;
  }

  onDeleteFile(msgId, docId){
    this.authService.deleteFileInstant(msgId, docId).subscribe((res) => {
      if(res){
        for (var i = 0; i < this.messagesInstant.length; i++) {
          if(msgId ===  this.messagesInstant[i]['id'])
            this.messagesInstant[i] = res; 
        }
      }
    });
  }

  updDialog(): void{
    this.updclicked = !this.updclicked;
  }

  deleteTag(sub){
    if(confirm("Voulez vraiment suppprimer le thème "+sub.libelle)) {
      this.authService.deleteTag(sub.id).then((res)=>{
        const index = this.projectTags.findIndex(item => item.id === sub.id);
            if (index !== -1) {
              const elementAaa = this.projectTags.splice(index, 1)[0];
            }
      }).catch(err=>console.log(err))
    }
  }

  showUpdateTag(sub, i){
    this.projectTags.find(item => item.id === sub.id).updated = true;
    this.updateSubject = sub.libelle;
  }

  userIsAdmin(project){
    if (project.creator.id == this.currentUser) {
        return 'admin';
    }
    var member = project['userProject'];
    for (var i = 0; i < member.length; i++) {
      if(member[i].user && member[i].user.id == this.currentUser && member[i].isAdmin == true){
        return 'co-admin';
      }
    }
    return 'membre';
  }

  onDateSelected(event: any): void {
    const selectedDate = event.target.value;
    if(selectedDate){
      this.echeance = moment(selectedDate).format('DD-MM-YYYY').toLocaleString( );
    }
    this.sendMessageForm.get('deadLine').setValue(selectedDate); 
  }

  onRelanceSelected(event: any): void {
    const selectedDate = event.target.value;
    if(selectedDate){
      this.relanceOneDate = moment(selectedDate).format('DD-MM-YYYY').toLocaleString( );
    }
    this.sendMessageForm.get('relanceOne').setValue(selectedDate); 
  }

  transferDocs(transfermsg, iddoc, typeDialog){
    this.dialog.closeAll();
    if (this.showTool == true) {
      this.showTool = false;
      this.messageService.setShowNotifTuto(false);
    }
  }
  goToDashboard(){
    this.router.navigate(['/dashboard'], { queryParams: { projet: this.ID } });
  }

  goToDashboardInstant(){
    this.router.navigate(['/dashboardinst'], { queryParams: { projet: this.ID } });
  }

  transferMessage(transfermsg, idmsg, typeDialog, iddoc = null){
    this.dialog.closeAll();
    console.log("first iddoc "+iddoc)
    if (this.showTool == true) {
      this.showTool = false;
      this.messageService.setShowNotifTuto(false);
    }

    const dialogRef = this.dialog.open(TransferComponent, {
      width: '350px',
      data: { projectUsers: this.projectUsers, projectTags: this.projectTags, idprojet: this.ID, project: this.project, transfermsg: transfermsg, idmsg: idmsg, typeDialog: typeDialog, iddoc },
      panelClass: 'transferclass'
    });

    dialogRef.afterClosed().subscribe(result => {
       //console.log({result:result});
      if (result && result.id == this.ID)
      {
        if(result.type == "instant"){
          this.messagesInstant.push(result.res);
          setTimeout(() => {
            this.scrollToBottom();
          }, 1000);
        } else {
          this.total +=1 ;
          this.changeDiscussionFormat();
        }
      } else if(result && result.id){
        if(result.type == "face"){
          this.router.navigate(['/project/'+result.id]);
        } else {
          this.router.navigate(['/project/'+result.id+'/openmsg']);
        }
      }
    });
    
  }

  scrollToProject() {
    //this.checkproject.nativeElement.scrollIntoView({block: "center"});
  }

  scrollToTop() {
    /*
    if (this.messageBloc) {
      this.messageBloc.nativeElement.scrollIntoView({ block: "start" });
    } else {
      console.error('messageBloc is not defined');
    }*/
      const element = document.querySelector('.messageBloc');
        if (element) {
          console.log("Element found using querySelector", element);
          (element as HTMLElement).scrollTop = 0;
        } else {
          console.error('Element is not found');
        }
  }

  scrollToTopInstant(){
    console.log("scrolleeeed");
    //this.checkproject.nativeElement.scrollIntoView({block: "center"});
  }

  scrollToBottomtChat() {
    const container = this.scrollMechat.nativeElement;
    container.scrollTop = container.scrollHeight;
  }

  // Fonction pour passer au résultat précédent
  previousResult() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      var messageId = this.searchResults[this.currentIndex]['id'];
      this.scrollToMessage(messageId);
    }
  }

  scrollToMessage(messageId: number) {
    const element = document.getElementById('message_'+messageId);
    const elementbody = document.getElementById('body_'+messageId);
    this.renderer.addClass(elementbody, 'active');
    setTimeout(() => {
      this.renderer.removeClass(elementbody, 'active');
    }, 1000);
    /*
    if (element) {
      // Calculer la nouvelle position de défilement
      const newPosition = element.scrollTop + element.getBoundingClientRect().top - offset;
    
      // Faire défiler vers la nouvelle position
      element.scrollTo({ top: newPosition, behavior: 'smooth' });
    }*/
    if (element) {
      element.classList.add('smooth-scroll');
      element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      element.scrollTop -= 60;
      element.classList.remove('smooth-scroll'); // Retirez la classe après l'animation
    }
  }

  replaceAllCaseInsensitive(message: string, searchTerm: string): string {
    const regex = new RegExp(searchTerm, 'gi'); // 'g' pour global (toutes les occurrences), 'i' pour insensible à la casse
    return message.replace(regex, match => `<span style="background-color: yellow;" class="highlighted">${match}</span>`);
  }

  clearAllCaseInsensitive(message: string, searchTerm: string): string {
    const regex = new RegExp(searchTerm, 'gi'); // 'g' pour global (toutes les occurrences), 'i' pour insensible à la casse
    return message.replace(regex, searchTerm);
  }

  get currentResult(): Message | null {
    const currentId = this.searchResults[this.currentIndex];
    return this.messages.find(message => message.id === currentId) || null;
  }

  /*

  highlightText2(message: string, termeRecherche: string): string {
    const regex = new RegExp(termeRecherche, 'gi');
    return message.replace(regex, match => `<p style="background-color: yellow;" class="recherchee">${match}</p>`);
  }
  highlightText3(message: string, termeRecherche: string) {
    console.log("msgg "+ termeRecherche +"----"+this.replaceAllCaseInsensitive(message, "test", "ok"));
    return this.replaceAllCaseInsensitive(message, "test", "ok");
  }

  highlightText(message: string, termeRecherche: string) {
    const regex = new RegExp(this.chosenFilterInstant.keyWordinstant, 'gi');
    return message.replace(regex, "<p style='background-color: yellow;' class='recherchee'>"+this.chosenFilterInstant.keyWordinstant+"</p>");
  }

  replaceAllCaseInsensitive(message: string, searchTerm: string, replacement: string): string {
    const regex = new RegExp(searchTerm, 'gi'); // 'g' pour global (toutes les occurrences), 'i' pour insensible à la casse
    return message.replace(regex, replacement);
  }*/

  showTooltip() {
    this.tooltipVisible = true;
  }

  hideTooltip() {
    this.tooltipVisible = false;
  }

  closeSearch() {
    // Logique pour fermer le composant de recherche
    // Par exemple, réinitialiser les résultats et le champ de recherche
    //this.searchResults = [];
    this.searchItem = false;
    this.currentIndex = 0;
    this.scrollToBottom();
    // Autres actions pour fermer le composant
  }


  selectAllOptions() {
    this.selectedOptions = this.options.map(option => option.id);
  }

  openModal(libelle){
    
  }


  private getNombreObjets(control: AbstractControl): number {
    if (control instanceof FormGroup) {
      return Object.keys(control.controls).length;
    } else if (control instanceof FormArray) {
      return control.controls.length;
    }
    return 0;
  }

  resetforminstant(){
    const textarea = document.getElementById('tchat-text') as HTMLTextAreaElement;
      this.sendMessageFormInstant.get('messageToSend').setValue('');
      this.sendMessageFormInstant.get('files').setValue(null);
      this.sendMessageFormInstant.get('files').setValidators([requiredFileType(['png','jpeg','jpg','mov','mp4'])]); 
      this.sendMessageFormInstant.get('files').updateValueAndValidity();
      this.filesinstant = [];
    if (textarea) {
      textarea.value = '';
    }
  }

  changeDiscussionFormat(){
    this.menuOuvert = false;
    if(this.instant == false){
      this.scrollToTop();
      this.writeMode = -1;
      this.divopened = false;
      this.instant = !(this.instant);
    } else this.instant = !(this.instant);
    
    this.searchinstant = false;
    this.drive = false;
    this.modeDoc = false;
    this.hiddenSearchEngin = true;
    if(this.instant == true){
      this.messagesInstant = [];
      this.typeproject = "MESSAGES INSTANTANES";
      this.authService.obreceiveType.next(this.ID);
      this.EventLaunchSearch.emit(this.chosenFilter);
      this.perPage = 30;
      this.startedPoint = false;
      this.pageIndex = 0;
      this.limitmsg = 20;
      this.nbrloadmsg = 0;
      this.offsetmsg = 0;
      this.canload = false;
      this.getMessagesInstant();
      this.authService.onScrollTop.next("1");
      this.authService.initNotifInstant.emit(this.ID);
      this.socketService.sendInstant({
        id: '',
        action: this.ID,
        type: "READMSG",
        author: this.currentUser,
        author_name: '',
        recipient: '',
        text: '',
        copy: "",
        private: false,
        res: '',
        project_lib: '',
        date: ''
      });/*
      if(this.notificationsCount > 0){
        this.authService.initNotifInstant.emit(this.ID);
      }*/
      //this.authService.typeProject.emit("1");
    } else {
      this.notifiedmode = false;
      localStorage.setItem('notified', "false");
      this.resetforminstant();
      this.resetAllmsgbloc();
      this.selectedOptiontag = "";
      this.notificationsCount = 0;
      this.typeproject = "MESSAGES ORGANISES";
      this.showFileSelector();
      if(this.firstConnection !== 'true'){
        this.firstConnection = 'true';
        localStorage.setItem('firstConnection', 'true');
        this.authService.setUserFirstConnection();
      }
      //this.authService.obreceiveType.next("face");
      //this.divopened = false;
      //this.authService.typeProject.emit("2");
      this.updateNewProjectNotifications(this.ID).subscribe((total) => {
        this.total=total;
      });
      //this.fctEcrire();
      this.showRedBar = false;
      this.launchSearch();
      this.authService.onScrollTop.emit("1");
    }
    //this.authService.hideNotif.emit(""+this.instant);
  }

  updateNewProjectNotifications(id) {
    var total = 0;
    const observables = [
      this.authService.getAllProjectNotification(0, 1, 10, id)
    ]
    return forkJoin(observables).pipe(
      map((responses: any[]) => { // Définir le type explicite ici
        let total = 0;
        responses.forEach((res) => {
          total += res['hydra:member']['all'];
        });
        return total;
    }));

  }

  openDialogNotif(){
    this.dialog.closeAll();
    if (this.showTool == true) {
      this.showTool = false;
      this.messageService.setShowNotifTuto(false);
    }

    const dialogRef = this.dialog.open(DashboardDialogNotif, {
      width: '1400px',
      data: { sentNotifCount: this.sentNotifCount, receiveNotifCount: this.receiveNotifCount, copyNotifCount: this.copyNotifCount, commentNotifCount: this.commentNotifCount }
    });

    dialogRef.afterClosed().subscribe(result => {
       //console.log({result:result});
      if (result)
      {
        if (result.type == 'COMMENT')
        {
          this.router.navigate(['/project/details/' + result.id + '/' + result.number + '/' + result.type]);
        }
        else
        {
          this.router.navigate(['project/details/' + result.id + '/' + result.consult + '/' + result.type]);
        }
      }
    });
    
  }


  openDialogProjectNotif(){
    console.log("teciii "+this.ID);
    this.dialog.closeAll();
    if (this.showTool == true) {
      this.showTool = false;
      this.messageService.setShowNotifTuto(false);
    }

    const dialogRef = this.dialog.open(DashboardDialogProjectNotif, {
      width: '1400px',
      data: { sentNotifCount: this.sentNotifProjectCount, receiveNotifCount: this.receiveNotifProjectCount, copyNotifCount: this.copyNotifProjectCount, commentNotifCount: this.commentNotifProjectCount, idprojet: this.ID }
    });

    dialogRef.afterClosed().subscribe(result => {
       //console.log({result:result});
      if (result)
      {
        if (result.type == 'COMMENT')
        {
          this.router.navigate(['/project/details/' + result.id + '/' + result.number + '/' + result.type]);
        }
        else
        {
          this.router.navigate(['project/details/' + result.id + '/' + result.consult + '/' + result.type]);
        }
      }
    });
    
  }

  changeDriveFormat(){
    this.drive = true;
    this.searchinstant = false;
    this.modeDoc = true;
    this.instant = true;
    
    //this.hiddenSearchEngin = true;
    this.modeDoc = true;

    this.chosenFilter = { 
      transmitter: this.transmitter,
      recipient: this.recipient,
      copy: this.copy,
      projectId: this.projectId,
      dateBefore: this.dateBefore,
      dateAfter: this.dateAfter,
      dateDeadline: this.dateDeadline,
      dateDeadlineAfter: this.dateDeadlineAfter,
      tag: this.tag,
      statut: this.statut,
      type: this.type, 
      keyWord: (this.keyWord ? this.keyWord.trim() : null),
      smartNumber: this.smartNumber, 
      drive: this.drive
    };
    //console.log(' launchSearchDoc '+ JSON.stringify(this.chosenFilter) );
    this.EventLaunchSearchDoc.emit(this.chosenFilter);
  }

  verifAll(){

    if (this.arrayUserCopy.length === this.sendMessageForm.get("inCopy").value.filter(item => item != "0").length) {
      this.showselect = false;
      //this.selectSubject.open();
    }
    this.selectCopies.close();
    setTimeout(() => {
        this.selectSubject.open();
    }, 2);
    
    //console.log("shoen "+this.showselect);
  }

  toggleAll(){
    this.projectUsers.forEach(element => {
      if(this.currentUser != element.user.id){
        this.tempMember.push(element);
        this.copiesusers.push(element.user);
        //console.log("us "+element.user.fullName);
      }
    });
  }

  toggleAllSelection() {
    //console.log("length array before "+this.arrayUserCopy.length);
    //console.log("length selected before"+this.sendMessageForm.get("inCopy").value.filter(item => item !== "0").length);
    //if (this.arrayUserCopy.length === this.sendMessageForm.get("inCopy").length) {
      if (this.arrayUserCopy.length === this.sendMessageForm.get("inCopy").value.filter(item => item != "0").length) {
        this.allSelect = false;
        this.sendMessageForm.controls.inCopy.patchValue([]);
      } else {
        this.allSelect = true;
        this.sendMessageForm.controls.inCopy.patchValue([...this.getUserListWithNoCurrentUser().map(item => item.user.id)]);
      }
      //console.log("length selected "+this.sendMessageForm.get("inCopy").value.length);
      /*
      if (this.selectedoption) {
        this.allSelected.deselect();
      } else this.allSelected.select();
      this.selectedoption = !(this.selectedoption);*/
  }

  toggleAllSelectionNew(matSelect: MatSelect) {
    const isSelected: boolean = matSelect.options
      // The "Select All" item has the value 0
      .filter((item: MatOption) => item.value === 0)
      // Get the selected property (this tells us whether Select All is selected or not)
      .map((item: MatOption) => item.selected)[0];
    // Get the first element (there should only be 1 option with the value 0 in the select)

    if (isSelected) {
      matSelect.options.forEach((item: MatOption) => item.select());
      const allUserIds = this.getUserListWithNoCurrentUser().map(item => item.user.id);
      allUserIds.push(0);
      //this.sendMessageForm.controls.inCopy.patchValue(allUserIds);
      //this.allSelect = true;
      //this.sendMessageForm.controls.inCopy.patchValue([...this.getUserListWithNoCurrentUser().map(item => item.user.id)]);
    } else {
      matSelect.options.forEach((item: MatOption) => item.deselect());
      //this.allSelect = false;
        //this.sendMessageForm.controls.inCopy.patchValue([]);
    }
  }

  validateSelection(){
    this.sendMessageForm.controls.inCopy.patchValue([...this.temporarySelectedValues]);
    this.copiesusers = [];
    this.copyvalidate = true;
    for (let indexCopy = 0; indexCopy < this.sendMessageForm.value.inCopy.length; indexCopy++) {
        for (let j = 0; j < this.arrayUserCopy.length; j++) {
          if(this.arrayUserCopy[j].user.id ==  this.sendMessageForm.value.inCopy[indexCopy]){
          this.copiesusers.push(this.arrayUserCopy[j].user);
        }
        }
    }
  }

  changeCopies(event: any) {
    /*
    this.copiesusers = [];
    for (let indexCopy = 0; indexCopy < this.sendMessageForm.value.inCopy.length; indexCopy++) {
        for (let j = 0; j < this.arrayUserCopy.length; j++) {
          if(this.arrayUserCopy[j].user.id ==  this.sendMessageForm.value.inCopy[indexCopy]){
          this.copiesusers.push(this.arrayUserCopy[j].user);
        }
        }
    }*/
    this.temporarySelectedValues = event.value.filter(item => (item != 0 && item != undefined));
    console.log('Sélections temporaires :', this.temporarySelectedValues);
  }

  /*
  toggleAllSelection() {
    console.log("yes "+this.allSelected.selected);
    if(this.allSelected.selected){
      this.allSelected.deselect();
      //this.sendMessageForm.controls.inCopy.patchValue([]);
    } else {
      this.allSelected.select();
      //this.sendMessageForm.controls.inCopy.patchValue([...this.getUserListWithNoCurrentUser().map(item => item.user.id)]);
    }
    //this.sendMessageForm.controls.inCopy.patchValue(this.arrayUserCopy.map(item => item.user.id));
    //this.toppings.patchValue(this.toppingList.map(item => item));
    /*
    this.toppingList.forEach(element => {
      this.toppings.patchValue(toppingList.map(item => item));
    });*/
    /*
    this.copySearch.reset();  
    //console.log(this.allSelected)
    if (this.allSelected.selected) {
      console.log("deselect");
      this.arrayUserCopy.forEach(item => item.selected = true);
      this.sendMessageForm.controls.inCopy.patchValue([...this.getUserListWithNoCurrentUser().map(item => item.user.id)]);
    } else {
      console.log("select");
      this.sendMessageForm.controls.inCopy.patchValue([]);
    }
   // console.log(this.sendMessageForm.value);
  }*/

  getIndexByLibelle(libelle){
    //alert("okkk");
    const index = this.projectTags.findIndex(element => element.libelle === libelle);
    //console.log("index "+index);
    return index+1;
  }

  deletecopy(user, matSelect: MatSelect){
    const index = this.sendMessageForm.value.inCopy.indexOf(user.id);
    if (index !== -1) {
      this.sendMessageForm.value.inCopy.splice(index, 1);
      //alert(JSON.stringify(this.sendMessageForm.value.inCopy))
    }
    const index2 = this.tempMemberUser.indexOf(user);
    if (index2 !== -1) {
      this.tempMemberUser.splice(index, 1);
    }/*
    matSelect.options.forEach((item: MatOption) => {
      if (item.value === user.id || item.value === 0) {
        item.deselect();
      }
    });*/

    /*
    const isSelected: boolean = matSelect.options
        // The "Select All" item has the value 0
        .filter((item: MatOption) => item.value === 0)
        // Get the selected property (this tells us whether Select All is selected or not)
        .map((item: MatOption) => item.selected)[0];
      // Get the first element (there should only be 1 option with the value 0 in the select)
  
      if (isSelected) {
        matSelect.options.forEach((item: MatOption) => item.select());
        const allUserIds = this.getUserListWithNoCurrentUser().map(item => item.user.id);
        allUserIds.push(0);
        //this.sendMessageForm.controls.inCopy.patchValue(allUserIds);
        //this.allSelect = true;
        //this.sendMessageForm.controls.inCopy.patchValue([...this.getUserListWithNoCurrentUser().map(item => item.user.id)]);
      } else {
        matSelect.options.forEach((item: MatOption) => item.deselect());
        //this.allSelect = false;
          //this.sendMessageForm.controls.inCopy.patchValue([]);
      }*/
  }
  
  changeRecipient(){
      this.generalselected = false;
    this.private = false;
    this.sendMessageForm.controls.inCopy.patchValue([]);
    this.copiesusers = [];
    this.allSelect = false;
    //this.changeNotify();
    //this.toggleAllSelection();
    this.getUserListWithNoCurrentUser();
  }


  openSelect(){
    this.showselect = true;

    setTimeout(() => {
      this.selectCopies.open();
  }, 0.5);
    
  }

  resetMsgForm(){
    this.sendanswerForm.get('files').clearValidators();
    this.sendanswerForm.get('files').updateValueAndValidity();
    this.files = [];
    const elementtous: HTMLElement = document.getElementById('divtous') as HTMLElement
    elementtous.innerHTML = '';
    this.copiesusers = [];
    this.sendMessageForm.controls.inCopy.patchValue([]);
    this.sendMessageForm.reset();
  }

  converterDate(datestring){
    return datestring.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
  }

  onSelectClosed(){
    this.addmissionclicked =0;
    //this.updclicked = false;
    if(this.sendMessageForm.value.subProject.libelle){
      this.showsubject = false;
      setTimeout(() => {
        const libsubjectvalue: HTMLElement = document.getElementById('libsubjectvalue') as HTMLElement
        libsubjectvalue.innerHTML = this.sendMessageForm.value.subProject.libelle;
      }, 0.5);
    }
  }

  closeMsgBloc(){
    //alert("sub "+this.sendMessageForm.value.subProject.libelle)
    this.selectedOptiontag = "";
    this.resetQuestionFrom();
    this.scrollToTop();
    this.divopened = !this.divopened;
    this.hiddenSearchEngin = true;
    this.private = false;
    if(this.modeDoc && this.divopened){
      this.resetSearch();
    }
    if(this.divopened === true){
      this.showScroll = false;
      this.scrollToProject();
      setTimeout(() => {
          //this.selectRecipient.open();
      }, 0.1);
    } else {
      this.showScroll = true;
      this.writeMode = -1;
    }
  }

  addProjectToArray(){
    // this.arraySubject.push({libelle:this.newProjectSubject})
    //var newProjectSubject = (<HTMLInputElement>document.getElementById("missionname")).value;
    var newProjectSubject = this.missionname.nativeElement.value;
    if(newProjectSubject.trim() == "" || newProjectSubject.trim().length == 0){
      alert("Veuillez entrer un thème"+this.project);
      return;
    }
    this.authService.addTagToProject({
      libelle:newProjectSubject,
      project:this.project
    }).then((res)=>{
      this.newProjectSubject = "";
      this.missionname.nativeElement.value = "";
      newProjectSubject = "";
      this.addmissionclicked = 0;
      this.projectTags.push(res);
      this.projectTags.sort((a, b) => {
        if ( a.libelle.toLowerCase() < b.libelle.toLowerCase() ){
          return -1;
        }
        if ( a.libelle.toLowerCase() > b.libelle.toLowerCase() ){
          return 1;
        }
        return 0;
      })
      this.sendMessageForm.get('subProject').setValue(res); 
      this.showValue();
      this.selectSubject.close();
      setTimeout(() => this.descp.nativeElement.focus(), 0);
      this.socketService.sendInstant({
        id: "",
        action: this.ID,
        type: "ADDSUBJECT",
        author: this.currentUser,
        recipient: "",
        text: "",
        copy: "",
        private: false,
        res: res,
        date: ""
      });
      //this.getProjectInfo();
    }).catch(err=>{
      console.log(err)
    });
  }

  FocusWriteMsg(){
    
    setTimeout(() => this.inputMessage2.nativeElement.focus(), 0);
  }

  showValue(){
    //alert("text "+this.sendMessageForm.value.subProject.libelle);
    console.log("testedd")
    this.showsubject = false;
    setTimeout(() => {
      const libsubjectvalue: HTMLElement = document.getElementById('libsubjectvalue') as HTMLElement
      libsubjectvalue.innerHTML = this.sendMessageForm.value.subProject.libelle;
    }, 0.5);
    
    setTimeout(() => this.descp.nativeElement.focus(), 0);
  }


  changesub(){
    //this.textArea.focus();
    //this.toggleAllSelection();
    /*
    console.log("testedd")
    this.showsubject = false;
    setTimeout(() => {
      const libsubjectvalue: HTMLElement = document.getElementById('libsubjectvalue') as HTMLElement
      libsubjectvalue.innerHTML = this.sendMessageForm.value.subProject.libelle;
    }, 0.5);
    
    setTimeout(() => this.descp.nativeElement.focus(), 0);*/
    //document.getElementById("descp").focus();
    //this.elementRef.nativeElement.focus();
    //alert("yeees");
  }

  openSubject(){
    this.showsubject = true;
    this.selectSubject.open()
  }

  updateProjectNotifications() {
    this.total = 0;
    this.authService.getOnlyProjectNotification(0, 1, 1000, this.ID).subscribe((res) => {
      this.sentNotifProjectCount = res['hydra:totalItems'];
      this.total += this.sentNotifProjectCount;
    });

    this.authService.getOnlyProjectNotification(1, 1, 1000, this.ID).subscribe((res) => {
      this.receiveNotifProjectCount = res['hydra:totalItems'];
      this.total += this.receiveNotifProjectCount;
    });

    this.authService.getOnlyProjectNotification(2, 1, 1000, this.ID).subscribe((res) => {
      this.copyNotifProjectCount = res['hydra:totalItems'];
      this.total += this.copyNotifProjectCount;
    });

    this.authService.getOnlyProjectNotification(3, 1, 1000, this.ID).subscribe((res) => {
      this.commentNotifProjectCount = res['hydra:totalItems'];
      this.total += this.commentNotifProjectCount;
    });

  }
  /*
  async mainFunction() {
    await this.ngAfterViewInit();
    setTimeout(() => this.selectRecipient.open(), 7000);
    }*/
  

  ngAfterViewInit() { 
    this.sentNotifCountSubscription = this.messageService.getSentNotifCount()
    .subscribe(count => {
      this.sentNotifCount = count;
    });
    this.sentNotifCountSubscription = this.messageService.getReceiveNotifCount()
    .subscribe(count => {
      this.receiveNotifCount = count;
    });
    this.sentNotifCountSubscription = this.messageService.getCopyNotifCount()
    .subscribe(count => {
      this.copyNotifCount = count;
    });
    this.sentNotifCountSubscription = this.messageService.getCommentNotifCount()
    .subscribe(count => {
      this.commentNotifCount = count;
    });
    
    
    /*
    if (document.getElementById('selectRecipient') && (this.openWriteFace == true)) {
      setTimeout(() => this.selectRecipient.open(), 7000);
    }*/
    // setTimeout(() => this.selectSubject2.open(), 1800);
    //this.selectSubject2.open();
    //this.mainFunction();
    //setTimeout(() => this.selectRecipient.open(), 3000);
    /*
    this.elementRef.focus();
    this.mySelect.open();
    document.getElementById("btnsub").click();
    console.log("okkk");
    let el: HTMLElement = this.btnsub;
    this.btnsub.click();*/
    //this.selectBtn.nativeElement.click();
    //let el: HTMLElement = this.btnsub.nativeElement;
    //el.click();
    //this.selectSubject.nativeElement.click();
  }


  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public  dialog: MatDialog,
    private messageService: MessageService,
    private coreService: CoreServiceService,
    private socketService: SocketService,
    private loadingScreenService: LoadingScreenService,
    private translate: TranslateService,
    private overlay: Overlay,
    private renderer: Renderer2,
    protected _sanitizer: DomSanitizer,
    private _lightbox: Lightbox/*,
    private clipboardService: ClipboardService,
    private clipboard: Clipboard*/) {
    
    this.userData = JSON.parse(localStorage.getItem('currentUser'))

    this.echeance = null;
    //this.divopened = true;

    this.sendMessageForm = this.formBuilder.group({
      subProject: ['', Validators.required],
      newSubProject: '',
      recipient: ['', Validators.required],
      //inCopy: [],
      inCopy: new FormControl([]),
      private: false,
      notify: "ALL",
      deadLine: null,
      general: null,
      relanceOne: null,
      relanceTwo: null,
      relanceTree: null,
      messageToSend: '',
      //messageToSend: ['', [Validators.required, Validators.minLength(1)]],
      files: [null, [requiredFileType(['png','jpeg','jpg','mov','mp4'])]]
    });

    this.sendMessageFormInstant = this.formBuilder.group({
      messageToSend: ['', [Validators.required, Validators.minLength(1)]],
      files: [null, [requiredFileType(['png','jpeg','jpg','mov','mp4'])]]
    });

    this.sendanswerForm = this.formBuilder.group({
      answerMessage: ['', [Validators.required, Validators.minLength(1)]],
      files: [null, [requiredFileType(['png','jpeg','jpg','mov','mp4'])]]
    });

    this.formComment = this.formBuilder.group({
      commentMessage: ['', [Validators.required, Validators.minLength(1)]],
      files: [null, [requiredFileType(['png','jpeg','jpg','mov','mp4'])]]
    });

    this.formCommentResponse = this.formBuilder.group({
      commentMessage: ['', [Validators.required, Validators.minLength(1)]],
      files: [null, [requiredFileType(['png','jpeg','jpg','mov','mp4'])]]
    });

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    delete currentUser.token;
    delete currentUser.refresh_token;
    delete currentUser.roles;
    this.curUser = currentUser;

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))['id'];
    this.currentUserInfo = JSON.parse(localStorage.getItem('currentUser'));

  }

  ngOnDestroy() {
    /*
    if (this.socket_service_connection) {
      this.socket_service_connection.unsubscribe();
    }
    if (this.socket_service_disconnected) {
      this.socket_service_disconnected.unsubscribe();
    }
    if (this.socket_service_onMessage) {
      this.socket_service_onMessage.unsubscribe();
    }
    this._onDestroy.next();
    this._onDestroy.complete();
    */
  }

  clickaddmission(){
    this.addmissionclicked = 1;
    setTimeout(() => this.missionname.nativeElement.focus(), 200);
  }

  cancelSubject(){
    this.addmissionclicked = 0;
  }

  cancelUpdSubject(){
    this.updclicked = false;
    this.updateSubject = "";
    this.projectTags.map((value, index) => {
      value.updated = false;
    });
  }

  updateTag(tag, i){
    this.updateSubject = (<HTMLInputElement>document.getElementById("updateSubject")).value;
    if (this.updateSubject.trim().length == 0) {
      alert("Veuillez entrer un thème");
      return;
    }
    this.authService.updateTag(tag.id,{libelle:this.updateSubject}).then((res)=>{
      this.projectTags[i].libelle = this.updateSubject;
      this.cancelUpdSubject();
      this.socketService.sendInstant({
        id: "",
        action: this.ID,
        type: "RENAMESUBJECT",
        author: this.currentUser,
        recipient: "",
        text: "",
        copy: "",
        private: false,
        res: res,
        date: ""
      });
    }).catch(err=>console.log(err))
  }


  protected themeProject() {
    
    if(this.saveSubject == null){
      this.saveSubject = this.projectTags;
    }

    let search = this.themeCtrl.value;
    if (!search) {
      this.projectTags = this.saveSubject;
      return;
    } else {
      search = search.toLowerCase();
      this.projectTags = this.saveSubject.filter(bank => bank.libelle.toLowerCase().indexOf(search) > -1);
    }
  }

  protected filterSubjectSearch() {
    console.log("start : ")
    this.projectTags.forEach(item => console.log("lib : "+item.libelle));
    if(this.saveTheme == null){
      this.saveTheme = this.projectTags;
    }

    let search = this.subjectCtrl.value;
    if (!search) {
      this.projectTags = this.saveTheme;
      return;
    } else {
      search = search.toLowerCase();
      this.projectTags = this.saveTheme.filter(bank => bank.libelle.toLowerCase().indexOf(search) > -1);
    }
    
    console.log("end  :------------- ")
    this.projectTags.forEach(item => console.log("lib : "+item.libelle));
  }

  protected filterRecipient() {
    if(this.saveCtc == null){
      this.saveCtc = this.contactsDest;
    }

    let search = this.recipientCtrl.value;
    if (!search) {
      this.contactsDest = this.saveCtc;
      return;
    } else {
      search = search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      this.contactsDest = this.saveCtc.filter(bank => bank.givenName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1 || bank.familyName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1 );
    }
  }

  protected filterCopy() {
    console.log("changed"+this.copyCtrl.value)
    
    if(this.saveCopySearch == null){
      this.saveCopySearch = this.projectUsers;
    }

    let search = this.copyCtrl.value;
    if (!search) {
      this.projectUsers = this.saveCopySearch;
      return;
    } else {
      search = search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      this.projectUsers = this.saveCopySearch.filter(bank => bank.user.givenName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1 || bank.user.familyName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1 );
    }
  }

  protected filterRecipientSearch(){
    if(this.saveRecipient == null){
      this.saveRecipient = this.projectUsers;
    }

    let search = this.recipientSearch.value;
    if (!search) {
      this.projectUsers = this.saveRecipient;
      return;
    } else {
      search = search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      this.projectUsers = this.saveRecipient.filter(bank => bank.user.givenName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1 || bank.user.familyName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1 );
    }
  }

  protected filterCopytSearch(){
    if(this.saveCopy == null){
      this.saveCopy = this.getUserListWithNoCurrentUser().map(item => item.user.id);
    }

    let search = this.copySearch.value;
    //alert("search "+search)
    if (!search) {
      this.arrayUserCopy = this.getUserListWithNoCurrentUser();
      return;
    } else {
      search = search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      this.arrayUserCopy = this.arrayUserCopy.filter(bank => bank.user.givenName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1 || bank.user.familyName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1 );
    }
  }

  protected filterTransmitter(){
    if(this.saveCtcTransmitter == null){
      this.saveCtcTransmitter = this.contactsExp;
    }

    let search = this.transmitterCtrl.value;
    if (!search) {
      this.contactsExp = this.saveCtcTransmitter;
      return;
    } else {
      search = search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      this.contactsExp = this.saveCtcTransmitter.filter(bank => bank.givenName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1 || bank.familyName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1 );
    }
  }
  


  ngOnInit() {
    this.authService.obreadMessage.subscribe((indexMessage: number) =>{ 
      this.readmsg = indexMessage;
    });

    this.authService.closeBloc.subscribe((term) =>{ 
      if(term == 1){
        this.resetSearch();
      }
    });

    this.authService.scrollTop.subscribe((term) =>{ 
      if(term == 1){
        this.scrollToTop();
      }
    });
    //this.authService.initHeader.emit("1");
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    
    this.secteur = localStorage.getItem('secteur');
    this.firstConnection = localStorage.getItem('firstConnection');

    this.currentDate = dd + '/' + mm + '/' + yyyy;
    this.localDate = dd + '/' + mm + '/' + yyyy;

    const hier = new Date(today);
    hier.setDate(today.getDate() - 1);

    const jourHier = hier.getDate().toString().padStart(2, '0');
    const moisHier = (hier.getMonth() + 1).toString().padStart(2, '0');
    const anneeHier = hier.getFullYear();

    this.yesterdayDate = `${jourHier}/${moisHier}/${anneeHier}`;
    
    this.recipientCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterRecipient();
      });

    this.copyCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCopy();
      });

    this.transmitterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterTransmitter();
    });

    
    this.themeCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.themeProject();
      });

    this.recipientSearch.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterRecipientSearch();
    });

    this.copySearch.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCopytSearch();
    });
    
    this.subjectCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterSubjectSearch();
    });

    this.route.paramMap.subscribe(params => {
      this.divopened = false;
      this.ID = params.get('ID');
      this.specComment = false;
      this.iDProjet = params.get('ID');
      //console.log('getCurrentProject paramètre id ==> '+params.get('ID'));
      //console.log('getCurrentProject paramètre specMsg ==> '+params.get('specMsg'));
      this.specMsg = params.get('specMsg');
      this.openmsg = params.get('openmsg');
      this.idmsg = params.get('idmsg');
      console.log("iddd specMsg"+this.specMsg)

      

    /*
    this.authService.obNotifiedMode.subscribe((res: any) =>{ 
      this.notifiedmode = res['notifmode'];
      this.ID = res['id'];
      this.instant = false;
      console.log("nooot "+this.notifiedmode)
    });*/
      
      //this.divopened = true;
      //alert("open "+this.openmsg);
      this.specMsgType = params.get('type');
      if (this.specMsg) {
        this.instant = false;
        //console.log({specMsg : this.specMsg});
        this.showRedBar = true;
        console.log("showrefd "+this.showRedBar);
        this.authService.obprojectChosen.next(this.specMsg);
      }
      this.isSearch = false;
      this.getCurrentProject();

      

      // init instant format
      this.drive = false;
      if (this.openmsg) {
        
        this.instant = true;
        //this.authService.hideNotif.emit(""+this.instant);
        this.modeDoc = false;
        this.hiddenSearchEngin = true;
        this.resetSearchInstant();
        this.searchinstant = false;
        this.messagesInstant = [];
        this.perPage = 30;
        this.startedPoint = false;
        this.pageIndex = 0;
        this.limitmsg = 20;
        this.nbrloadmsg = 0;
        this.canload = false;
        this.offsetmsg = 0;
        if(this.notificationsCount > 20) {
          var rest = Math.floor(this.notificationsCount / 20);
          this.limitmsg = 20 * (rest + 1);
          console.log("resss "+rest)
        }
        this.getMessagesInstant();
        if(this.idmsg){
          this.scrollToMsg(this.idmsg);
        }
      }
      if(this.instant == true){
        this.scrollToTopInstant();
        this.typeproject = "MESSAGES INSTANTANES";
        this.authService.obreceiveType.next(this.ID);
        this.socketService.sendInstant({
          id: '',
          action: this.ID,
          type: "READMSG",
          author: this.currentUser,
          author_name: '',
          recipient: '',
          text: '',
          copy: "",
          private: false,
          res: '',
          project_lib: '',
          date: ''
        });
        //this.authService.typeProject.emit("1");
      } else {
        this.typeproject = "MESSAGES ORGANISES";
        //this.authService.obreceiveType.next("face");
        //this.authService.typeProject.emit("2");
      }

      if(this.menuOuvert == true){
        this.showFileSelector();
      }

      // end init instant 
      this.resetQuestionFrom();
      this.resetAnswerFrom();
      this.resetCommentForm();
      this.writeMode = -1;
      this.openWriteFace = false;
      this.senderror = false;
      this.showMessages = true;
      this.submitted = false;
      this.paginator.pageIndex = 0;
      this.dataSource = [];
      this.messagesInstant = [];
      this.resultsLength = 0; 
      this.lastFaceNumber = 1;
      this.chosenFilterProject = null;
      this.isReady = false;
      this.userAdmin = false;

      if (this.messageService.getShowCreateProjectTuto() == false) {
        this.showTool = this.messageService.getShowProjectTutobis();
      }
      this.selectedOptiontag = "";
      this.showsubject = true;
      //console.log('shown '+this.showsubject);

      
      //this.getProjectInfo();
      

      //info du filtre
      //this.getInfoProject(); 


      this.hiddenSearchEngin = true;
      this.updateProjectNotifications();

      setTimeout(() => {
       window.scroll(0,0);
      }, 500);

      //let el: HTMLElement = this.btnsub.nativeElement;
      
      //document.getElementById("btnsub").click();
      //this.btnsub.nativeElement.click();

    });


    this.messageService.getShowCreateProjectTutoIsFalse().subscribe(( ) =>{ 
      this.showTool = this.messageService.getShowProjectTutobis();
    });

    this.messageService.getTranslate().subscribe((data) => {
      this.lang = data;
    });

    this.messageService.getLibelleProject().subscribe((data) => {
      this.projectFull.libelle = data
    });

    this.coreService.toggleSidebar.subscribe((res) => {
      this.hideNav = Number(res);
    });


    //this.divHeaderTable.nativeElement.scrollIntoView();
    
    this.expanded = true;
    this.ged = false;
    

    this.messageService.getWriteMessage()
    .subscribe(
      (data)=>{
        // this.fctEcrire();
      }
    )

    this.getAnswerListe();
    this.authService.obprojectChosen.subscribe((idMessage) =>{ 
      this.smartNumber = idMessage;
      //console.log('dans le smarnumber idmessage ==> '+idMessage);
    });

    this.authService.obreceiveAsread.subscribe((idProject) => {
      console.log("read from single")
      if(this.total > 0){
        this.total -= 1;
      }
      //this.updateProjectNotifications();
    });

    this.authService.obchangeinterface.subscribe((instant) => {
      this.changeDiscussionFormat();
    });

    this.messageService.obdisableMessage.subscribe((idProject) => {
      console.log("disable from single")
      this.updateProjectNotifications();
    });

    this.authService.obreceiveMessage.subscribe((res) =>{ 
      console.log("received single");
      if (!res["id"]) {
        return;
      }
      var length = this.messagesInstant.length;
      var index = length - 1;
      console.log("dernier "+index)
      console.log("length "+length);
      console.log("id "+res["id"])
      if(length > 0 && this.messagesInstant[index].id == res["id"]){
        return;
      }
      this.offsetmsg += 1;
      this.messagesInstant.push(res);
          if(this.instant == true){
            setTimeout(() => {
              this.notificationsCount = 0;
              this.scrollToBottom();
            }, 200);
      }
      //this.getMessageInstant(idMessage);
    });

    this.authService.obreceiveFace.subscribe((res) =>{ 
      if(this.dataSource[0] && this.dataSource[0].id == res["id"]){
        return;
      }
      this.getMessageFace(res);
      this.getProjectExp();
      this.getProjectDest();
    });

    this.authService.obreceiveComment.subscribe((idMessage) =>{ 
      const index = this.dataSource.findIndex(message => message.id === idMessage);
      if (index !== -1) {
        this.getMessageComment(idMessage, index);
      }
    });

    this.authService.obreceiveResponse.subscribe((idMessage) =>{ 
      const index = this.dataSource.findIndex(message => message.id === idMessage);
      if (index !== -1) {
        this.getMessageComment(idMessage, index);
      }
    });

    this.authService.obreceiveAdmin.subscribe((res) =>{ 
      this.userAdmin = true;
    });

    this.authService.obDeletedmin.subscribe((res) =>{ 
      this.userAdmin = false;
    });

    this.authService.obResponseLu.subscribe((idMessage) =>{ 
      console.log("reponse lu")
      const index = this.dataSource.findIndex(message => (message.answer && (message.answer.id === idMessage)));
      if (index !== -1) {
        console.log("reponse index : "+index)
        this.dataSource[index].answer.isRecipientRead = true;
      }
    });

    

    this.authService.obQuestionLu.subscribe((idMessage) =>{ 
      console.log("question lu")
      const index = this.dataSource.findIndex(message => message.id === idMessage);
      if (index !== -1) {
        console.log("reponse index : "+index)
        this.dataSource[index].isRecipientRead = true;
      }
    });

    this.authService.obCommentLu.subscribe((message : any) =>{ 
      // TO DO OR VERIFY
      console.log("commentaire lu msg : "+message.id+"  id comment "+message.res);
      const index = this.dataSource.findIndex(item => item.id === message.id);
      if (index !== -1) {
        console.log("index msg : "+index);
        const indexcom  = this.dataSource[index].comment.findIndex(comment => comment.id === message.res);
        if (indexcom !== -1) {
          console.log("index com : "+indexcom);
          //element.copy[i].isRead
          this.dataSource[index].comment[indexcom].copy.forEach(element => {
            element.isRead = true;
          });
          //.isRecipientRead = true;
        }
      }
      //this.dataSource[indexmsg].comment[indexcmt].copy[i].isRead = true;
    });

    this.authService.obSubAdd.subscribe((message : any) =>{ 
      console.log("subject added : "+message.id);
      const index = this.projectTags.findIndex(item => item.id === message.id);
      if (index == -1) {
        this.projectTags.push(message);
        this.projectTags.sort((a, b) => {
          if ( a.libelle.toLowerCase() < b.libelle.toLowerCase() ){
            return -1;
          }
          if ( a.libelle.toLowerCase() > b.libelle.toLowerCase() ){
            return 1;
          }
          return 0;
        })
      }
    });

    this.authService.obSubRename.subscribe((message : any) =>{ 
      console.log("subject renamed : "+message.id);
      const index = this.projectTags.findIndex(item => item.id === message.id);
      if (index !== -1) {
          this.projectTags[index].libelle = message.libelle ;
      }
    });
    

    this.messageService.getMessageIsSend().subscribe(() => {
       this.showTool = false; 
       this.messageService.setShowProjectTutobis(false);
    });

    this.hiddenSearchEngin = true;
    

  }
/*
  getMessageFace(idmessage) {
    if (!idmessage) {
      return;
    }
    this.authService.getMessageFace(idmessage)
      .subscribe(res => {
        this.dataSource.unshift(res['hydra:member'][0]);
        var exist = false;
        if(res['hydra:member'][0].recipient.id == this.currentUser){
          this.total += 1;
            return;
        } else {
          res['hydra:member'][0].copy.forEach(element => {
            if(element.user.id == this.currentUser ){
              console.log("trouvé")
              this.total += 1;
              return;
            }
          })
        }
      }, (error) => {
    });
  }*/

  getMessageFace(res) {
    this.dataSource.unshift(res);
    if(res.recipient.id == this.currentUser){
      this.total += 1;
      //this.new_message = true;
        return;
    } else if(res.author.id == this.currentUser){
      this.total += 1;
        return;
    } else {
      res.copy.forEach(element => {
        if(element.user.id == this.currentUser ){
          console.log("trouvé")
          //this.new_message = true;
          this.total += 1;
          return;
        }
      })
    }
  }

  getMessageComment(idmessage, index) {
    if (!idmessage) {
      return;
    }
    this.authService.getMessageFace(idmessage)
      .subscribe(res => {
       //this.dataSource[index] = res['hydra:member'][0];
       this.dataSource.splice(index, 1);
       console.log("msg "+JSON.stringify(index))
       this.dataSource.unshift(res['hydra:member'][0]);
      }, (error) => {
    });
  }


  getMessageInstant(idmessage) {
    //console.log('getCurrentProject paramètre id ==> '+this.ID);

    if (!idmessage) {
      return;
    }

    this.authService.getMessageInstant(idmessage)
      .subscribe(res => {
        this.messagesInstant.push(res);
        if(this.instant == true){
          setTimeout(() => {
            this.notificationsCount = 0;
            this.scrollToBottom();
          }, 200);
        }

      }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
    });
  } 

  private initIoConnection(id_project): void {
    // get All projects
    //this.socketService.initSocket();
    /*
    this.socketService.joinRoom(id_project);
    this.socket_service_onMessage = this.ioConnection = this.socketService.onMessage()
    .subscribe((message: Message) => {
      console.log('message reçu ==> '+ JSON.stringify(message));
      if (message.type == "CONVERSATION" && message.id !== this.currentUser) {
        this.new_message = true;
      }
      if (message.type == "INSTANT" && message.author !== this.currentUser) {
        console.log('message reçu instant '+message.author);
        this.getMessageInstant(message.id);
      }
    });


    this.socket_service_connection = this.socketService.onEvent(Event.CONNECT)
      .subscribe(() => {
        //console.log('connected');
      });

     this.socket_service_disconnected = this.socketService.onEvent(Event.DISCONNECT)
      .subscribe(() => {
        //console.log('disconnected');
      });
      */
  }


  reloadScreen() {
    //console.log('reloadScreen!!!!');
    this.chosenFilterProject = null;
    this.specMsg = null;
    this.specComment = false;
    this.showRedBar = false;
    this.specMsgType = null;
    this.messageService.messageRefreshConv();//reload message page
    //this.messageService.messageUpdated();//update header notification
    this.messageService.needRefreshFiltre();//update filter elements
  }

  fcthiddenSearchEngin(){
    if(this.instant == true && this.drive == false) {
      this.searchinstant = !this.searchinstant;
      if(this.menuOuvert == true){
        this.showFileSelector();
      }
    }
    if(this.instant == false && this.drive == false){
      if(this.searchItem == true) this.searchItem = false;
      this.hiddenSearchEngindrive =false;
        if (this.hiddenSearchEngin == false)
          this.hiddenSearchEngin = true;
        else
          this.hiddenSearchEngin = false;
          this.divopened = false;
          this.writeMode = -1;
    } else if(this.drive == true) {
      this.hiddenSearchEngin =false;
      if (this.hiddenSearchEngindrive == false)
        this.hiddenSearchEngindrive = true;
      else
        this.hiddenSearchEngindrive = false;
    }
  }

  fcthiddenSearchEnginDrive(){
    if (this.hiddenSearchEngindrive == false)
      this.hiddenSearchEngindrive = true;
    else
      this.hiddenSearchEngindrive = false;
  }

  fullTextWithLink(value: string) {
    //return value.replace(/(http?s:\/\/[^\s]+)/g, "<a href='$1' target='__blank'>$1</a>");
    return value.replace(/(https?:\/\/[^\s]+)/g, "<a href='$1' target='__blank'>$1</a>");
  }
  
  uploadFile(event) {
    //console.log('item récupéré '+JSON.stringify(event));
    /*for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.files[this.files.length] = element;
      this.inputMedia.nativeElement.value = "";
    }  */

    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.files.push(element);
    } 
    this.inputMedia.nativeElement.value = "";
  }

  onRemoveAnswer(event) {
    //console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  selectCopyClosefct(opened: boolean, matSelect: MatSelect){
    console.log("opened : "+opened)
    if (!opened) {
      this.hideSelectCopy = true;
      this.hideSelectCopyMenu = false;
      this.temporarySelectedValues = [];
      /*
      matSelect.options.forEach((item: MatOption) => {
        console.log("item : "+item.value)
        console.log("inc "+this.sendMessageForm.controls.inCopy.value.includes(item.value))
        if (!(this.sendMessageForm.controls.inCopy.value.includes(item.value))) {
          item.deselect();
        }
      });*/
    }
  }

  openSelectCopy(){
    this.hideSelectCopy = false;
    this.hideSelectCopyMenu = true;
    this.oneToOneOption.deselect();
    this.allSelected.deselect();
    this.sendMessageForm.controls.inCopy.patchValue([]);
    this.sendMessageForm.get('private').setValue(false);
  }

  loadFilterElement(){
    //console.log("on loadFilterElement");
    if (this.firstLoad == true) {
      this.getInfoProject();
    }
    this.firstLoad = false;
  }


  pendingDev(){
    if(this.lang == "fr")
      alert("Processus action délai bientôt disponible");
    else
      alert("Deadline action process soon available");
  }

  pendingDevReunion(){
    if(this.lang == "fr")
      alert("Compte rendu de réunion bientôt disponible");
    else
      alert("Meeting minutes coming soon");
  }

  onSelectResponse(event){
    event.addedFiles.map((item)=>{
      this.files.push(item);
    })   
  }

  onSelectResponseInstant(event){
    //console.log("executed "+JSON.stringify(event));
    event.addedFiles.map((item)=>{
      this.filesinstant.push(item);
    })   
  }

  fermerMenu() {
    this.menuOuvert = false;
  }

  ouvrirMenu() {
    this.menuOuvert = true;
  }
/*
  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    const targetElement = event.target as HTMLElement;
    if (!targetElement.closest('.menu')) {
      this.fermerMenu();
    }
  }
  */

  rotateIcon() {
    this.state = (this.state === 'normal' ? 'rotated' : 'normal');
    /*const icon = document.getElementById('iconfile');
    if (icon) {
      icon.style.transform = 'rotate(90deg)';
    }*/
  }

  showFileSelector() {
    var i = 0;
    /*
    if (this.menuOuvert) {
      clearTimeout(this.closeTimeout);
    }*/
    this.menuOuvert = !this.menuOuvert;
    this.state = (this.state === 'normal' ? 'rotated' : 'normal');
    
  }

  togglePopup() {
    this.popupVisible = !this.popupVisible;
  }

  closePopup() {
    this.popupVisible = false;
  }

  /*
  onOutsideClick(targetElement: any) {
    const clickedInside = this.toggleLink.nativeElement.contains(targetElement);

    if (!clickedInside) {
      // Utilise un délai avant de fermer la popup
      this.closeTimeout = setTimeout(() => {
        this.menuOuvert = false;
      }, 200); // Vous pouvez ajuster le délai selon vos besoins
    }
  }

  // HEREEEE
  hideFileSelector(){
    if(this.menuOuvert == false){
      this.menuOuvert = false;
      this.state = 'normal';
    }
  }*/

  choisirType(type) {
    if(type == "contact" || type=="sondage"){
      alert("En cours de développement");
      return;
    }
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = true;
    if(type == 'document') {
      //type = '.pdf,.doc,.docx,.txt';
      this.typefileselected = type;
    } else this.typefileselected = 'photo';
    input.accept = type; // Acceptez les fichiers de type image, vidéo et PDF
    //input.accept = type+'/*'; // Acceptez les fichiers de type image, vidéo et PDF
    input.onchange = (event) => this.onSelectFiles(event);
    input.click();
  }

  onSelectFiles(event: any) {
    const files = event.target.files;
    this.filesinstant = Array.from(files);
    console.log("file : ", { data : this.filesinstant[0] });
    this.menuOuvert = false;
  }

  downloadFile(url) {
    const path = environment.server_url+'/media/instant/'+url
    const nomFichier = path; // Remplacez par le nom de votre fichier avec l'extension
    alert("url "+path)
    const a = document.createElement('a');
    a.href = url;
    a.download = nomFichier;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  

  
  
  
  clickSuppMethod() {
    if(confirm("Voulez vraiment suppprimer le projet "+this.projectFull.libelle)) {
      this.onClickConfirmeDelete();
    }
  }

  onClickConfirmeDelete(): void{
    this.authService.deleteProject(this.iDProjet).subscribe(
      res => {  
         //this.authService.obRefreshProjet.next();
         //this.messageService.messageUpdated();
         this.authService.obProjectDeleted.next(this.projectFull);
         this.router.navigate(['/']);
        //this.stepper.selectedIndex = 1;
      },
      (error) => {
        alert('Erreur ! : ' + error)
      }
    );
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  onRemoveInstant(event) {
    this.filesinstant.splice(this.files.indexOf(event), 1);
  }

  chooseCopy(value){
    //this.dialogRef.close();
    this.hideSelectCopy2 = false;
    /*
    this.dialogRef.afterClosed().subscribe(result => {
      if (result){ 
        result.value = value;
      }
    })*/
  }

 
  fctEcrire(){

    this.writeMode = -1;
    this.resetCommentForm();
    this.resetAnswerFrom();
    this.hideSelectCopy = true;
    this.hideSelectCopyMenu = false;

    if(this.openWriteFace == false)
    {
      this.hiddenSearchEngin = true;
      this.showRedBar = false;
      this.openWriteFace = true;
      this.showMessages = true;
      this.getLastFaceNumber();
      setTimeout(() => {
        if (this.sendmsgdiv) {
          this.selectRecipient.open();
          this.sendmsgdiv.nativeElement.scrollIntoView({block: "center"});
        }
        else{
          this.selectRecipient.open();
          this.sendmsgdiv.nativeElement.scrollIntoView({behavior: "smooth", block: "center"});
        }
      }, 1000);
      
      //this.descp.nativeElement.scrollIntoView({block: "center"});
      //this.sendmsgdiv.nativeElement.scrollIntoView({behavior: "smooth", block: "center"});
    }
    else
    {
      this.resetQuestionFrom();
      this.openWriteFace = false;
      this.showMessages = true;
    }
     
  }


  exiteWriteMode(){
    this.writeMode = -1;
    this.resetCommentForm();
    this.resetAnswerFrom();
    this.resetQuestionFrom();
    this.openWriteFace = false;
    this.showMessages = true;
    this.messageService.getCloseWriteMessage(true);
  }

  getLastFaceNumber(){
     this.authService.getLastFaceNumber(this.ID)
      .subscribe(res => {
        //console.log(' retour getLastFaceNumber => '+res);
        let arrayMessage = res['hydra:member']

        if (arrayMessage.length > 0) {
           this.lastFaceNumber = arrayMessage[0].smartNumber;
        }

        // console.log(this.projectUsers);
        //console.log('fin chargement projet ');
      }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
    });   
  }

  onSelectPrivate(){
    if (this.sendMessageForm.controls.private.value === false)
    {
       this.sendMessageForm.get('private').setValue(true); 
    }
    else
    {
      this.sendMessageForm.get('private').setValue(false); 
    } 
  }

  getAnswerListe(){
    this.arrayQuickAnswer = [
      {message: "Noté, merci ! ☺️", title : "Noted Thanks"},
      {message: "Ok, je suis d'acccord 👍", title : "Ok I agree"},
      {message: "C'est parfait, je m'en occupe", title : "It's perfect, I'll take care of it"},
      {message: "Désolé je ne peux pas", title : "Sorry I can not"},
      {message: "Merci, mais ça ne dépend pas à mes attentes", title : "Thanks, but it doesn't meet my expectations"},
      {message: "Impossible pour moi, désolé", title : "Impossible for me, sorry"}];
      /*
     this.authService.getAnswerListe( )
      .subscribe(res => {
        this.arrayQuickAnswer = res['hydra:member'];
      }, (error) => {
    }); */ 
  }

  sendQuickAnswer(answerItem,  infoMessage) {
    const msg = this.lang == 'fr' ? answerItem.message : answerItem.title
    this.authService.sendQuickAnswer(
      msg,
      infoMessage.tags[0],
      infoMessage.isPrivate,
      this.project,
      infoMessage.id
    )
    .subscribe(res => {
      //console.log('réponse answer => '+res);
      //this.writeMode = 0;
      this.writeMode = -1;
      //this.envoyerObs.next();
      this.messageService.messageRefreshConv();
      //this.messageService.messageUpdated();
      this.authService.obreceiveAsread.next(this.ID);
      //this.markAsRead(infoMessage);
      var copies = [];
      if(infoMessage.copy.length > 0){
        infoMessage.copy.forEach(element => {
          //if(element.user && element.user.id)
          copies.push(element.user.id);
        })
      }
      //this.total -= 1;
      //scroll in view
      //this.divHeaderTable.nativeElement.scrollIntoView();
      this.authService.obinstantMsgsent.next({"id": this.ID, "text":msg, "date": res['dateCreated']});
      this.socketService.sendInstant({
        id: infoMessage.id,
        action: this.ID,
        type: "RESPONSE",
        author: this.currentUser,
        recipient: infoMessage.author.id,
        text: msg,
        copy: copies,
        private: infoMessage.isPrivate,
        res: res,
        project_lib: this.projectFull.libelle,
        date: res['dateCreated']
      });

       window.scroll(0,0);

    });
  }

  getRecipient(){
    for (var i = 0; i < this.projectUsers.length; i++) {
      if(this.projectUsers[i].user.id == this.sendMessageForm.controls.recipient.value){
        return this.projectUsers[i].user.fullName;
      }
    }
    return false;
    //console.log('Your order has been submitted ---> ', this.sendMessageForm.value);
  }

  getDeadline(){
    if(this.sendMessageForm.controls.deadLine.value){
      return this.sendMessageForm.controls.deadLine.value;
    }
    return '';
    //console.log('Your order has been submitted ---> ', this.sendMessageForm.value);
  }


  transfertByMail(messageData){
    const dialogRef = this.dialog.open(transfertdialog, {
      width: '600px',
      data: {message: messageData}
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');       
    });
  }

  
  

  open(file){

    const reader = new FileReader();
    reader.onload = (e: any) => {
       //console.log('csv content', e.target.result);
       const album = {
          src: e.target.result
       };
       let albums = [];
       albums.push(album);
       this._lightbox.open(albums, 0);

       setTimeout(() => {
        document.querySelector( 'img.lb-image' ).removeAttribute("hidden");
        if(document.querySelector( '.lb-loader' )) document.querySelector( '.lb-loader' ).setAttribute("hidden", "true");
        if(document.querySelector( '.lb-nav' )) document.querySelector( '.lb-nav' ).setAttribute("hidden", "true");
       }, 1500);

    };
    reader.readAsDataURL(file);

  }

  playVideoOrAudio(file, source){
    source.src = URL.createObjectURL(file);
  }

  createFileUrl(file, source){
    source.href = URL.createObjectURL(file);
  }

  getFiletype(file){

    if (file.type.includes("image/") == true) {
      return 1;
    }
    else if (file.type.includes("video/") == true) {
      return 2; 
    }
    else if (file.type.includes("application/") == true) {
      return 3;
    }
    else if (file.type.includes("audio/") == true) {
      return 4;
    }
    else
    {
      return -1;
    }

  }


  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  deleteAttachment(index) {
    this.files.splice(index, 1);
    //console.log(this.files)
  }

  tosslePerOne(all){ 
    if (this.allSelected.selected) {  
      this.allSelected.deselect();
      return false;
    }
    if(this.sendMessageForm.controls.inCopy.value.length==this.getUserListWithNoCurrentUser().length){ 
      this.allSelected.select();
    }
  }

  
  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];



  filter(data){
    
    if (this.arrayUserCopy.length === this.sendMessageForm.get("inCopy").value.filter(item => item != "0").length) {
      //this.showselect = false;
    }
  }

  /*copyTemp = [];
  toggleAllSelection() {
    //console.log(this.allSelected)
    this.projectUsers.map(item=>this.copyTemp.push(item.user.id))
    if (this.allSelected.selected) {
      this.sendMessageForm.controls.inCopy.patchValue(this.copyTemp);
    } else {
      this.sendMessageForm.controls.inCopy.patchValue([]);
    }
    console.log(this.sendMessageForm.value);
  }*/

  resetPrivate() {
    //this.sendMessageForm.controls.inCopy.patchValue([]);
    //const element: HTMLElement = document.getElementById('divtous') as HTMLElement
    //element.innerHTML = '';
    this.private = false;
    this.notifyValue = "ALL";
    //this.copiesusers = [];
    this.sendMessageForm.get('private').setValue(false);
  }
  /*
  toggleAllSelection() {
    //console.log(this.allSelected)
    //console.log("start ");
    this.sendMessageForm.controls.inCopy.patchValue([]);
    const element: HTMLElement = document.getElementById('divtous') as HTMLElement
    element.innerHTML = 'Tous';
    this.copiesusers = [];
    //this.oneToOneOption.deselect();
    //this.allSelected.select();
    this.sendMessageForm.get('private').setValue(false);

  }*/

  fctOneToOne(){
    this.sendMessageForm.controls.inCopy.patchValue([]);
    this.allSelected.deselect();
    this.oneToOneOption.select();
    this.sendMessageForm.get('private').setValue(true);
  }

  compareObjects(o1: any, o2: any) {
    if(o1 === o2 )
    return true;
    else return false
  }

  onCloseEmoji() {
    this.showEmojiPicker = false;
  }

  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  testClick(event: any) {
    if (event.emoji && event.emoji.native) {
      console.log("Emoji selected: " + event.emoji.native);
    } else {
      console.log("No emoji selected or event structure is different: ", event);
    }
  }

  addEmoji(event: any) {
    console.log("start : "+event.emoji.native)
    const currentValue = this.sendMessageFormInstant.get('messageToSend').value;
    this.sendMessageFormInstant.get('messageToSend').setValue(currentValue + event.emoji.native);/*
    console.log("text : "+event.emoji.native)
    this.sendMessageFormInstant.value.messageToSend += event.emoji.native;
    console.log("text : "+this.sendMessageFormInstant.value.messageToSend)*/
  }

  handleSelection(event) {
    console.log("handleSelection**")
    const currentValue = this.sendMessageFormInstant.get('messageToSend').value;
    this.sendMessageFormInstant.get('messageToSend').setValue(currentValue + event.char);
    //console.log(event.char);
    //this.sendMessageFormInstant.value.messageToSend += event.char;
    //console.log("text : "+this.sendMessageFormInstant.value.messageToSend)
    //this.message += event.char;
  }


  truncate(value: string, limit = 25, completeWords = false, ellipsis = '...') {
    if (completeWords) {
      limit = value.substr(0, limit).lastIndexOf(' ');
    }
    let val =  value.length > limit ? value.substr(0, limit) + ellipsis : value;
    return val.replace(/(http?s:\/\/[^\s]+)/g, "<a href='$1' target='__blank'>$1</a>");
  }

  onScroll(event: any): void {
    const container = this.scrollMechat.nativeElement;
    const atTop = container.scrollTop === 0;
    if (atTop && !this.loadingMessagesTop && this.dataloaded == false && this.startedPoint == false && this.canload == true) {
      console.log("loaaded")
      this.canload = false;
      this.dataloaded = true;
      this.scrollposition = container.scrollHeight;
      this.getMessagesInstant(true);
    }
  }

  restoreScrollPosition() {
    const container = this.scrollMechat.nativeElement;
    container.scrollTop = container.scrollHeight - this.scrollposition + 50;
    this.dataloaded = false;
  }

  getMessagesInstant(loaded = null) {
    if(this.nbrloadmsg > 0) {
      this.loadingMessagesTop = true;
    }
    this.search = false;
    if(this.messagesInstant.length > 0){
      this.perPage = this.messagesInstant.length;
      this.pageIndex = 1;
    }
    //this.authService.getAllMessagesInstant(this.ID, null, this.pageIndex, this.perPage)
    this.authService.getMessagesInstantFromPosition(this.ID, this.offsetmsg, this.limitmsg)
      .subscribe(res => {
        const newMessages = res['hydra:member'].reverse();
        this.nbrloadmsg++;
        if(!newMessages || newMessages.length == 0 || newMessages.length < this.limitmsg) this.startedPoint = true;
        this.addNewMessages(newMessages)
        .then(() => {
          this.offsetmsg = this.offsetmsg + this.limitmsg;
          this.canload = true;
          setTimeout(() => {
            this.restoreScrollPosition();
          }, 200);
          
        //this.messagesInstant.unshift(...newMessages);
        //this.restoreScrollPosition();
        this.loadingMessagesTop = false;
        this.messagesInstant.forEach(message => {message['showButton'] = false, message['showButtonclicked'] = false});
        setTimeout(() => {
          if(loaded) return;
          if(this.notificationsCount > 0) {
            //console.log(">>>> 0")
            this.limitmsg = 20;
            this.scrollToUnread();
            //this.scrollToReadMsg();
          } else {
            //console.log("<===== 0");
            this.scrollToBottom();
          }
          //this.scrollToBottom();
          this.FocusWriteMsg();
        }, 1000);
      });

      }, (error) => {
        this.loadingMessagesTop = false;
         //console.log(' getTheProject subscribe error '+error.statusText);
    });
  }

  addNewMessages(newMessages) {
    return new Promise<void>((resolve) => {
      this.messagesInstant.unshift(...newMessages);
      resolve();
    });
  }

  searchMessagesInstant() {
    //alert("date "+this.chosenFilterInstant.dateAfterinstant);
    this.authService.getAllMessagesInstant(this.ID, (this.chosenFilterInstant ? this.chosenFilterInstant : null ), null, null)
      .subscribe(res => {
        //this.messagesInstant = res['hydra:member'];
        if(res['hydra:member'].length == 0){
          alert("pas de résultats");
          return;
        }
        this.messagesResults = res['hydra:member'];
        //this.messagesInstant = res['hydra:member'];
        //this.currentIndex = this.searchResults.length - 1;
        //this.currentIndex = this.onlyResults.length - 1;
        //var messageId = this.searchResults[this.currentIndex]['id'];
        //var messageId = this.onlyResults[this.currentIndex]['id'];
        this.fcthiddenSearchEngin();
        /*
        this.messagesInstant.forEach(element => {
          const textelement = document.getElementById('p_'+element['id']);
          const pElementContent = textelement.innerHTML;
          textelement.innerHTML = element.text;
        });
        this.searchResults.forEach(element => {
          const textelement = document.getElementById('p_'+element['id']);
          const pElementContent = textelement.innerHTML;
          textelement.innerHTML = this.replaceAllCaseInsensitive(pElementContent, this.chosenFilterInstant.keyWordinstant);
        });*/
        //this.scrollToMessage(messageId);
        this.scrollToBottomResult();/*
        setTimeout(() => {
          var textelement2 = document.getElementById('D_967e840d-1200-4da6-af13-7f6cac83e871');
        var pElementContent2 = textelement2.innerHTML;
        console.log("tex "+pElementContent2);
        }, 1000);*/
        
        
        if(this.chosenFilterInstant.keyWordinstant) {
          setTimeout(() => {
            this.messagesResults.forEach(element => {
              const textelement = document.getElementById('Doctor_'+element['id']);
              const pElementContent = textelement.innerHTML;
              textelement.innerHTML = this.replaceAllCaseInsensitive(pElementContent, this.chosenFilterInstant.keyWordinstant);
            });
          }, 500);
        }
        //this.firstDate = this.messagesInstant[0].dateCreated
        //alert("first "+this.firstDate);
        /*
        console.log("first "+this.firstDate)
        setTimeout(() => {
          this.scrollToBottom();
        }, 1000);*/

      }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
    });
  }

  verifyDateChanged(date, k) {
    /*
    if(k==0){
      this.firstDate = date;
      return true
    }*/
    const date1 = new Date(this.firstDate);
    const date2 = new Date(date);

    // Comparaison en ignorant l'heure, les minutes, les secondes
    const memeDate = date1.getFullYear() === date2.getFullYear() &&
                     date1.getMonth() === date2.getMonth() &&
                     date1.getDate() === date2.getDate();

    if (memeDate) {
      return false;
    } else {
      this.firstDate = date;
      return true
    }
  }

  pasteMessage(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    return;
    if (clipboardData && clipboardData.getData) {
      const pastedText = clipboardData.getData('text');
  
      // Récupérer le message complet depuis le stockage local
      const storedMessage = localStorage.getItem('copiedMessage');
      if (storedMessage) {
        const message = JSON.parse(storedMessage);
        //alert("fichiers inclus "+message.text)
  
        // Vérifiez s'il y a des fichiers associés au message
        if (message.files && message.files.length > 0) {
          //alert("yeeees")
          /*
          const filesDiv = document.createElement('div');
          filesDiv.textContent = 'Fichiers associés : ';
          message.files.forEach(file => {
            const fileLink = document.createElement('a');
            fileLink.href = file.url; // Assurez-vous d'avoir l'URL du fichier dans l'objet message
            fileLink.textContent = file.name;
            filesDiv.appendChild(fileLink);
            filesDiv.appendChild(document.createElement('br'));
          });
  
          // Ajoutez le div contenant les fichiers sous le textarea
          const textarea = event.target as HTMLTextAreaElement;
          textarea.parentElement?.appendChild(filesDiv);*/
        }
      }
  
      // Collez le texte dans le textarea
      //(event.target as HTMLTextAreaElement).value = pastedText;
    }
  }
  

  copierText(message: any) {
    let contentToCopy = message.text;
    /*if (message.fichier) {
      contentToCopy += ' - Fichier: ' + message.fichier.libelle;
    }*/
    //this.clipboardService.copyFromContent(contentToCopy);
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = contentToCopy;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    if(message.filegeneric.length > 0){
      localStorage.setItem('copiedMessage', JSON.stringify(message));
    } else localStorage.removeItem('copiedMessage');
    //alert('Message copié avec succès ' + contentToCopy);

    /*
    alert('en cours de développement ! : ');
    if (text) {
      this.clipboard.writeText(text).then(() => {
        alert('Texte copié avec succès ! : '+text);
      }).catch((error) => {
        console.error('Erreur lors de la copie du texte : ', error);
      });
    } else {
      alert('Faut copier du texte.');
    }*/
  }

  getColor(id){
    return this.userscolor.get(id);
  }

  getCurrentProject() {
    this.tempMemberUser = []
    //console.log('getCurrentProject paramètre id ==> '+this.ID);
    
    this.isSearch = false;
    if (!this.ID) {
      return;
    }

    //this.initIoConnection(this.ID);

    this.authService.getTheProject(this.ID)
      .subscribe(res => {
        this.projectFull = res;
        //console.log("lib "+this.projectFull.libelle)
        this.firstDate = res['dateCreated'];
        this.projectmembers = "";
        var tmpArrayUser = res['userProject'];
        var currentuserproject = null;
        currentuserproject = tmpArrayUser.find(userProject => {
          return userProject.user && userProject.user.id === this.currentUser;
        });
        this.notificationsCount = currentuserproject.number;
        console.log("id "+currentuserproject.id);
        console.log("count "+currentuserproject.number);
        if(this.notificationsCount > 0 && this.instant == true){
          this.authService.setNbrMsgProjet(currentuserproject.id).subscribe(dataAccepte => {
            this.messageService.getListProject().filter(item => item.id == this.ID)[0].userProject[0].id;
            this.messageService.setListProject(this.projectLists);
          }, (error) => {
            //alert('une erreur est survenue');
          });
        }
        //alert("notificationsCount "+this.notificationsCount)
        //var tmpArrayUser = tmpArrayUser.filter((item) => item.user != null);
        this.projectUsers = [];
        let creatorSet = new Set();
        this.project_isparent = res['type'];
        //alert("ok "+this.project_isparent);
        for (var i = 0; i < tmpArrayUser.length; i++) {
          if(tmpArrayUser[i].user != null && !creatorSet.has(tmpArrayUser[i].user.id)){
            this.projectUsers.push(tmpArrayUser[i]);
            creatorSet.add(tmpArrayUser[i].user.id);
            this.userscolor.set(tmpArrayUser[i].user.id, tmpArrayUser[i].color);
            this.projectmembers+=tmpArrayUser[i].user.givenName+" "+tmpArrayUser[i].user.familyName+", ";
            //console.log('mmm : '+tmpArrayUser[i].id);
          }
        }
        
        this.projectUsers.sort((a, b) => {
          if ( a.user.fullName.toLowerCase() < b.user.fullName.toLowerCase() ){
            return -1;
          }
          if ( a.user.fullName.toLowerCase() > b.user.fullName.toLowerCase() ){
            return 1;
          }
          return 0;
        });
        //this.projectUsers.sort((a,b) => a.user.fullName > b.user.fullName);
        this.project = '/api/projects/' + res['id'];
        this.projectTags = res['tags'];


        this.projectTags.sort((a, b) => {
          if ( a.libelle.toLowerCase() < b.libelle.toLowerCase() ){
            return -1;
          }
          if ( a.libelle.toLowerCase() > b.libelle.toLowerCase() ){
            return 1;
          }
          return 0;
        })

        // console.log(this.project);
        // console.log(this.projectUsers);
        //console.log('fin chargement projet ');

        for (var i = 0; i < this.projectUsers.length; i++) {
          if((this.projectUsers[i].isAdmin == true) && (this.projectUsers[i].user.id == this.currentUser))
          {
            this.userAdmin = true;
          }
        }

      }, (error) => {
        this.router.navigate(['/homes']);
         //console.log(' getTheProject subscribe error '+error.statusText);
    });

    /*this.envoyerObs.subscribe(() =>{ 
      this.paginator.pageIndex = 0;
    });*/

    this.messageService.getMessageRefreshConv().subscribe(() =>{ 
      this.paginator.pageIndex = 0;
    });

    merge(this.paginator.page, this.messageService.getMessageRefreshConv(), this.refreshPageObs)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          console.log("before moood : "+this.notifiedmode)
          if(this.authService.isNotified === true){
              console.log("notfied moood : "+this.ID)
              return this.authService.getProjectNotifedMessages(this.ID, this.paginator.pageIndex, this.perPage, (this.specMsg ? this.specMsg : null ), (this.specMsgType ? this.specMsgType : null ),(this.chosenFilterProject ? this.chosenFilterProject : null ))
            } else { 
              console.log("normal moood : "+this.ID)
              return this.authService.getProjectHisMessages(this.ID, this.paginator.pageIndex, this.perPage, (this.specMsg ? this.specMsg : null ), (this.specMsgType ? this.specMsgType : null ),(this.chosenFilterProject ? this.chosenFilterProject : null ))
            } 
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data['hydra:totalItems']; 
          if (this.paginator.page !== null) {
            this.scrollToTop();
          }
          return data['hydra:member'];  
        }),
        catchError(() => {    
          this.isLoadingResults = false;
          // Catch if the  API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => {    

        //console.log({commConvos:data})
          
          /*this.messageArray = data['hydra:member']; 
          this.messageArray.sort((a: any, b: any) => {// order by date
              return b.number - a.number;
          });
          this.dataSource = this.messageArray;*/

          for (var i = 0; i < data.length; i++) {
            data[i].truncating = true;
            if(data[i].isMessageAnswered == true && data[i].isMessageDisabled == false) {
              data[i].answer.truncating = true;
            }
          }
          this.isReady = true;
          this.dataSource = data;
          // this.dataSource=this.dataSource.filter(item=>!item.action)
          // console.log({dataMsg:this.dataSource})
          if(this.dataSource.length > 0){ 

            this.hasMessage = true;
            //this.hiddenSearchEngin = false;

            for (var i = 0; i < this.dataSource.length; i++) {
              //this.dataSource[i].comment
              this.dataSource[i].comment.sort((a: any, b: any) => {// order by date
                  return b.smartNumber - a.smartNumber;
              });
            }
            if(this.specComment) {
              this.specCom = this.dataSource[0].comment[0].number;
              this.specMsg = null;
            }
            if (this.specMsg && this.specComment == false) {
              //this.specMsg = this.dataSource[0].comment[0].number;
              if (this.specMsgType && this.specMsgType == 'COMMENT') {
                for (var i = 0; i < this.dataSource.length; i++) {
                  if (this.dataSource[i].smartNumber ==  this.specMsg){
                    for (var index = 0; index < this.dataSource[index].comment.length; index++) {
                      if(this.dataSource[i].comment[index].dateCreated == this.dataSource[i].motionAt){
                        this.specMsg = this.dataSource[i].comment[index].number;
                        break;
                      }
                    }
                    break;
                  }
                }
              }
              console.log("specoment : "+this.specCom)
              /*
              if (!(this.divComSpecMsg)){
                if(this.dataSource[0].smartNumber == this.specMsg){
                  if((this.userIsConcerned(this.dataSource[0]) == true) && (this.youNeedToMarkAsRead(this.dataSource[0]) == true)){
                    this.markAsReadAll(this.dataSource[0])
                  }
                }
              }*/
              setTimeout(() => {
                if (this.divComSpecMsg) {
                  var obj = this.dataSource[0].comment;
                  for(var i = 0; i < obj.length; i++) {
                    if(this.specMsg && (obj[i].number == this.specMsg)){
                      if(this.isNoRead(obj[i]) || this.userInCopyHasNoRead(obj[i])){
                        this.markAsRead(obj[i]);
                      }
                    }
                  }
                  this.divComSpecMsg.nativeElement.scrollIntoView({block: "center"});
                }
                else{
                  if(this.dataSource[0].smartNumber == this.specMsg){
                    if((this.userIsConcerned(this.dataSource[0]) == true) && (this.youNeedToMarkAsRead(this.dataSource[0]) == true)){
                      this.markAsReadForCopy(this.dataSource[0], 0)
                    }
                  }
                  //this.divHeaderTable.nativeElement.scrollIntoView({behavior: "smooth", block: "center"});
                  //for(var i = 0; i < this.dataSource.length; i++) {
                    //console.log("len "+this.dataSource.length)
                    
                    this.divHeaderTable.nativeElement.scrollIntoView({behavior: "smooth", block: "center"});
                  //}
                  /*
                  if(this.specMsg && (this.element.smartNumber == specMsg) && (showRedBar == true))
                  If="(userIsConcerned(element) == true) && (youNeedToMarkAsRead(element) == true)"
                  markAsReadAll(element)
                  let element of dataSource
                  *ngIf="specMsg && (element.smartNumber == specMsg) && (showRedBar == true)
                  */
                }
              }, 500);
            }   


          }else { 
            this.hasMessage = false;
            this.messageService.openWriteMessageFace(null);
          }

      });
      
      
  } 

  // convenience getter for easy access to form fields
  get f() { return this.sendMessageForm.controls; }
  get fanswer() { return this.sendanswerForm.controls; }
  get fcomment() { return this.formComment.controls; }
  get fcommentresponse() { return this.formCommentResponse.controls; }

  convertText(text){
    return text.replace(/(http?s:\/\/[^\s]+)/g, "<a href='$1'>$1</a>");
  }

  retrySubmit(message){
    this.RetrySendQuestionInstant(message.text, message);
  }

  cancelResponse(){
    this.msgAnswered = null;
  }

  answerInstant(message: any, i: number, numdoc = null){
    //console.log("nuuum "+i)
    console.log("numdoc 0 "+numdoc)
    this.numdoc = numdoc;
    this.msgAnswered = message;
    this.sendMessageFormInstant.patchValue({ messageToSend: '' });
  }

  getIcon(media){
    let filelink = "";
    var extension = media.extension;
    var extensionfile = media.extensionfile;
    if(extensionfile){
    if(extensionfile.startsWith('xl') || (extensionfile == 'csv')){
      filelink = "../../../../assets/icons/excel.png";
    }else if(extensionfile.startsWith('ppt')){
      filelink = "../../../../assets/icons/ppt.png";
    }else if((extensionfile.startsWith('do') || extensionfile == 'odt')){
      filelink = "../../../../assets/icons/word.png";
    }else if(extensionfile.includes("zip") || extensionfile.includes("rar")){
      filelink = "../../../../assets/icons/zip.png";
    }else if(extensionfile.includes("pdf")){
      filelink = "../../../../assets/icons/pdf.png";
    }else if(extension.includes("image")){
      //filelink = "../../../../assets/icons/image.png";
      filelink = this.getMediaLinkResponse(media.contentUrl, media.thumbnailPath);
    }else if(extension.includes("audio")){
      filelink = "../../../../assets/icons/audio.png";
    }else if(extension.includes("video")){
      filelink = "../../../../assets/icons/video.png";
    }}

    return filelink ? filelink : false;
  }

  getMediaLinkResponse(mediaUrl, thumbnailPath=null){
    if(thumbnailPath){
        return this.authService.getMediaDataInstantThumbnail(thumbnailPath);
      } else return this.authService.getMediaDataInstant(mediaUrl);
  }

  onSubmitFormInstant(customerData) {
    /*
    alert("show : "+this.msgAnswered.id)
    return;
    */
    this.msgsent = true;
    this.showEmojiPicker = false;
    //alert("yes "+this.sendMessageFormInstant.value.messageToSend);
    this.sendQuestionInstant(this.sendMessageFormInstant.value.messageToSend);
    this.sendMessageFormInstant.patchValue({ messageToSend: '' });
  }
 
  onSubmitForm(customerData) {
    this.submitted = true;
    this.facesent = true;
    console.log("sentet")
     // stop here if form is invalid
    if (this.sendMessageForm.invalid) {
      console.log("invalid")
      return;
    }
    
    if (this.private == true) {
      //this.sendMessageForm.controls.inCopy.patchValue([]);
      this.sendMessageForm.controls.private.patchValue(true)
    }
    //if (this.sendMessageForm.controls.inCopy.value[0] == 'copyAll') {
    
    if (this.allcopies == true) {
      var tempMember = [];
      this.projectUsers.map(item=>tempMember.push(item.user.id))
      this.sendMessageForm.controls.inCopy.patchValue(tempMember);
    }
    const tempMember2 = this.sendMessageForm.controls.inCopy.value;
    const updatedCopy = tempMember2.filter((item: any) => (item !== "0" ));
    this.sendMessageForm.controls.inCopy.patchValue(updatedCopy);

    if (this.generalselected == true) {
      this.sendMessageForm.controls.general.patchValue(true);
    } else this.sendMessageForm.controls.general.patchValue(false);

    //console.log(this.sendMessageForm.value);

    this.sendQuestion(this.sendMessageForm.value);

    if (this.showTool == false) {
      this.messageService.setShowProjectTutoIsFalse();
    }
    //scroll in view
     window.scroll(0,0);
    //this.divHeaderTable.nativeElement.scrollIntoView();
   
  }

  resetAllmsgbloc(){
    this.messageService.setMessageIsSend();
    this.sujectPlaceholder = ""
    this.submitted = false;
    this.selectprivate = false;
    this.copiesusers = [];
    //this.theForm.resetForm();
    this.resetQuestionFrom();
    this.resetPrivate();
    this.openWriteFace = false;
    this.divopened = false;
    this.relanceOne = null;
    this.relanceTwo = null;
    this.echeance = null;
    this.showsubject = true;
    this.showMessages = true;
    this.messageService.getCloseWriteMessage(true);
    this.sendMessageForm.controls.inCopy.patchValue([]);
    this.senderror = false;
  }

  onSelectSubProject(sujetChosen){
    //console.log('sujetChosen ==> ', sujetChosen);
    this.sendMessageForm.get('newSubProject').setValue('');
    this.sendMessageForm.get('subProject').setValidators([Validators.required]);
    this.sendMessageForm.get('subProject').updateValueAndValidity();
  }

 
  onSelectNewSubProject(newSubProjetName){
    this.sendMessageForm.get('subProject').setValue('');
    this.sendMessageForm.get('subProject').clearValidators();
    this.sendMessageForm.get('subProject').updateValueAndValidity();
  }

  onEnterKey(event: KeyboardEvent, messageTosendData): void {
    event.preventDefault(); // Empêche le saut de ligne par défaut du textarea
    this.onSubmitFormInstant(messageTosendData);
  }

  getObjectUrl(file: File): string {
    console.log("url "+URL.createObjectURL(file));
    return URL.createObjectURL(file);
  }

  getExtension(filename){
    const fileName = filename.name;
    const fileExtension = fileName.split('.').pop();
    console.log('Extension du fichier : ', fileExtension);
    return fileExtension;
  }
  
  mapProject(messageTosendData, project, filesinstant, typefileselected){
    var files = [];
    var typefiles = "image";
    if(typefileselected == "document"){
      typefiles = 'DOCUMENT';
    }
    console.log('type file : '+typefiles)
    if(filesinstant.length > 0 ){
      files = filesinstant.map(file =>({
        id: "00000",
        contentUrl: "a81fc9305d3a613e32f57f3b29f3e5505349ac43.png",
        file: file,
        createdAt: "2024-01-16T11:13:20+00:00",
        extension: file.type,
        extensionfile: this.getExtension(file),
        mimtype: "image/png",
        type: "fake",
        libelle: typefiles,
        originalName: file.name,
        thumbnailPath: null
      }));
    }
    /*
    const fileName = filesinstant[0].name;
      const fileExtension = fileName.split('.').pop();

      console.log('Extension du fichier : ', fileExtension);*/
    const newMessage = {
      id: "",
      fakeId: uuidv4(),
      type: "fake",
      author: { 
        id: this.currentUser,
        imageUrl: this.userData.imageUrl
      },
      dateCreated: new Date(),
      iddoc: null,
      isDrive: false,
      isTransfered: false,
      number: 0,
      project: {
        id: project,
        imageUrl: null,
        libelle: false,
        parentItem: null,
      },
      filegeneric: files,
      text: messageTosendData,
      transfermsg: null,
      error: true
    }
    return newMessage;
    console.log("uidv4 "+newMessage.fakeId);
    this.messagesInstant.push(newMessage);
    //this.messagesInstantSent.push(newMessage);
  }

  sendQuestionInstant(messageTosendData) {
    this.msgInstantRetry = messageTosendData;
    this.notificationsCount = 0;
    //this.loadingScreenService.stopLoading();
    if(this.filesinstant.length == 0 && (messageTosendData == "" || messageTosendData.trim().length == 0)) {
      alert("veuillez ecrire un message")
      return;
    }
    const filesinst = [...this.filesinstant];
    this.filesInstantRetry = filesinst;
    const newMessage = this.mapProject(messageTosendData, this.project, filesinst, this.typefileselected );
    this.messagesInstant.push(newMessage);
    setTimeout(() => {
      this.scrollToBottom();
    }, 200);
    const textarea = document.getElementById('tchat-text') as HTMLTextAreaElement;
      this.sendMessageFormInstant.get('files').setValue(null);
      this.sendMessageFormInstant.get('files').setValidators([requiredFileType(['png','jpeg','jpg','mov','mp4'])]); 
      this.sendMessageFormInstant.get('files').updateValueAndValidity();
      this.filesinstant = [];
    if (textarea) {
      textarea.value = '';
    }
    var idanswered = null;
    if(this.msgAnswered){
      idanswered = this.msgAnswered.id;
    }
    console.log("before answer : "+idanswered)
    this.authService.sendQuestionInstant(messageTosendData, this.project, filesinst, this.typefileselected, this.numdoc, null, null, null, newMessage.fakeId, idanswered).subscribe(res => {
      //console.log('reponse envoi message: ',res);
      //this.messagesInstant.push(res);
      this.msgAnswered = null;
      this.numdoc = null;
      var length = this.messagesInstant.length - 1;
      this.msgInstantRetry = null;
      this.filesInstantRetry = [];
      console.log("len "+length);
      console.log("last id "+this.messagesInstant[length].fakeId);
      console.log("fake id "+res['fakeid']);
      if(this.messagesInstant[length].fakeId == res['fakeid']){
        console.log("replaced by api");
        this.messagesInstant[length] = res;
        setTimeout(() => {
          this.scrollToBottom();
        });
      }
      /*this.messagesInstant.forEach(element => {
        if(element.fakeid && element.fakeid == res['fakeid']){
          element = res;

        }
      });*/
      this.msgsent = false;
      //this.sendMessageFormInstant.get('messageToSendInstant').setValue('');
      //this.theFormInstant.resetForm();
      
      if(messageTosendData === "" || messageTosendData == undefined) {
         messageTosendData = "Documents";
      }
      this.authService.obinstantMsgsent.next({"id": this.ID, "idmsg": res['id'], "text":messageTosendData, "date": res['dateCreated']});
      this.socketService.sendInstant({
        id: res['id'],
        action: this.ID,
        type: "INSTANT",
        author: this.currentUser,
        author_name: this.currentUserInfo["fullName"],
        recipient: this.ongletId,
        text: messageTosendData,
        copy: "",
        private: false,
        res: res,
        project_lib: this.projectFull.libelle,
        date: res['dateCreated']
      });
      

    // Vider le contenu du textarea
      /*
      setTimeout(() => {
        this.scrollToBottom();
      }, 1000);*/
      //this.loadingScreenService.startLoading();
      
      //this.newTagsArray = res['tags'];
    },error => {
      //alert("error "+error)
      for (var i = 0; i < this.messagesInstant.length; i++) {
        if(newMessage.fakeId ===  this.messagesInstant[i].fakeId){
          this.messagesInstant[i].retry = true;
          console.log("is here")
          //this.messagesInstant.splice(i, 1); 
        } 
      } 
      //alert("oups une erreur s'est produite!");
    });
  }

  RetrySendQuestionInstant(messageTosendData, message) {
    this.notificationsCount = 0;
    const filesinst = this.filesInstantRetry;
    this.authService.sendQuestionInstant(messageTosendData, this.project, filesinst, this.typefileselected, null, null, null, message.fakeId).subscribe(res => {
      //console.log('reponse envoi message: ',res);
      //this.messagesInstant.push(res);
      var length = this.messagesInstant.length - 1;
      this.msgInstantRetry = null;
      this.filesInstantRetry = [];
      if(this.messagesInstant[length].fakeId == res['fakeid']){
        this.messagesInstant[length] = res;
        setTimeout(() => {
          this.scrollToBottom();
        });
      }
      this.msgsent = false;
      
      if(messageTosendData === "" || messageTosendData == undefined) {
         messageTosendData = "Documents";
      }
      this.authService.obinstantMsgsent.next({"id": this.ID, "idmsg": res['id'], "text":messageTosendData, "date": res['dateCreated']});
      this.socketService.sendInstant({
        id: res['id'],
        action: this.ID,
        type: "INSTANT",
        author: this.currentUser,
        author_name: this.currentUserInfo["fullName"],
        recipient: this.ongletId,
        text: messageTosendData,
        copy: "",
        private: false,
        res: res,
        project_lib: this.projectFull.libelle,
        date: res['dateCreated']
      });
    },error => {
      //alert("error "+error)
      for (var i = 0; i < this.messagesInstant.length; i++) {
        if(message.fakeId ===  this.messagesInstant[i].fakeId){
          this.messagesInstant[i].retry = true;
          console.log("is here")
        } 
      } 
    });
  }

  handleData(data: any) {
    // Faire quelque chose avec les données reçues du composant enfant
    //console.log("handele  "+data.contentUrl);
    this.selectedPdf = data;
  }

  closeModal(){
    this.selectedPdf=null;
  }

  getPdfLink(mediaUrl){
    console.log(this.authService.getMediaData(mediaUrl));
    if(this.instant == true){
      return this._sanitizer.bypassSecurityTrustResourceUrl(this.authService.getMediaDataInstant(mediaUrl));
    } else return this._sanitizer.bypassSecurityTrustResourceUrl(this.authService.getMediaData(mediaUrl));
    //return this._sanitizer.bypassSecurityTrustResourceUrl(this.authService.getMediaData(mediaUrl));

  }

  scrollToBottom(): void {
    //const container = this.scrollMechat.nativeElement;
    this.scrollMechat.nativeElement.scrollTop = this.scrollMechat.nativeElement.scrollHeight;
    
  }

  scrollToReadMsg() {
    const element = document.getElementById('readmsgdiv');
    if (element) {
      element.classList.add('smooth-scroll');
      element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      element.scrollTop -= 60;
      element.classList.remove('smooth-scroll'); // Retirez la classe après l'animation
    }
  }

  scrollToMsg(id: string) {
    setTimeout(() => {
      console.log("end 0");
      if (this.scrollMechat && this.scrollMechat.nativeElement) {
        console.log("end 1");
        const element = this.scrollMechat.nativeElement.querySelector(`#message_${id}`);
        console.log("end 2");
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
          console.log("end 3");
        }
      }
    }, 1000);
    
    /*
    const element = document.getElementById('message_'+id);
    if (element) {
      element.classList.add('smooth-scroll');
      element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      element.scrollTop -= 60;
      element.classList.remove('smooth-scroll'); // Retirez la classe après l'animation
    }*/
  }

  scrollToBottomResult(): void {
    const container = this.scrollResultchat.nativeElement;
    this.scrollResultchat.nativeElement.scrollTop = this.scrollResultchat.nativeElement.scrollHeight;
    
  }

  mapProjectFace(messageTosendData, project, filesface, typefileselected){
    var files = [];
    var typefiles = "image";
    if(typefileselected == "document"){
      typefiles = 'DOCUMENT';
    }
    console.log('type file : '+typefiles)
    if(filesface.length > 0 ){
      files = filesface.map(file =>({
        id: "00000",
        contentUrl: "a81fc9305d3a613e32f57f3b29f3e5505349ac43.png",
        file: file,
        createdAt: "2024-01-16T11:13:20+00:00",
        extension: file.type,
        extensionfile: this.getExtension(file),
        mimtype: "image/png",
        type: "fake",
        libelle: typefiles,
        originalName: file.name,
        thumbnailPath: null
      }));
    }
    const recipient = this.projectUsers.find(user => user.user.id === messageTosendData.recipient).user;
    const tag = this.projectTags.find(subject => subject.id === messageTosendData.subProject.id);
    var smartNumber = 1 ;
    if(Number(this.dataSource.length > 0)){
      smartNumber = Number(this.dataSource[0].smartNumber) + 1;
    }
    const newMessage = {
      id: "",
      fakeId: uuidv4(),
      type: "fake",
      author: { 
        id: this.currentUser,
        givenName: this.currentUserInfo.givenName,
        familyName: this.currentUserInfo.familyName,
        imageUrl: this.userData.imageUrl
      },
      recipient: { 
        id: recipient.id,
        givenName: recipient.givenName,
        familyName: recipient.familyName,
        imageUrl: recipient.imageUrl
      },
      tags: [{
        id: tag.id,
        libelle: tag.libelle
      }],
      dateCreated: new Date(),
      iddoc: null,
      isDrive: false,
      isPrivate: messageTosendData.private,
      isRecipientRead: false,
      isTransfered: false,
      recipientReadAt: null,
      smartNumber: smartNumber,
      copy: [],
      comment: [],
      recall: [],
      relance1: messageTosendData.relanceOne,
      deadLine: messageTosendData.deadLine,
      project: {
        id: project,
        imageUrl: null,
        libelle: false,
        parentItem: null,
      },
      filegeneric: files,
      text: messageTosendData.messageToSend,
      transfermsg: null,
      error: true
    }
    return newMessage;
  }

  sendQuestion(messageTosendData) {
    this.ISendMessage = true;
    //---------------------
    const filesface = [...this.files];
    const newMessage = this.mapProjectFace(messageTosendData, this.project, filesface, this.typefileselected );
    console.log("init "+newMessage.fakeId)
    this.dataSource.unshift(newMessage);
    this.total += 1;
    this.divopened = false;
    //---------------------
    this.authService.sendQuestion(
      messageTosendData.messageToSend,
      messageTosendData.subProject,
      this.project,
      messageTosendData.recipient,
      messageTosendData.inCopy,
      messageTosendData.private,
      this.isCommentActivated,
      messageTosendData.deadLine,
      messageTosendData.relanceOne,
      messageTosendData.relanceTwo,
      messageTosendData.relanceTree,
      this.files,
      null,
      null,
      null,
      messageTosendData.general
    )
    .subscribe(res => {
      window.scroll(0,0);
      //console.log('reponse envoi message: ',res);
      this.resetAllmsgbloc();
      this.tempMemberUser = [];
      this.facesent = false;
      this.newTagsArray = res['tags'];
      this.dataSource[0] = res;
      for (var i = 0; i < this.newTagsArray.length; i++) {
        if (this.containsTheTag(this.newTagsArray[i]) == false) {
          //console.log('ajout du nouveau tag => '+ this.newTagsArray[i]);
          var tag = this.newTagsArray[i];
          this.projectTags.push(tag);
        }
      }

     this.projectTags.sort((a, b) => {
        if ( a.libelle.toLowerCase() < b.libelle.toLowerCase() ){
          return -1;
        }
        if ( a.libelle.toLowerCase() > b.libelle.toLowerCase() ){
          return 1;
        }
        return 0;
      })
      this.socketService.sendInstant({
        id: res['id'],
        action: this.ID,
        type: "FACE",
        author: this.currentUser,
        recipient: messageTosendData.recipient,
        text: messageTosendData.messageToSend,
        copy: messageTosendData.inCopy,
        private: messageTosendData.private,
        res: res,
        project_lib: this.projectFull.libelle,
        date: res['dateCreated']
      });
      this.tempMember = [];

      

      this.chosenFilterProject = null;
      this.specMsg = null;
      this.specComment = false;
      this.specCom = null;
      this.showRedBar = false;
      this.specMsgType = null;
      this.modeDoc = false;
    this.chosenFilter = null;
    //console.log(' launchSearch '+ JSON.stringify(this.chosenFilter) );
    this.EventLaunchSearch.emit(this.chosenFilter);
    this.ISendMessage = false;
      //console.log("socket sended");
    
    },error => {
      this.total -= 1;
      this.senderror = true;
      this.facesent = false;
      if(error.status !== 0){
      alert("oups une erreur s'est produite!"+JSON.stringify(error));
    }
      this.ISendMessage = false;
    });
  }


  // getTags(){
  //   this.authService.getAllTags().then((res)=>{
  //     console.log({allTags:res})
  //   }).catch((err)=>{
  //     console.log(err)
  //   })
  // }
  

  getProjectInfo(){
    this.arraySubject = []

    if (!this.ID) {
      return;
    }

    this.authService.getProjectInfo(this.ID).then((res)=>{
      this.arraySubject = res['tags']
      /*this.arraySubject.sort((a, b) => {
        return <any>new Date(a.dateCreated) - <any>new Date(b.dateCreated);
      })*/

      this.arraySubject.sort((a, b) => {
        if ( a.libelle.toLowerCase() < b.libelle.toLowerCase() ){
          return -1;
        }
        if ( a.libelle.toLowerCase() > b.libelle.toLowerCase() ){
          return 1;
        }
        return 0;
      })

      // this.arraySubject.reverse()
      this.isUpdate = Array(this.arraySubject.length).fill(false)
      //this.project = res
      // if(this.currentUser['id'] == this.project['creator']['id'] ){
      //   this.isAdmin = true
      // }
    }).catch(err=>console.log(err))
  }


  openDuppSubjectModal(action){
    //console.log('ID du project subject =>'+ this.ID);
    this.projectLists = this.messageService.getListProject();
    const dialogRef = this.dialog.open(SubjectDuppComponent, {
      width: '700px',
      data:{idProjet: this.project, projects: this.projectLists}
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed result '+result);
        //TO Do dialog ajout membre 
        //console.log(dialogRef.componentInstance.chosenSubject);
          console.log("element "+ dialogRef.componentInstance.tagsadded.length);
        if(dialogRef.componentInstance.tagsadded){
          dialogRef.componentInstance.tagsadded.forEach((element)=>{
            console.log("element "+ JSON.stringify(element));
            this.projectTags.push(element);
          })
          
          this.projectTags.sort((a, b) => {
            if ( a.libelle.toLowerCase() < b.libelle.toLowerCase() ){
              return -1;
            }
            if ( a.libelle.toLowerCase() > b.libelle.toLowerCase() ){
              return 1;
            }
            return 0;
          })
          this.selectSubject.open();
        }
        
    });
  }

  

  openAddSubjectModal(action){
    //console.log('ID du project subject =>'+ this.ID);
    const dialogRef = this.dialog.open(SubjectsListComponent, {
      width: '700px',
      data:{idProjet: this.ID, action: action, type: this.project_isparent}
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed result '+result);
        //TO Do dialog ajout membre 
        //console.log(dialogRef.componentInstance.chosenSubject);
        if(dialogRef.componentInstance.chosenSubject){
          this.sujectPlaceholder = dialogRef.componentInstance.chosenSubject;
          this.sendMessageForm.get('subProject').setValue(dialogRef.componentInstance.chosenSubject)
          this.sendMessageForm.get('subProject').clearValidators();
          this.sendMessageForm.get('subProject').updateValueAndValidity();
          this.projectTags.push(dialogRef.componentInstance.chosenSubject);
          this.projectTags.sort((a, b) => {
            if ( a.libelle.toLowerCase() < b.libelle.toLowerCase() ){
              return -1;
            }
            if ( a.libelle.toLowerCase() > b.libelle.toLowerCase() ){
              return 1;
            }
            return 0;
          })
          this.selectSubject.open();
        }
        if(action == 2 && dialogRef.componentInstance.updatedSubject){
          //this.getProjectTags();
          this.getCurrentProject();
        }
        
    });
  }

  getUserListWithNoCurrentUser(){
    if(!this.projectUsers) return [];
    this.arrayUserCopy = [];
    for (var i = 0; i < this.projectUsers.length; i++) {
      if (this.sendMessageForm.controls.recipient.value) {
        if(this.projectUsers[i].user.id != this.currentUser && this.sendMessageForm.controls.recipient.value != this.projectUsers[i].user.id /* && this.projectUsers[i].status == 'ACCEPTED'*/){
          //arrayUser.push(this.projectUsers[i]);
          this.arrayUserCopy.push(this.projectUsers[i]);
          console.log(this.projectUsers[i].user.id);
        }
      }
      else if(this.projectUsers[i].user.id != this.currentUser /* this.projectUsers[i].status == 'ACCEPTED'*/){
        //arrayUser.push(this.projectUsers[i]);
        this.arrayUserCopy.push(this.projectUsers[i]);
      }
    }
    let search = this.copySearch.value;
    if (!search) {
      return this.arrayUserCopy;
    } else {
      search = search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      return this.arrayUserCopy.filter(bank => bank.user.givenName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1 || bank.user.familyName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1 );
    }
    //return this.arrayUserCopy;
  }

  openBottomDialogEcheance(){
    
    const dialogRef = this.dialog.open(DeadLineConvDialog, {
      width: '330px',
      data:{}
    });   
    var resultobject = {"value": "", "echeance" : "", "relanceOne": "", "relanceTwo": "", "relanceTree" : ""};
    
    
 
    dialogRef.afterClosed().subscribe(result => {
       
      
      if (result){ 
          //result.echeance = moment(result.echeance).format('DD-MM-YYYY').toLocaleString( );
          //this.echeance = result.echeance.toLocaleString( );
          if(result.deadline){
            this.echeance = moment(result.deadline).format('DD-MM-YYYY').toLocaleString( );
          }
          if(result.Relance1){
            this.relanceOneDate = moment(result.Relance1).format('DD-MM-YYYY').toLocaleString( );
          }
          this.relanceOne = result.relanceOne;
          //this.relanceOneDate = moment(result.Relance1).format('DD-MM-YYYY').toLocaleString( );
          this.relanceTwo = result.relanceTwo;
          this.relanceTwoDate = moment(result.relanceTwo).format('DD-MM-YYYY').toLocaleString( );
          this.sendMessageForm.get('deadLine').setValue(result.deadline); 
          this.sendMessageForm.get('relanceOne').setValue(result.Relance1);
          this.sendMessageForm.get('relanceTwo').setValue(result.Relance2);
          this.sendMessageForm.get('relanceTree').setValue(result.Relance3);
        
      }

    })

  }

  setPrivate() {
    if(this.private == true){
      //this.sendMessageForm.controls.inCopy.patchValue([]);
      this.sendMessageForm.get('private').setValue(false);
      this.private = false;
      this.notifyValue = "ALL";
      //this.tempMember = [];
      //this.copiesusers = [];
      //this.toggleAllSelection();
    } else {
      //this.sendMessageForm.controls.inCopy.patchValue([]);
      this.sendMessageForm.get('private').setValue(true);
      this.private = true;
      this.sendMessageForm.controls.inCopy.patchValue([]);
      this.sendMessageForm.get('private').setValue(true);
      this.notifyValue = "ALL";
      this.private = true;
      this.selectprivate = true;
      this.allcopies = false;
      this.tempMember = [];
      this.copiesusers = [];
      //this.allcopies = false;
    }
  }

  changeNotify(){
    /*if(this.notifyValue == "AUCUN"){
          this.sendMessageForm.controls.inCopy.patchValue([]);
          this.sendMessageForm.get('private').setValue(false);
          this.selectprivate = true;
          this.tempMember = [];
          this.copiesusers = [];
          this.toggleAllSelection();
    } else */
    if(this.notifyValue == "ALL"){
          this.sendMessageForm.controls.inCopy.patchValue([]);
          this.sendMessageForm.get('private').setValue(false);
          this.tempMember = [];
          this.selectprivate = false;
          this.copiesusers = [];
          this.allcopies = true;
          this.projectUsers.forEach(element => {
            if(this.currentUser != element.user.id){
              this.tempMember.push(element);
              this.copiesusers.push(element.user);
            }
          });
    } else if(this.notifyValue == "MEMBERS"){
      this.openOptionsModal();
    }
  }

  openOptionsModal(){
    //console.log('ID du project subject =>'+ this.ID);
    var copies = this.getUserListWithNoCurrentUser();
    this.nbrcopies = copies.length;
    const dialogRef = this.dialog.open(OtherOptionsComponent, {
      width: '350px',
      data:{idProjet: this.ID, type: this.project_isparent, private: this.private, copies: copies, selected: this.tempMember},
      panelClass: 'custom-modalbox'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        if(result.value === "private"){
          //console.log("private "+JSON.stringify(result));
          this.sendMessageForm.controls.inCopy.patchValue([]);
          this.sendMessageForm.get('private').setValue(true);
          this.private = true;
          this.selectprivate = true;
          this.allcopies = false;
        } else if(result.value === "notprivate"){
          //console.log("private "+JSON.stringify(result));
          this.sendMessageForm.controls.inCopy.patchValue([]);
          this.sendMessageForm.get('private').setValue(false);
          this.private = false;
          this.selectprivate = true;
          this.tempMember = [];
          this.copiesusers = [];
          this.toggleAllSelection();
        } else if(result.value === "all"){
          //console.log("private "+JSON.stringify(result));
          this.sendMessageForm.controls.inCopy.patchValue([]);
          this.sendMessageForm.get('private').setValue(false);
          this.private = false;
          this.tempMember = [];
          this.selectprivate = false;
          this.selectteam = true;
          this.copiesusers = [];
          this.allcopies = true;
          this.projectUsers.forEach(element => {
            if(this.currentUser != element.user.id){
              this.tempMember.push(element);
              this.copiesusers.push(element.user);
              //console.log("us "+element.user.fullName);
            }
          });
        } else if(result.value === "deadline"){
          //this.sendMessageForm.controls.inCopy.patchValue([]);
          //this.sendMessageForm.get('private').setValue(true);
          //console.log("deadline "+JSON.stringify(result));
          this.sendMessageForm.get('deadLine').setValue(result.echeance); 
          //result.echeance = moment(result.echeance).format('DD-MM-YYYY').toLocaleString( );
          //this.echeance = result.echeance.toLocaleString( );
          //this.echeance = moment(result.echeance).format('DD-MM-YYYY').toLocaleString( );
          this.relanceOne = result.relanceOne;
          this.relanceOneDate = moment(result.relanceOne).format('DD-MM-YYYY').toLocaleString( );
          this.relanceTwo = result.relanceTwo;
          this.relanceTwoDate = moment(result.relanceTwo).format('DD-MM-YYYY').toLocaleString( );
          this.sendMessageForm.get('relanceOne').setValue(result.Relance1);
          this.sendMessageForm.get('relanceTwo').setValue(result.Relance2);
          this.sendMessageForm.get('relanceTree').setValue(result.Relance3);
        } else if(result.value === "copy"){
          this.sendMessageForm.get('private').setValue(false);
          this.private = false;
          this.selectcopies = false;
          this.selectprivate = false;
          this.allcopies = false;
          this.toggleAllSelection();
          var copiesselected = result.copiesselected;
          this.tempMember = [];
          this.copiesusers = [];
          copiesselected.forEach(item => {
            this.projectUsers.forEach(element => {
              if(item == element.user.id){
                this.tempMember.push(item);
                this.copiesusers.push(element.user);
                //console.log("us "+element.user.fullName);
              }
            });
          });/*
          const elementtous: HTMLElement = document.getElementById('divtous') as HTMLElement
          elementtous.innerHTML = '';*/
          this.sendMessageForm.controls.inCopy.patchValue(this.tempMember);
        }
      }
      //console.log('The dialog was closed result '+result);
        //TO Do dialog ajout membre 
        //console.log(dialogRef.componentInstance.chosenSubject);
        //this.optionselected = dialogRef.componentInstance.chosenSubject;
    });
  }


  containsTheTag(newTag){
    for(var i = 0; i < this.projectTags.length; i++) {
      if(this.projectTags[i].id == newTag.id){
        return true;
      }
    }
    return false;
  }

  quitReply(messageData){
    this.writeMode = -1;
  }

  openAnswerMode(messageData) {
    //console.log(' messageData => '+ JSON.stringify(messageData));
    this.resetAnswerFrom();
    this.resetQuestionFrom();
    this.openWriteFace = false;
    this.showMessages = true;
    this.messageInfo = messageData;
    this.writeMode = 1;     
  }

  quitComment(messageData){
    this.writeMode = -1;
  }

  openCommentResponseMode(messageData){
    if(messageData && messageData.isCommentActivated == true){
      //if (this.writeMode !== 2) {
        this.resetCommentForm();
        this.resetQuestionFrom();
        this.openWriteFace = false;
        this.showMessages = true;
        this.messageInfo = messageData;
        this.writeMode = 3;
        setTimeout(() => this.commentresponsetext.nativeElement.focus(), 1000);
      /*}
      else
      {
        this.writeMode = -1;
      }*/
    }
    else
    {
      if(!messageData) 
      {
        alert('Aucun message à commenter');
      }
      else if (messageData.isCommentActivated == false)
      {
        alert('Commentaire déactivé');
      }
      else
      {
        alert('oups Error !!!');
      }
    }
  }

  openCommentMode(messageData){
    if(this.divopened) this.closeMsgBloc();
    this.hiddenSearchEngin = true;
    if(messageData && messageData.isCommentActivated == true){
      //if (this.writeMode !== 2) {
        this.resetCommentForm();
        this.resetQuestionFrom();
        this.openWriteFace = false;
        this.showMessages = true;
        this.messageInfo = messageData;
        this.writeMode = 2;
        setTimeout(() => this.commenttext.nativeElement.focus(), 1000);
      /*}
      else
      {
        this.writeMode = -1;
      }*/
    }
    else
    {
      if(!messageData) 
      {
        alert('Aucun message à commenter');
      }
      else if (messageData.isCommentActivated == false)
      {
        alert('Commentaire déactivé');
      }
      else
      {
        alert('oups Error !!!');
      }
    }
  }


  onSubmitAnswerForm(answerFormData, element) {

     // Process checkout data here
    //console.log('Your order has been submitted onSubmitAnswerForm ', answerFormData);

    this.submitted = true;

     // stop here if form is invalid
    if (this.sendanswerForm.invalid) {
      return;
    }
    this.markAsRead(element);
    
    this.sendAnswer(answerFormData, element);
    
    this.submitted = false;
    this.files = [];
    this.resetAnswerFrom();

    //scroll in view
     window.scroll(0,0);
    //this.divHeaderTable.nativeElement.scrollIntoView();

  }

  sendAnswer(answerData, element) {
    this.ISendMessage = true;
    this.authService.sendAnswer(
      answerData.answerMessage,
      this.messageInfo.tags[0],
      this.messageInfo.isPrivate,
      this.project,
      this.messageInfo.id,
      this.files,
      null,
      null,
      null
    )
    .subscribe(res => {
      //console.log('réponse answer => '+res);
      //this.writeMode = 0;
      this.writeMode = -1;
      //this.envoyerObs.next();
      this.messageService.messageRefreshConv();
      this.authService.obreceiveAsread.next(this.ID)
      //this.messageService.messageUpdated();
      this.ISendMessage = false;
      //this.total -=1;
      var copies = [];
      if(element.copy.length > 0){
        element.copy.forEach(element => {
          //if(element.user && element.user.id)
          copies.push(element.user.id);
        })
      }
      this.authService.obinstantMsgsent.next({"id": this.ID, "text":answerData.answerMessage, "date": res['dateCreated']});
      this.socketService.sendInstant({
        id: this.messageInfo.id,
        action: this.ID,
        type: "RESPONSE",
        author: this.currentUser,
        recipient: element.author.id,
        text: answerData.answerMessage,
        private: element.isPrivate,
        res: res,
        project_lib: this.projectFull.libelle,
        copy: copies,
        date: res['dateCreated']
      });

    },error => {
      alert("oups une erreur s'est produite!");
      this.ISendMessage = false;
    });
  }

  onSubmitFormComment(commentFormData){

    // Process checkout data here
    //console.log('Your order has been submitted onSubmitFormComment ', commentFormData);

    this.submitted = true;

     // stop here if form is invalid
    if (this.formComment.invalid) {
      return;
    }
    
    this.sendComment(commentFormData);

    this.submitted = false;
    this.files = [];
    this.resetCommentForm();  

     //scroll in view
      window.scroll(0,0);
    //this.divHeaderTable.nativeElement.scrollIntoView();

  }

  onSubmitFormCommentResponse(commentFormData, element){

    // Process checkout data here
    //console.log('Your order has been submitted onSubmitFormComment ', commentFormData);

    this.submitted = true;

     // stop here if form is invalid
    if (this.formCommentResponse.invalid) {
      return;
    }
    
    this.sendCommentResponse(commentFormData, element);
    

    this.submitted = false;
    this.files = [];
    this.resetCommentResponseForm();  

     //scroll in view
      window.scroll(0,0);
    //this.divHeaderTable.nativeElement.scrollIntoView();

  }

  sendComment(commentFormData) {
    this.ISendMessage = true;
    this.authService.sendComment(
      commentFormData.commentMessage,
      this.messageInfo.tags[0],
      this.messageInfo.isPrivate,
      this.project,
      this.messageInfo.id,
      this.files,
      null,
      null,
      null
    )
    .subscribe(res => {
      //console.log('réponse comment => '+res);
      //this.writeMode = 0;
      this.writeMode = -1;
      //this.envoyerObs.next();
      this.messageService.messageRefreshConv();
      //this.messageService.messageUpdated();
      this.ISendMessage = false;
      this.specComment = true;
      //this.messageInfo.id
      /*
      const messageIndex = this.dataSource.findIndex(message => message.id === this.messageInfo.id);
      console.log("inddd "+messageIndex)
      if (messageIndex !== -1) {
          const messageToMove = this.dataSource.splice(messageIndex, 1)[0];
          console.log("msg "+JSON.stringify(messageToMove))
          this.dataSource.unshift(messageToMove);
      }*/
      this.authService.obinstantMsgsent.next({"id": this.ID, "text":commentFormData.commentMessage, "date": res['dateCreated']});
      this.socketService.sendInstant({
        id: this.messageInfo.id,
        action: this.ID,
        type: "COMMENT",
        author: this.currentUser,
        recipient: "",
        text: commentFormData.commentMessage,
        copy: "",
        private: false,
        res: res,
        project_lib: this.projectFull.libelle,
        smartNumber: res['smartNumber'],
        date: res['dateCreated']
      });

      /*
      this.socketService.send({
        id: this.currentUser,
        action: this.ID,
        type: "COMMENT",
        text: ""
      });
      */

    },error => {
      alert("oups une erreur s'est produite!");
      this.ISendMessage = false;
      this.writeMode = 2;
    });
  }

  sendCommentResponse(commentFormData, element) {
    this.ISendMessage = true;
    this.authService.sendCommentResponse(
      commentFormData.commentMessage,
      element.tags[0],
      element.isPrivate,
      this.project,
      this.messageInfo.id,
      this.files,
      null,
      null,
      null
    )
    .subscribe(res => {
      //console.log('réponse comment => '+res);
      //this.writeMode = 0;
      this.writeMode = -1;
      //this.envoyerObs.next();
      this.messageService.messageRefreshConv();
      //this.messageService.messageUpdated();
      this.ISendMessage = false;
      this.authService.obinstantMsgsent.next({"id": this.ID, "text":commentFormData.commentMessage, "date": res['dateCreated']});
      this.socketService.sendInstant({
        id: this.messageInfo.id,
        action: this.ID,
        type: "COMMENT",
        author: this.currentUser,
        recipient: "",
        text: commentFormData.commentMessage,
        copy: "",
        private: false,
        res: res,
        project_lib: this.projectFull.libelle,
        smartNumber: res['smartNumber'],
        date: res['dateCreated']
      });

      /*
      this.socketService.send({
        id: this.currentUser,
        action: this.ID,
        type: "COMMENT",
        text: ""
      });
      */

    },error => {
      alert("oups une erreur s'est produite!");
      this.ISendMessage = false;
    });
  }

  resetQuestionFrom(){
    this.files = [];
    this.sendMessageForm.get('subProject').setValue('');
    this.sendMessageForm.get('subProject').setValidators([Validators.required]);
    this.sendMessageForm.get('subProject').updateValueAndValidity();
    this.sendMessageForm.get('newSubProject').setValue('');
    this.sendMessageForm.get('recipient').setValue('');
    this.sendMessageForm.get('recipient').setValidators([Validators.required]);
    this.sendMessageForm.get('recipient').updateValueAndValidity();
    this.sendMessageForm.get('inCopy').setValue([]);
    this.sendMessageForm.get('private').setValue(false);
    this.sendMessageForm.get('deadLine').setValue(null);
    this.echeance = null;
    this.private = false;
    this.relanceOneDate = null;
    this.sendMessageForm.get('relanceOne').setValue(null);
    this.sendMessageForm.get('relanceTwo').setValue(null);
    this.sendMessageForm.get('relanceTree').setValue(null);
    this.sendMessageForm.get('messageToSend').setValue('');
    //this.sendMessageForm.get('messageToSend').setValidators([Validators.required, Validators.minLength(1)]);
    this.sendMessageForm.get('messageToSend').updateValueAndValidity();
    this.sendMessageForm.get('files').setValue(null);
    this.sendMessageForm.get('files').setValidators([requiredFileType(['png','jpeg','jpg','mov','mp4'])]); 
    this.sendMessageForm.get('files').updateValueAndValidity();
    
    // Object.keys(this.sendMessageForm.controls).forEach(key => {
    //   this.sendMessageForm.controls[key].setErrors(null)
    // });
  }


  redirectTache(){
   //this.router.navigateByUrl('/reunion/'+this.ID)
   //alert("En cours de développement");
   this.router.navigateByUrl('/faceTofacesProposal/'+this.ID+'/openprop');
  }

  redirectReunions(){
   //this.router.navigateByUrl('/reunion/'+this.ID)
   this.router.navigateByUrl('/faceTofacesReunion/'+this.ID+'/openmeet');
  }

  redirectPlan(){
    this.router.navigateByUrl('/plan/'+this.ID)
  }

  redirectCompteRenduReunions(){
    this.router.navigateByUrl('/compteRenduRenunion/'+this.ID)
  }

  changePrivacy(val){
    this.sendMessageForm.get('private').setValue(!val);
  }

  resetAnswerFrom(){
    
    // reset form
    this.sendanswerForm.get('answerMessage').clearValidators();
    this.sendanswerForm.get('answerMessage').updateValueAndValidity();
    this.sendanswerForm.get('files').clearValidators();
    this.sendanswerForm.get('files').updateValueAndValidity();
    this.sendanswerForm.reset();

    //set defaut form values
    this.sendanswerForm.get('answerMessage').setValue('');
    this.sendanswerForm.get('answerMessage').setValidators([Validators.required, Validators.minLength(1)]);
    this.sendanswerForm.get('answerMessage').updateValueAndValidity();
    this.sendanswerForm.get('files').setValue(null);
    this.sendanswerForm.get('files').setValidators([requiredFileType(['png','jpeg','jpg','mov','mp4'])]);
    this.sendanswerForm.get('files').updateValueAndValidity();

  }

  resetCommentForm(){

    // reset form
    this.formComment.get('commentMessage').clearValidators();
    this.formComment.get('commentMessage').updateValueAndValidity();
    this.formComment.get('files').clearValidators();
    this.formComment.get('files').updateValueAndValidity();
    this.formComment.reset();

    //set defaut form values
    this.formComment.get('commentMessage').setValue('');
    this.formComment.get('commentMessage').setValidators([Validators.required, Validators.minLength(1)]);
    this.formComment.get('commentMessage').updateValueAndValidity();
    this.formComment.get('files').setValue(null);
    this.formComment.get('files').setValidators([requiredFileType(['png','jpeg','jpg','mov','mp4'])]);
    this.formComment.get('files').updateValueAndValidity();

  }

  resetCommentResponseForm(){

    // reset form
    this.formCommentResponse.get('commentMessage').clearValidators();
    this.formCommentResponse.get('commentMessage').updateValueAndValidity();
    this.formCommentResponse.get('files').clearValidators();
    this.formCommentResponse.get('files').updateValueAndValidity();
    this.formCommentResponse.reset();

    //set defaut form values
    this.formCommentResponse.get('commentMessage').setValue('');
    this.formCommentResponse.get('commentMessage').setValidators([Validators.required, Validators.minLength(1)]);
    this.formCommentResponse.get('commentMessage').updateValueAndValidity();
    this.formCommentResponse.get('files').setValue(null);
    this.formCommentResponse.get('files').setValidators([requiredFileType(['png','jpeg','jpg','mov','mp4'])]);
    this.formCommentResponse.get('files').updateValueAndValidity();

  }

  getMediaLink(mediaUrl){
    return this.authService.getMediaData(mediaUrl);
  }

  getMediaThumbail(mediaUrl){
    return this.authService.getMediaThumbail(mediaUrl);
  }

  fctListeMembre(){
    const dialogRef = this.dialog.open(DialogMemberListe, {
      panelClass: 'dialogmember-container',
      data:{idProjet: this.ID}
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed result '+result);
      if(result){
        //TO Do dialog ajout membre 
        //this.fctAddMember();
      } 
    });
  }

  fctListeSuject(event: MouseEvent){
    const dialogRef = this.dialog.open(SubjectListeDialog, {
      panelClass: 'dialogsubject-container',
      data: { idProjet: this.ID, projectTags: this.projectTags},
      position: {
        top: `${event.clientY}px`,
        left: `${event.clientX}px`
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed result '+result);
      if (result) {
        this.sendMessageForm.patchValue({
           subProject: result
         });
         setTimeout(() => {
          const libsubjectvalue: HTMLElement = document.getElementById('libsubjectvalue') as HTMLElement
          libsubjectvalue.innerHTML = this.sendMessageForm.value.subProject.libelle;
        }, 0.5);
       } 
    });
  }

  fctListeCopies(event: MouseEvent){
    const dialogRef = this.dialog.open(CopyListeDialog, {
      panelClass: 'dialogsubject-container',
      data: { idProjet: this.ID, projectCopies: this.getUserListWithNoCurrentUser()},
      position: {
        top: `${event.clientY}px`,
        left: `${event.clientX}px`
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed result '+result);
      if (result) {
        console.log("ris : "+result.length);
        var tempMember = [];
        this.tempMemberUser = [];
        result.map(item=> {
          tempMember.push(item.id);
          this.tempMemberUser.push(item);
        })
        this.sendMessageForm.controls.inCopy.patchValue(tempMember);
       } 
    });
  }

  fctListeRecipient(event: MouseEvent){
    const dialogRef = this.dialog.open(RecipientListeDialog, {
      panelClass: 'dialogsubject-container',
      data: { idProjet: this.ID, projectUsers: this.projectUsers},
      position: {
        top: `${event.clientY}px`,
        left: `${event.clientX}px`
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed result '+result);
      if (result) {
        this.sendMessageForm.patchValue({
          recipient: result.id
        });
        setTimeout(() => {
          const librecipientvalue: HTMLElement = document.getElementById('librecipientvalue') as HTMLElement
          librecipientvalue.innerHTML = result.givenName +" "+result.familyName;
        }, 0.5);
       } 
    });
  }

  fctAddMember() {
    const dialogRef = this.dialog.open(DialogAddMember, {
      panelClass: 'dialogmember-container',
      data: {project: this.project, projectUsers: this.projectUsers, project_lib: this.projectFull.libelle}
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed result add membre '+result);    
    });
  }

  showHideAllMessage(){
    if (this.showMessages == true) {
        this.showMessages = false;
    }
    else{
       this.showMessages = true;
    }
  }


  loadAllMessage(){
    this.ged = false;
    this.notifiedmode = false;
    localStorage.setItem('notified', "false");
    this.chosenFilterProject = null;
    this.specMsg = null;
    this.specComment = false;
    this.specCom = null;
    this.showRedBar = false;
    this.specMsgType = null;
    this.chosenFilter = null;
    //this.envoyerObs.next();
    this.messageService.messageRefreshConv();
  }

  public filterMessage(wantedFilter){
    //console.log(' wantedFilter --> '+ JSON.stringify(wantedFilter));
    if (this.isEmpty(wantedFilter) == true) 
    {
      this.chosenFilterProject = null;
    }
    else 
    {
      this.chosenFilterProject = wantedFilter;    
    }
    this.ged = false;
    this.expanded = true;
    this.specMsg = null;
    this.specComment = false;
    this.specCom = null;
    this.showRedBar = false;
    this.specMsgType = null;
    //this.envoyerObs.next();
    this.messageService.messageRefreshConv();
  }

  isEmpty(obj) {
    if (obj) {
      for(var key in obj) {
        if ((key != 'projectId') && obj[key]) {
          return false;
        }
      }
    }
    return true;
  }

  getAvatarlUrl(avatarLink){
    return environment.server_url+avatarLink;
  }



  userInCopyHasNoRead(element){
    if(!element) return false;
    for (var i = 0; i < element.copy.length; i++) {
      if((element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.copy[i].isRead == false)){
        return true;
      }
    }
    return false;
  }

  userInCopyHasRead(element){
    if(!element) return false;
    for (var i = 0; i < element.copy.length; i++) {
      if((element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.copy[i].isRead == true)){
        return true;
      }
    }
    return false;
  }

  isNoRead(element){
    if((element.recipient && element.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.isRecipientRead == false))
    {
      return true;
    }
    return false;
  }

  isRead(element){
    if((element.recipient && element.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.isRecipientRead == true))
    {
      return true;
    }
    return false;
  }


  userIsNotConcerned(element){
    if(!element) return true;
    if(element.recipient && (element.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id']))
    {
      return false;
    }
    for (var i = 0; i < element.copy.length; i++) {
      if(element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']){
        return false;
      }
    } 
    return true;
  }


  userHasRead(element){
    if(!element) return false;
    if((element.recipient && (element.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id'])) && (element.isRecipientRead == true))
    {
      return true;
    }
    else if ((element.recipient && (element.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id'])) && (element.isRecipientRead == false)) {
      return false;
    }
    return false;
  }

  inCopyHasRead(element){
    if(!element) return false;
    for (var i = 0; i < element.copy.length; i++) {
      if((element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.copy[i].isRead == true)){
        return true;
      }
      else if ((element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.copy[i].isRead == false)) {
        return false;
      }
    }
    return false;
  }

  userIsInCopy(element){
    if(!element) return false;
    for (var i = 0; i < element.copy.length; i++) {
      if(element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']){
        return true;
      }
    } 
    return false;
  }

  userIsConcerned(element){
    if(!element) return false;
    if(element.recipient && (element.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id']))
    {
      return true;
    }
    if(element.author && (element.author.id == JSON.parse(localStorage.getItem('currentUser'))['id']))
    {
      return true;
    }
    for (var i = 0; i < element.copy.length; i++) {
      if(element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']){
        return true;
      }
    }
    if (element.answer) {
      for (var i = 0; i < element.answer.copy.length; i++) {
        if(element.answer.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']){
          return true;
        }
      }
    }
    return false;
  }

  userIsConcernedQuestion(element){
    if(!element) return false;
    if(element.recipient && (element.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id']))
    {
      return true;
    }
    /*if(element.author && (element.author.id == JSON.parse(localStorage.getItem('currentUser'))['id']))
    {
      return true;
    }*/
    for (var i = 0; i < element.copy.length; i++) {
      if(element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']){
        return true;
      }
    }
    /*if (element.answer) {
      for (var i = 0; i < element.answer.copy.length; i++) {
        if(element.answer.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']){
          return true;
        }
      }
    }*/
    return false;
  }


  userIsConcernedResponse(element){
    if(!element) return false;
    if(element.recipient && (element.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id']))
    {
      return true;
    }
    /*if(element.author && (element.author.id == JSON.parse(localStorage.getItem('currentUser'))['id']))
    {
      return true;
    }*/
    for (var i = 0; i < element.copy.length; i++) {
      if(element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']){
        return true;
      }
    }
    /*if (element.answer) {
      for (var i = 0; i < element.answer.copy.length; i++) {
        if(element.answer.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']){
          return true;
        }
      }
    }*/
    return false;
  }

  youNeedToMarkReadQuestion(element){
    
    if(!element) return false;

    if(element.isMessageDisabled) return false;

    if((element.isRecipientRead == false) && (element.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id'])){
      return true
    }

    for (var i = 0; i < element.copy.length; i++) {
      if((element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.copy[i].isRead == false)){
         return true
      }
    }

    /*
    if (element.answer) {
      
      if((element.answer.isRecipientRead == false) && (element.answer.type != "COMMENT") && (element.answer.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id'])){
        return true
      }

      for (var i = 0; i < element.answer.copy.length; i++) {
        if((element.answer.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.answer.copy[i].isRead == false)){
           return true
        }
      }

    }*/

    return false;

  }

  youNeedToMarkReadResponse(element){
    
    if(!element) return false;

    if(element.isMessageDisabled) return false;

    /*if((element.isRecipientRead == false) && (element.type != "COMMENT") && (element.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id'])){
      return true
    }

    for (var i = 0; i < element.copy.length; i++) {
      if((element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.copy[i].isRead == false)){
         return true
      }
    }*/

    if (element.answer) {
      
      if((element.answer.isRecipientRead == false) &&  (element.answer.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id'])){
        return true
      }

      for (var i = 0; i < element.answer.copy.length; i++) {
        if((element.answer.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.answer.copy[i].isRead == false)){
           return true
        }
      }

    }

    return false;

  }


  youNeedToMarkAsRead(element){
    
    if(!element) return false;

    if(element.isMessageDisabled) return false;

    if((element.isRecipientRead == false) && (element.type != "COMMENT") && (element.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id'])){
      return true
    }

    for (var i = 0; i < element.copy.length; i++) {
      if((element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.copy[i].isRead == false)){
         return true
      }
    }

    if (element.answer) {
      
      if((element.answer.isRecipientRead == false) && (element.answer.type != "COMMENT") && (element.answer.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id'])){
        return true
      }

      for (var i = 0; i < element.answer.copy.length; i++) {
        if((element.answer.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.answer.copy[i].isRead == false)){
           return true
        }
      }

    }

    return false;

  }

  markAsReadForCopy(element, index){
    if (!element) {
      return null;
    }
    const iduser = JSON.parse(localStorage.getItem('currentUser'))['id'];
    if(element.author.id !== iduser && element.recipient.id !== iduser){
      this.markAsReadCopy(element, index);
      /*
      this.socketService.sendInstant({
        id: element.answer.id,
        action: this.ID,
        type: "MARKREADCOPY",
        author: this.currentUser,
        recipient: this.ongletId,
        text: "",
        copy: "",
        private: false,
        res: "",
        date: ""
      });*/
    } else if (element.answer) {
      console.log("is answer")
      this.dataSource[index].answer.isRecipientRead = true;
      this.deleteMsgNotified(this.dataSource[index], index);
      this.authService.markAsRead(element.answer).subscribe(res => {
        this.authService.obreceiveAsread.next(this.ID);
        this.socketService.sendInstant({
          id: element.answer.id,
          action: this.ID,
          type: "MARKREADRESPONSE",
          author: this.currentUser,
          recipient: this.ongletId,
          text: "",
          copy: "",
          private: false,
          res: "",
          date: ""
        });
        /*
        this.socketService.sendInstant({
          id: "",
          action: this.ID,
          type: "MARKADMIN",
          author: this.currentUser,
          recipient: "",
          text: "",
          copy: "",
          private: false,
          res: "",
          date: ""
        });*/
      },
      error => {
        this.dataSource[index].answer.isRecipientRead = false;
      });
    }
    else
    {
      console.log("not answer")
      this.dataSource[index].isRecipientRead = true;
      this.deleteMsgNotified(this.dataSource[index], index);
      this.authService.markAsRead(element).subscribe(res => {
        this.socketService.sendInstant({
          id: element.id,
          action: this.ID,
          type: "MARKREADQUESTION",
          author: this.currentUser,
          recipient: this.ongletId,
          text: "",
          copy: "",
          private: false,
          res: "",
          date: ""
        });
        if(element.isMessageDisabled) this.authService.obreceiveAsread.next(this.ID);
      },
      error => {
        this.dataSource[index].isRecipientRead = false;
      });
      return;
    }
  }

  markAsReadAll(element){
    
     this.authService.markAsRead(element).subscribe(res => {
        let idCopy = this.readMarkCopyFct(element);
        console.log("mark exec")
        if (idCopy) {
          console.log("mark copiees")
          this.authService.markAsReadCopy(idCopy).subscribe(res => {
            if (element.answer) {
              console.log("mark copy answer")
              this.markAsReadAll(element.answer);
            }
            else
            {
              console.log("mark copy not answer")
              this.authService.obreceiveAsread.next(this.ID);
              this.refreshPageObs.next();
              //this.messageService.messageUpdated();
              return;
            }
          },
          error => {
            //alert("oups une erreur s'est produite!");
            if (element.answer) {
              console.log("error mark copy answer")
              this.markAsReadAll(element.answer);
            }
            else
            {
              console.log("error mark copy not answer")
              this.authService.obreceiveAsread.next(this.ID);
              this.refreshPageObs.next();
              //this.messageService.messageUpdated();
              return;
            }
          });
        }
        else
        {
          console.log("error mark else")
          if (element.answer) {
            console.log("error mark else 111")
            this.markAsReadAll(element.answer);
          }
          else
          {
            console.log("error mark else 222")
            this.refreshPageObs.next();
            this.authService.obreceiveAsread.next(this.ID);
            //this.authService.obreceiveAsread.next(this.ID);
            //this.messageService.messageUpdated();
            return;
          }
        }
      },
      error => {
        console.log("error mark 100")
        //alert("oups une erreur s'est produite!");
        let idCopy = this.readMarkCopyFct(element);
        if (idCopy) {
          this.authService.markAsReadCopy(idCopy).subscribe(res => {
            this.authService.obreceiveAsread.next(this.ID);
            if (element.answer) {
              this.markAsReadAll(element.answer);
            }
            else
            {
              this.refreshPageObs.next();
              //this.messageService.messageUpdated();
              return;
            }
          },
          error => {
            //alert("oups une erreur s'est produite!");
            if (element.answer) {
              this.markAsReadAll(element.answer);
            }
            else
            {
              this.refreshPageObs.next();
              //this.messageService.messageUpdated();
              return;
            }
          });
        }
        else
        {
          if (element.answer) {
            this.markAsReadAll(element.answer);
          }
          else
          {
            this.refreshPageObs.next();
            //this.messageService.messageUpdated();
            return;
          }
        }
      });
  }

  checkAllReadMsg(element){
    if((this.userIsConcerned(element) == true) && (this.youNeedToMarkAsRead(element) == true)){
        return true;
    } else if(element.comment){
        var comments = element.comment;
        comments.forEach(element => {
        if(this.isNoRead(element) || this.userInCopyHasNoRead(element)) return true;
        });
        
    }
    return false;
  }

  deleteMsgNotified(element, i){
    if(this.notifiedmode === true){
      if(this.checkAllReadMsg(element) == false){
          this.dataSource.splice(i, 1);
      }
    }
  }


  readMarkCopyFct(element){
      if (!element) {
        return null;
      }
      for (var i = 0; i < element.copy.length; i++) {
        if((element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.copy[i].isRead == false)){
          return element.copy[i].id;
        }
      }
      return null;
  }

  markAsReadComment(element, indexmsg, indexcmt){
    for (var i = 0; i < element.copy.length; i++) {
      if((element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.copy[i].isRead == false)){
        this.dataSource[indexmsg].comment[indexcmt].copy[i].isRead = true;
        this.deleteMsgNotified(this.dataSource[indexmsg], indexmsg);
        this.authService.markAsReadCopy(element.copy[i].id).subscribe(res => {
          //this.refreshPageObs.next();
          //this.messageService.messageUpdated();
          this.authService.obreceiveAsread.next(this.ID);
          this.socketService.sendInstant({
            id: this.dataSource[indexmsg].id,
            action: this.ID,
            type: "MARKREADCOMMENT",
            author: this.currentUser,
            recipient: this.ongletId,
            text: "",
            copy: "",
            private: false,
            res: this.dataSource[indexmsg].comment[indexcmt].id,
            date: ""
          });
        },
        error => {
          this.dataSource[indexmsg].comment[indexcmt].copy[i].isRead = false;
          alert("oups une erreur s'est produite!");
        });
        return;
      }
    }
  }

  markAsRead(element){
    if((element.isRecipientRead == false) && (element.type != "COMMENT") && (element.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id'])){
      //console.log('lancement de en recipient');
      this.authService.markAsRead(element).subscribe(res => {
        this.refreshPageObs.next();
        //this.messageService.messageUpdated();
        //this.authService.obreceiveAsread.next(this.ID);
      },
      error => {
        alert("oups une erreur s'est produite!");
      });
    }
    else
    {
      //console.log('lancement de en copie');
       for (var i = 0; i < element.copy.length; i++) {
        if((element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.copy[i].isRead == false)){
          this.authService.markAsReadCopy(element.copy[i].id).subscribe(res => {
            this.refreshPageObs.next();
            //this.messageService.messageUpdated();
            this.authService.obreceiveAsread.next(this.ID);
          },
          error => {
            alert("oups une erreur s'est produite!");
          });
          return;
        }
      }
    }
  }

  markAsReadbis(element){

     this.authService.markAsRead(element).subscribe(res => {
        if (element.answer) {
          this.authService.markAsRead(element.answer).subscribe(res => {
            this.refreshPageObs.next();
            //this.messageService.messageUpdated();
          },
          error => {
            alert("oups une erreur s'est produite!");
          });
        }
        else
        {
          this.refreshPageObs.next();
          //this.messageService.messageUpdated();
        }
      },
      error => {
        alert("oups une erreur s'est produite!");
      });

  }

  markAsReadCopy(element, indexmsg = null){
      for (var i = 0; i < element.copy.length; i++) {
        if((element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.copy[i].isRead == false)){
          this.dataSource[indexmsg].copy[i].isRead = true;
          this.deleteMsgNotified(this.dataSource[indexmsg], indexmsg);
          this.authService.markAsReadCopy(element.copy[i].id).subscribe(res => {
            console.log("read question for copy "+indexmsg)
            //this.refreshPageObs.next();
            if(!element.isMessageDisabled) this.authService.obreceiveAsread.next(this.ID);
            //this.messageService.messageUpdated()
          },
          error => {
            alert("oups une erreur s'est produite!");
            this.dataSource[indexmsg].copy[i].isRead = false;
          });
        }
      }

      for (var index = 0; index < element.answer.copy.length; index++) {
        if((element.answer.copy[index].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.answer.copy[index].isRead == false)){
          this.dataSource[indexmsg].answer.copy[index].isRead = true;
          this.authService.markAsReadCopy(element.answer.copy[index].id).subscribe(res => {
            console.log("read response for copy")
            this.authService.obreceiveAsread.next(this.ID);
          },
          error => {
            alert("oups une erreur s'est produite!");
            this.dataSource[indexmsg].answer.copy[index].isRead = false;
          });
        }
      }
  }


  fctinfoProjet(): void{
    const dialogRef = this.dialog.open(projectInfoDialog, {
      width: '500px',
      data: this.projectFull
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed fctinfoProjet '+result); 
        if (result && result.deleteReturn) {
          this.authService.obRefreshProjet.next();
          this.router.navigate(['/']);
        }else if (result) {
          this.projectFull = result;
          //this.authService.obRefreshProjet.next();
       }  

    });
  }

  changeSubject(){
    
    if (this.showTool == true) {
      this.showTool = false;
      this.messageService.setShowProjectTutobis(false);
    }

    const dialogRef = this.dialog.open(subjectInfodialog, {
      width: '500px',
      data: {
        projectData : this.projectFull,
        sendMessageForm : this.sendMessageForm
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed fctinfoProjet '+result); 
      if (result) {
       this.sendMessageForm.patchValue({
          subProject: result
        });
      } 
    });

  }

  disabledMessage(messageData): void{
    var copies = [];
    if(messageData.copy.length){
      messageData.copy.forEach(element => {
        //if(element.user && element.user.id)
        copies.push(element.user.id);
      })
    }
    this.authService.disableMessage(messageData)
    .subscribe(res => {
      this.refreshPageObs.next();
      //this.messageService.messageUpdated();
      this.messageService.obdisableMessage.next(this.ID);
      this.socketService.sendInstant({
        id: messageData.id,
        action: this.ID,
        type: "DISABLE",
        author: this.currentUser,
        recipient: messageData.recipient.id,
        text: "",
        copy: copies,
        private: messageData.private,
        res: res,
        date: res['dateCreated']
      });
    },
    error => {
      alert("oups une erreur s'est produite!");
    }); 
  }

  disabledMessageNotified(messageData, index): void{
    var copies = [];
    if(messageData.copy.length){
      messageData.copy.forEach(element => {
        //if(element.user && element.user.id)
        copies.push(element.user.id);
      })
    }
    this.authService.disableMessage(messageData)
    .subscribe(res => {
      this.dataSource.splice(index, 1);
      //this.messageService.messageUpdated();
      this.messageService.obdisableMessage.next(this.ID);
      this.socketService.sendInstant({
        id: messageData.id,
        action: this.ID,
        type: "DISABLE",
        author: this.currentUser,
        recipient: messageData.recipient.id,
        text: "",
        copy: copies,
        private: messageData.private,
        res: res,
        date: res['dateCreated']
      });
    },
    error => {
      alert("oups une erreur s'est produite!");
    }); 
  } //to do here 

  recalls(messageData): void{
    this.authService.recalls(messageData).subscribe(res => {
      this.refreshPageObs.next();
      //this.messageService.messageUpdated();
      alert("Rappel envoyé");
    },
    error => {
      //console.log('error '+JSON.stringify(error));   
      alert(error['error']['hydra:description']);
    }); 
  }
 
  compare( a, b ) {
    if ( a.fullName.toLowerCase() < b.fullName.toLowerCase() ){
      return -1;
    }
    if ( a.fullName.toLowerCase() > b.fullName.toLowerCase() ){
      return 1;
    }
    return 0;
  }

  /*getContact(){

    if (!this.iDProjet) {
      return;
    }

    this.authService.getTheProject(this.iDProjet)
    .subscribe(res => {      
      var tmpArrayUser = res['userProject'];
      this.contacts = [];
      for (var i = 0; i < tmpArrayUser.length; i++) {
        if(tmpArrayUser[i].user){
          this.contacts.push(tmpArrayUser[i].user);
        }
      }
      this.contacts.sort(this.compare);
    }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
    });
    
  }*/

  /*getSujet(idProject){
    if (!idProject) {
      return;
    }
    this.projectId = idProject;
    this.authService.getTheProject(idProject).subscribe(res => {
      this.projectTags = res['tags'];
      console.log({projectTags:this.projectTags})
    }, (error) => {
       //console.log(' getTheProject subscribe error '+error.statusText);
    });
  }
*/

  getProjectTags(){
    if (!this.iDProjet) {
      return;
    }

    this.projectId = this.iDProjet;

    this.authService.getTheProject(this.iDProjet).subscribe(res => {
      this.projectTags = res['tags'];
      this.projectTags.sort((a, b) => {
        if ( a.libelle.toLowerCase() < b.libelle.toLowerCase() ){
          return -1;
        }
        if ( a.libelle.toLowerCase() > b.libelle.toLowerCase() ){
          return 1;
        }
        return 0;
      });
      this.selectSubject.open();
    }, (error) => {
       //console.log(' getTheProject subscribe error '+error.statusText);
    });
  }

  getInfoProject(){

   // console.log('getInfoProject id project => '+ this.iDProjet);

    if (!this.iDProjet) {
      return;
    }
    this.saveTheme = null
    this.projectId = this.iDProjet;
    console.log("iddproject "+this.projectId)
    this.authService.getTheProject(this.iDProjet).subscribe(res => {
      this.projectTags = res['tags'];
      this.projectTags.sort((a, b) => {
        if ( a.libelle.toLowerCase() < b.libelle.toLowerCase() ){
          return -1;
        }
        if ( a.libelle.toLowerCase() > b.libelle.toLowerCase() ){
          return 1;
        }
        return 0;
      })
      this.getProjectExp();
      this.getProjectDest();
    }, (error) => {
       //console.log(' getTheProject subscribe error '+error.statusText);
    });
    
  }

  getProjectExp(){
    this.authService.getContactsExpID(this.iDProjet).subscribe(res => {
      //var tmpArrayUser = res['hydra:member'];
      this.contactsExp = [];
      this.contactsExp = res['hydra:member'];
      this.contactsExp.sort(this.compare);
      /*
      for (var i = 0; i < tmpArrayUser.length; i++) {
        if(tmpArrayUser[i].user){
          this.contactsExp.push(tmpArrayUser[i].user);
        }
      }
      this.contactsExp.sort(this.compare);
      */
    }, (error) => {
       //console.log(' getTheProject subscribe error '+error.statusText);
    });
  }

  getProjectDest(){
    this.authService.getContactsRecipID(this.iDProjet).subscribe(res => {
      var tmpArrayUser = res['hydra:member'];
      this.contactsDest = [];
      for (var i = 0; i < tmpArrayUser.length; i++) {
        if(tmpArrayUser[i].id){
          this.contactsDest.push(tmpArrayUser[i]);
        }
      }
      this.contactsDest.sort(this.compare);
    }, (error) => {
       //console.log(' getTheProject subscribe error '+error.statusText);
    });
  }


  getReceiver(recipientid){
    this.authService.getRecipientsBySender(this.iDProjet, recipientid).subscribe(res => {
      var tmpArrayUser = res['hydra:member'];
      this.contactsDest = [];
      for (var i = 0; i < tmpArrayUser.length; i++) {
        if(tmpArrayUser[i].id){
          this.contactsDest.push(tmpArrayUser[i]);
        }
      }
      this.contactsDest.sort(this.compare);
    }, (error) => {
       //console.log(' getTheProject subscribe error '+error.statusText);
    });
  }


  launchSearchInstant(){
    this.searchItem = true;
    this.modeDoc = false;
    this.hiddenSearchEngin = true;

    this.chosenFilterInstant = { 
      transmitterinstant: this.transmitterinstant,
      dateBeforeinstant: this.dateBeforeinstant,
      dateAfterinstant: this.dateAfterinstant,
      typeinstant: this.typeinstant,
      keyWordinstant: this.keyWordinstant,
      smartNumber: null
    };
    //this.getMessagesInstant();
    if(!this.transmitterinstant && !this.dateBeforeinstant && !this.dateAfterinstant && !this.typeinstant && !this.keyWordinstant){
      this.search = false;
    } else this.search = true;
    this.EventLaunchSearch.emit(this.chosenFilterInstant);
    this.searchMessagesInstant();
    //this.search = true;

  }


  launchSearch(){

    this.modeDoc = false;
    this.notifiedmode = false;
    localStorage.setItem('notified', "false");

    this.chosenFilter = { 
      transmitter: this.transmitter,
      recipient: this.recipient,
      copy: this.copy,
      projectId: this.projectId,
      dateBefore: this.dateBefore,
      dateAfter: this.dateAfter,
      dateDeadline: this.dateDeadline,
      dateDeadlineAfter: this.dateDeadlineAfter,
      tag: this.tag,
      statut: this.statut, 
      type: this.type,
      keyWord: (this.keyWord ? this.keyWord.trim() : null),
      smartNumber: this.smartNumber
    };
    //console.log(' launchSearch '+ JSON.stringify(this.chosenFilter) );
    this.EventLaunchSearch.emit(this.chosenFilter);
    if(this.transmitter == null && this.recipient == null && this.copy == null && this.dateBefore  == null && this.dateAfter  == null && this.dateDeadline == null && this.dateDeadlineAfter == null && this.tag == null && this.statut == null && this.type == null && this.keyWord == null && this.smartNumber == null){
      this.isSearch = false;
    } else this.isSearch = true;

  }

  launchSearchDocInstant(){
    if(this.typeinstant=="http") {
      alert("Vous ne pouvez pas sélectionner le type lien pour rechercher un document");
      return;
    }
    this.searchItem = true;
    this.modeDoc = true;
    this.hiddenSearchEngin = true;

    this.chosenFilterInstant = { 
      transmitterinstant: this.transmitterinstant,
      dateBeforeinstant: this.dateBeforeinstant,
      dateAfterinstant: this.dateAfterinstant,
      typeinstant: this.typeinstant,
      keyWordinstant: this.keyWordinstant,
      smartNumber: "projectinstant"
    };
    //this.getMessagesInstant();
    /*
    if(!this.transmitterinstant && !this.dateBeforeinstant && !this.dateAfterinstant && !this.typeinstant && !this.keyWordinstant){
      this.search = false;
    } else this.search = true;
    */
    //console.log(' launchSearchDoc '+ JSON.stringify(this.chosenFilter) );
    this.EventLaunchSearchDoc.emit(this.chosenFilterInstant);
    this.searchinstant = false;
  }

  launchSearchDoc(){
    console.log("type "+this.type)
    if(this.type=="http") {
      alert("Vous ne pouvez pas sélectionner le type lien pour rechercher un document");
      return;
    }
    this.modeDoc = true;

    this.chosenFilter = { 
      transmitter: this.transmitter,
      recipient: this.recipient,
      copy: this.copy,
      projectId: this.projectId,
      dateBefore: this.dateBefore,
      dateAfter: this.dateAfter,
      dateDeadline: this.dateDeadline,
      dateDeadlineAfter: this.dateDeadlineAfter,
      tag: this.tag,
      statut: this.statut,
      type: this.type, 
      keyWord: (this.keyWord ? this.keyWord.trim() : null),
      smartNumber: this.smartNumber, 
      drive: this.drive
    };
    //console.log(' launchSearchDoc '+ JSON.stringify(this.chosenFilter) );
    this.EventLaunchSearchDoc.emit(this.chosenFilter);

  }

  launchSearchInit(){

    this.modeDoc = false;
    //this.hiddenSearchEngin = true;

    this.chosenFilter = { 
      transmitter: this.transmitter,
      recipient: this.recipient,
      copy: this.copy,
      projectId: this.projectId,
      dateBefore: this.dateBefore,
      dateAfter: this.dateAfter,
      dateDeadline: this.dateDeadline,
      dateDeadlineAfter: this.dateDeadlineAfter,
      tag: this.tag,
      statut: this.statut, 
      type: this.type,
      keyWord: (this.keyWord ? this.keyWord.trim() : null),
      smartNumber: this.smartNumber
    };
    //console.log(' launchSearch '+ JSON.stringify(this.chosenFilter) );
    this.EventLaunchSearch.emit(this.chosenFilter);

  }

  checkMsgBar(element){
    if (this.notifiedmode && ((element.author.id == this.currentUser || element.recipient.id) && !element.answer 
          || ((this.userIsConcerned(element) == true) && (this.youNeedToMarkAsRead(element) == true))
          ) ){
      return true;
    } else return false;
  }

  public resetSearch(){

    this.notifiedmode = false;
    localStorage.setItem('notified', "false");
    this.transmitter = null;
    this.recipient = null;
    this.copy=null,
    this.projectId = null;
    this.dateBefore  = null;
    this.dateAfter  = null;
    this.dateDeadline = null;
    this.dateDeadlineAfter = null;
    this.tag = null;
    this.statut = null;
    this.type = null;
    this.keyWord = null;
    this.smartNumber = null;
    this.isSearch = false;
 
    /*this.getContact();
    this.getSujet(this.iDProjet); */
    this.getInfoProject();
    this.launchSearchInit();
    /*
    if(this.modeDoc == false) 
    {
      this.launchSearch();
    }
    else
    {
      this.launchSearchDoc();
    }*/

  }

  public resetSearchInstant(){
    this.transmitterinstant = null;
    this.dateBeforeinstant  = null;
    this.dateAfterinstant  = null;
    this.typeinstant = null;
    this.keyWordinstant = "";
    this.search = false;
    this.modeDoc = false;
    this.hiddenSearchEngin = true;

  }

  public projecthasChange(newIdProject){

    //console.log(' projecthasChange => '+newIdProject);
    
    this.iDProjet = newIdProject;
    this.ID = newIdProject;
    this.transmitter = null;
    this.recipient = null;
    this.projectId = null;
    this.dateBefore  = null;
    this.dateAfter  = null;
    this.dateDeadline = null;
    this.dateDeadlineAfter = null;
    this.tag = null;
    this.statut = null;
    this.type = null;
    this.keyWord = null;
    this.smartNumber = null;
    this.resetforminstant();

    /*this.getContact();
    this.getSujet(this.iDProjet);*/
    this.getInfoProject();
    

  }

  public projectlibChanged(libelle){

    //console.log(' projecthasChange => '+newIdProject);
    console.log("liiib "+libelle)
    this.projectFull.libelle = libelle;
    

  }

  public projectImageChanged(url){
    console.log("urrrr : "+url)
    this.projectFull.imageUrl = url;
  }

  public refreshInfoFilter(){  
    /*this.getContact();
    this.getSujet(this.iDProjet);*/
    this.getInfoProject();
  }

  openDialogInfoWrite(){
    
    const dialogRef = this.dialog.open(infoWriteMessageDialog, {
      width: '900px',
      data:{}
    });

    dialogRef.afterClosed().subscribe(result => {
       
      if (result){ 
       
      }

    })

  }

  openDialogEcheance(){
    
    const dialogRef = this.dialog.open(DeadLineConvDialog, {
      width: '500px',
      data:{}
    });
 
    dialogRef.afterClosed().subscribe(result => {
       
      if (result){ 
        //console.log({resultatdate:result});
        /*
        this.sendMessageForm.get('deadLine').setValue(result.deadline); 
        result.deadline = moment(result.deadline).format('DD-MM-YYYY');
        this.echeance = result.deadline.toLocaleString( );
        this.sendMessageForm.get('relanceOne').setValue(result.Relance1);
        this.sendMessageForm.get('relanceTwo').setValue(result.Relance2);
        this.sendMessageForm.get('relanceTree').setValue(result.Relance3);
        */
      }

    })

  }



}

@Component({
  selector: 'recipientList-dialog',
  templateUrl: './recipientList-dialog.html',
  styleUrls: ['./recipientList-dialog.scss']
})
export class RecipientListeDialog {

  filter = "noall";
  projectusers = [];
  projectusersInit = [];
  copiesSeelected = null;
  searchRecipient : any = null;
  ID : any;
  project: string;
  addingCopies = [];
  librecipient = "";

  constructor(
    public dialogRef: MatDialogRef<SubjectListeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public  dialog: MatDialog)   {
      this.ID = data.idProjet;
      this.project = '/api/projects/' + this.ID;
      this.projectusers = data.projectUsers;
      this.projectusersInit = data.projectUsers;
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      console.log("leeeen : "+this.projectusers.length)
  }

  getAvatarlUrl(avatarLink){
    return environment.server_url+avatarLink;
  }

  selectAll(){
    this.addingCopies = [];
    this.projectusers.forEach((item) => this.addingCopies.push(item.user));
  }

  fctCheckCopy(user){ 
    this.dialogRef.close(user);
  }

  fctRemoveCopy(userInfo){
    const index = this.addingCopies.indexOf(userInfo);
    if (index > -1) {
      this.addingCopies.splice(index, 1);
    }
  }

  onSearch() {
    if (this.searchRecipient.trim() === '' || this.searchRecipient == null) {
      this.searchRecipient = null;
      this.projectusers = this.projectusersInit;
      this.filterProjects(this.filter);
    } else {
      var search = this.searchRecipient.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      this.projectusers = this.projectusersInit.filter(bank => bank.user.givenName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1 || bank.user.familyName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1 );
    }
  }

  validateCopies(){
    this.dialogRef.close(this.addingCopies);
  }

  onNoClick(): void {
      this.dialogRef.close();
  }

  filterProjects(val : any){
    this.filter = val;
    if(val == "date"){
        this.projectusers.sort((a, b) => {
          if ( a.user.createdAt > b.user.createdAt ){
            return -1;
          }
          if ( a.user.createdAt < b.user.createdAt ){
            return 1;
          }
          return 0;
        })
    } else {
      this.projectusers.sort((a, b) => {
        if ( a.user.givenName.toLowerCase() < b.user.givenName.toLowerCase() ){
          return -1;
        }
        if ( a.user.givenName.toLowerCase() > b.user.givenName.toLowerCase() ){
          return 1;
        }
        return 0;
      })
    }
  }

}

@Component({
  selector: 'copyList-dialog',
  templateUrl: './copyList-dialog.html',
  styleUrls: ['./copyList-dialog.scss']
})
export class CopyListeDialog {

  filter = "noall";
  copyusers = [];
  copyusersInit = [];
  copiesSeelected = [];
  searchCopy : any = null;
  ID : any;
  project: string;
  addingCopies = [];

  constructor(
    public dialogRef: MatDialogRef<SubjectListeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public  dialog: MatDialog)   {
      this.ID = data.idProjet;
      this.project = '/api/projects/' + this.ID;
      this.copyusers = data.projectCopies;
      this.copyusersInit = data.projectCopies;
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      console.log("leeeen : "+this.copyusers.length)
  }

  getAvatarlUrl(avatarLink){
    return environment.server_url+avatarLink;
  }

  selectAll(){
    if(this.addingCopies.length == this.copyusersInit.length){
      this.addingCopies = [];
    } else {
      this.addingCopies = [];
      this.copyusersInit.forEach((item) => this.addingCopies.push(item.user));
    }
  }

  fctCheckCopy(userInfo){ 
    if(this.addingCopies && !this.addingCopies.includes(userInfo)){
      this.addingCopies.push(userInfo);
    } else this.fctRemoveCopy(userInfo);
  }

  fctRemoveCopy(userInfo){
    const index = this.addingCopies.indexOf(userInfo);
    if (index > -1) {
      this.addingCopies.splice(index, 1);
    }
  }

  onSearch() {
    if (this.searchCopy.trim() === '' || this.searchCopy == null) {
      this.searchCopy = null;
      this.copyusers = this.copyusersInit;
    } else {
      var search = this.searchCopy.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      this.copyusers = this.copyusersInit.filter(bank => bank.user.givenName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1 || bank.user.familyName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(search) > -1 );
    }
  }

  validateCopies(){
    this.dialogRef.close(this.addingCopies);
  }

  onNoClick(): void {
      this.dialogRef.close();
  }

  filterProjects(val : any){
    this.filter = val;
    if(val == "all"){
        this.copyusers.sort((a, b) => {
          if ( a.createdAt > b.createdAt ){
            return -1;
          }
          if ( a.createdAt < b.createdAt ){
            return 1;
          }
          return 0;
        })
    } else {
      this.copyusers.sort((a, b) => {
        if ( a.libelle.toLowerCase() > b.libelle.toLowerCase() ){
          return -1;
        }
        if ( a.libelle.toLowerCase() < b.libelle.toLowerCase() ){
          return 1;
        }
        return 0;
      })
    }
  }

}

@Component({
  selector: 'subjectList-dialog',
  templateUrl: './subjectList-dialog.html',
  styleUrls: ['./subjectList-dialog.scss']
})
export class SubjectListeDialog {

  updclicked = false;
  searchProject : any = null;
  filterRead = false;
  ID : any;
  projectTags = [];
  projectTagsInit = [];
  updateSubject = "";
  filter = "alpha";
  step = 1;
  namesubject = "";
  renameLength = 0;
  currentUser : any;
  indexUpd = 0;
  subjectUpd : any;
  project: string;
  @ViewChild('tagname', {static: false}) missionname: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<SubjectListeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private socketService: SocketService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public  dialog: MatDialog)   {
      this.ID = data.idProjet;
      this.project = '/api/projects/' + this.ID;
      this.projectTags = data.projectTags;
      this.projectTagsInit = data.projectTags;
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

  onNoClick(): void {
      this.dialogRef.close();
  }

  addProjectToArray(){
    // this.arraySubject.push({libelle:this.newProjectSubject})
    //var newProjectSubject = (<HTMLInputElement>document.getElementById("missionname")).value;
    var newProjectSubject = this.missionname.nativeElement.value;
    if(newProjectSubject.trim() == "" || newProjectSubject.trim().length == 0){
      alert("Veuillez entrer un thème");
      return;
    }
    this.authService.addTagToProject({
      libelle:newProjectSubject,
      project:this.project
    }).then((res)=>{
      this.missionname.nativeElement.value = "";
      this.projectTags.push(res);
      this.projectTags.sort((a, b) => {
        if ( a.libelle.toLowerCase() < b.libelle.toLowerCase() ){
          return -1;
        }
        if ( a.libelle.toLowerCase() > b.libelle.toLowerCase() ){
          return 1;
        }
        return 0;
      })
      this.backToList();
      this.socketService.sendInstant({
        id: "",
        action: this.ID,
        type: "ADDSUBJECT",
        author: this.currentUser,
        recipient: "",
        text: "",
        copy: "",
        private: false,
        res: res,
        date: ""
      });
      //this.getProjectInfo();
    }).catch(err=>{
      console.log(err)
    });
  }

  backToList(){
    this.updclicked = false;
    this.updateSubject = "";
    this.subjectUpd = null;
    this.indexUpd = 0;
    this.step = 1;
  }

  confirmUpdate(){
    this.updateSubject = (<HTMLInputElement>document.getElementById("updateSubject")).value;
    if (this.updateSubject.trim().length == 0) {
      alert("Veuillez entrer un thème");
      return;
    }
    this.authService.updateTag(this.subjectUpd.id,{libelle:this.updateSubject}).then((res)=>{
      this.projectTags[this.indexUpd].libelle = this.updateSubject;
      //this.cancelUpdSubject();
      this.socketService.sendInstant({
        id: "",
        action: this.ID,
        type: "RENAMESUBJECT",
        author: this.currentUser,
        recipient: "",
        text: "",
        copy: "",
        private: false,
        res: res,
        date: ""
      });
      this.backToList();
      //this.dialogRef.close();
    }).catch(err=>console.log(err))
  }

  filterProjects(val : any){
    this.filter = val;
    if(val == "date"){
        this.projectTags.sort((a, b) => {
          if ( a.createdAt > b.createdAt ){
            return -1;
          }
          if ( a.createdAt < b.createdAt ){
            return 1;
          }
          return 0;
        })
    } else {
      this.projectTags.sort((a, b) => {
        if ( a.libelle.toLowerCase() < b.libelle.toLowerCase() ){
          return -1;
        }
        if ( a.libelle.toLowerCase() > b.libelle.toLowerCase() ){
          return 1;
        }
        return 0;
      })
    }
    //this.projectLists.filter(item => (this.projectMapFace[item.id] > 0));
  }
      

  deleteTag(sub){
    if(confirm("Voulez vraiment suppprimer le thème "+sub.libelle)) {
      this.authService.deleteTag(sub.id).then((res)=>{
        const index = this.projectTags.findIndex(item => item.id === sub.id);
            if (index !== -1) {
              const elementAaa = this.projectTags.splice(index, 1)[0];
            }
      }).catch(err=>console.log(err))
    }
  }

  showUpdateTag(sub, i){
    this.subjectUpd = sub;
    this.indexUpd = i;
    this.updateSubject = sub.libelle;
    this.renameLength = this.updateSubject.length;
    this.step = 2;
    /*
    this.projectTags.find(item => item.id === sub.id).updated = true;
    this.updateSubject = sub.libelle;
    */
  }

  onSearch() {
    if (this.searchProject.trim() === '' || this.searchProject == null) {
      this.searchProject = null;
      this.projectTags = this.projectTagsInit;
      this.filterProjects(this.filter);
    } else {
      var searchText = this.searchProject.trim().toLowerCase();
      this.projectTags = this.projectTagsInit.filter(it => 
        it.libelle.toLowerCase().includes(searchText)
      );
      //return this.projectTags.filter(it => it['libelle'].toLowerCase().includes(searchText));
    }
  }

  selectSubject(subject){
    this.dialogRef.close(subject);
  }

  clickaddmission(){
    this.step = 3;
  }

  updDialog(): void{
    this.updclicked = !this.updclicked;
  }
}

@Component({
  selector: 'memberListe-dialog',
  templateUrl: './memberListe-dialog.html',
  styleUrls: ['./memberListe-dialog.scss']
})
export class DialogMemberListe {
  
  userID = JSON.parse(localStorage.getItem('currentUser'))['id'];
  userAdmin = false;
  projectUsers = [];
  secteur ="";
  currentUserInfo: any;

  fname = '';
  lname = '';
  dob = '';
  jobT = '';
  email = '';
  gender = 'male';
  description = '';
  phone = '';
  img: any;
  contactInfo: any;
  
  currentUser : any;
  ID: any;
  mode = 1;
  userStatus = null;
  contacts = [];
  project_lib: string;
  addingMember = false;
  searchContact: any;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  showNewContactInput = false;
  @ViewChild('emailInput', {static: true}) emailInput: ElementRef;
  username : any;

  constructor(
    public dialogRef: MatDialogRef<DialogMemberListe>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private messageService: MessageService,
    private socketService: SocketService,
    private router: Router,
    public dialog: MatDialog) {
      
      this.secteur = localStorage.getItem('secteur');
      var currentUserData = JSON.parse(localStorage.getItem('currentUser'));
      this.currentUserInfo = currentUserData.givenName + currentUserData.familyName;
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'))['id']; 
      this.username = JSON.parse(localStorage.getItem('currentUser'))['givenName'] +" "+ JSON.parse(localStorage.getItem('currentUser'))['familyName']; 
      this.ID = data.idProjet;
      this.authService.getTheProject(data.idProjet)
      .subscribe(res => {
        var tmpArrayUser = res['userProject'];
        this.projectUsers = [];
        var users = tmpArrayUser.filter(a => a.user != null);
        users.sort((a, b) => {
          if (a.user.fullName.toLowerCase() < b.user.fullName.toLowerCase()){
            return -1;
          }
          if (a.user.fullName.toLowerCase() > b.user.fullName.toLowerCase() ){
            return 1;
          }
          return 0;
        });

        /*for (var i = 0; i < users.length; i++) {
            if(users[i].isCreator){
              this.projectUsers.unshift(users[i]);
            }else{
              this.projectUsers.push(users[i]);
            }
        }*/
        this.projectUsers = users.sort((a, b) => {
          // Admins en premier
          if (a.isCreator) return -1;
          if (b.isCreator) return 1;
        
          // Co-admins ensuite
          if (a.isAdmin && !a.isCreator) return -1;
          if (b.isAdmin && !b.isCreator) return 1;
        
          // Les autres membres à la fin
          return 0;
        });
        var invited = tmpArrayUser.filter(a => a.user == null);
        invited.sort((a, b) => {
          if (a.email < b.email){
            return -1;
          }
          if (a.email > b.email ){
            return 1;
          }
          return 0;
        });
        for (var i = 0; i < invited.length; i++) {
              this.projectUsers.push(invited[i]);
        }
        for (var i = 0; i < this.projectUsers.length; i++) {
          if((this.projectUsers[i].isAdmin == true) && (this.projectUsers[i].user.id == this.userID))
          {
            this.userAdmin = true;
          }
        }
         
      });


  }

  goBack(){
    this.mode = 1;
  }

  getbackGroundColor(){
    if (this.data.projectAction) {
      return "#645994"
    }
    else if (this.data.projectMeetting){
      return "#5d97eb"
    }
    else
    {
      return "#57b031"
    }
  }

  loadContact(url?: string){
    url = url ? url : undefined;
    this.authService.getContacts(url).subscribe(res => {
      this.contacts = this.contacts.concat(res['hydra:member']);
    }); 
  }

  fctAddMember() {
    console.log("thisdata : "+this.data)
    this.authService.idProjectMember.emit(this.ID);
    this.loadContact();

    this.authService.getTheProject(this.ID)
      .subscribe(res => {
        //console.log(res)
        this.project_lib = res['libelle'];
        var tmpArrayUser = res['userProject'];
        for (var i = 0; i < tmpArrayUser.length; i++) {
          if(tmpArrayUser[i].user){
            this.projectUsers.push(tmpArrayUser[i]);
          }
        }

        let userStatus = []; 
        for (let index = 0; index < this.projectUsers.length; index++) {
          console.log("length : "+userStatus.length)
          if(this.projectUsers[index]['user'])
          userStatus[this.projectUsers[index]['user']['id']] = this.projectUsers[index]['status'];

        }
        this.userStatus = userStatus;
        this.data = "/api/projects/"+this.data;
        //console.log(this.userStatus)
      }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
      });
    this.mode = 2;
    //alert("mode "+this.mode)
  }

  newContact(){
    if (this.showNewContactInput == true)  
      this.showNewContactInput = false;
    else
      this.showNewContactInput = true;
  }


   fctAddcontactByMail(){

      // Process checkout data here
      //console.log('Your order has been submitted', customerData);
       // stop here if form is invalid
      if (this.emailFormControl.status == 'INVALID') {
        return;
      }

      var result = this.emailFormControl.value;

      this.authService.addUserProject("/api/projects/"+this.ID, result, true).subscribe(res => {
         //console.log('retour ajout membre mail => '+JSON.stringify(res));
        this.searchContact = "";
        alert('Invitation envoyé'); 
        
        if(res['user'] && res['user']['id']){
          var newUser = res;
          this.userStatus[res['user']['id']] = res['status'];
          this.projectUsers.push(newUser);
          this.messageService.needRefreshFiltre();
         }

         //this.emailFormControl.value = null;
         this.emailInput.nativeElement.value = "";
      },
      (error) => {
       
        if(error.error && error.error.violations){
          alert(error.error.violations[0].message);
        } else if(error.error && error.error['hydra:description']){
          alert(error.error['hydra:description']);
        } else
        {
          alert(error.statusText);
        }

      });

   }

  onSubmitForm(customerData) {
     // Process checkout data here
    //console.log('Your order has been submitted', customerData);

     // stop here if form is invalid
    if (this.emailFormControl.status == 'INVALID') {
      return;
    }

    this.authService.addUserProject("/api/projects/"+this.ID, customerData, true).subscribe(res => {
       //console.log('retour ajout membre mail => '+JSON.stringify(res));
      this.searchContact = "";
      alert('Invitation envoyé'); 
      
      if(res['user'] && res['user']['id']){
        var newUser = res;
        this.userStatus[res['user']['id']] = res['status'];
        this.projectUsers.push(newUser);
        this.messageService.needRefreshFiltre();
       }
    },
    (error) => {
     
      if(error.error && error.error.violations){
        alert(error.error.violations[0].message);
      }
      else
      {
        alert(error.statusText);
      }

    });

  }

  fctAdMember(userInfo){
    if(this.addingMember) return false;
    this.addingMember = true;
    this.authService.addUserProject("/api/projects/"+this.ID, userInfo.id, null).subscribe(res => {
       //console.log('retour ajout membre '+JSON.stringify(res['user']));
       if(res){
        var newUser = res;
        this.userStatus[res['user']['id']] = res['status'];
        this.projectUsers.push(newUser);
        this.searchContact = "";
        console.log("sended : "+this.username);
        this.socketService.sendInstant({
          id: "0",
          action: "0",
          type: "INVITATION",
          author: this.currentUser,
          recipient: this.username,
          text: res['user']['id'],
          copy: "",
          private: false,
          res: res,
          project_lib: this.project_lib,
          date: res['dateCreated']
        });
       }
       this.addingMember = false;
    },
    (error) => {
      this.addingMember = false;
      if(error.error && error.error.violations){
        alert(error.error.violations[0].message);
      }
      else
      {
        alert(error.statusText);
      }

    });

  }

  updateListUser(contact,values){
    this.authService.updateUserProject(contact, values)
    .subscribe(res => {
      for (var i = 0; i < this.projectUsers.length; i++) {
        if(res['id'] ===  this.projectUsers[i]['id'])
          this.projectUsers[i] = res;
      }
    });
  }


  updateUserProject(contact, key){
    let values = null;
    switch (key) {
      case 1:
        values = {isAdmin: false};
        this.updateListUser(contact, values);
        this.socketService.sendInstant({
          id: "",
          action: this.ID,
          type: "DELETEADMIN",
          author: this.userID,
          recipient: this.currentUserInfo,
          text: "",
          copy: "",
          private: false,
          res: contact.user.id,
          date: ""
        });
        break;
      case 2:
        values = {isMember: false};
        this.updateListUser(contact, values);
        break;
      case 3:
        values = {isAdmin: true};
        this.updateListUser(contact, values);
        console.log("recc : "+JSON.stringify(this.currentUserInfo))
        this.socketService.sendInstant({
          id: "",
          action: this.ID,
          type: "MARKADMIN",
          author: this.userID,
          recipient: this.currentUserInfo,
          text: "",
          copy: "",
          private: false,
          res: contact.user.id,
          date: ""
        });
        break;
      case 5:
        values = {isMember: true};
        this.updateListUser(contact, values);
        break;
      case 4:
        const fullname = contact.user ? contact.user.fullName : contact.email;
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '350px',
          data: "Vous êtes sur le point de retirer "+fullname+" du projet ?"
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result) {
            this.authService.deleteUserProject(contact)
            .subscribe(res => {
              for (var i = 0; i < this.projectUsers.length; i++) {
                if(contact['id'] ===  this.projectUsers[i]['id'])
                  this.projectUsers.splice(i, 1); 
              }
            });
          }
        });
        break;
      default:
        break;
    }
  }

  getAvatarlUrl(avatarLink){
    return environment.server_url+avatarLink;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  showProfil2(idUser){
    this.getCurrentUser(idUser);
    this.mode = 3;
  }

  getCurrentUser(idUser){
    // console.log('getCurrentUser paramètre id ==> '+this.idUser);
     this.authService.getInfoContact(idUser)
     .subscribe( dataContact => {
       this.contactInfo = dataContact;
       this.img = (this.contactInfo.imageUrl ? this.getAvatarlUrl(this.contactInfo.imageUrl) : '../../../../assets/img/default-avatar.jpg');
       this.fname =  this.contactInfo.givenName;
       this.lname =  this.contactInfo.familyName;
       if(this.contactInfo.birthDate) {
         if (this.contactInfo.birthDate.date) 
         {
           this.dob = this.contactInfo.birthDate.date;
         }
         else
         {
           this.dob = this.contactInfo.birthDate;
         }
       }
       //this.dob =  this.contactInfo.birthDate;
       this.jobT =  this.contactInfo.jobTitle;
       this.email =  this.contactInfo.email;
       this.gender =  this.contactInfo.gender;
       this.description =  this.contactInfo.description;
       this.phone =  this.contactInfo.telephone;
 
     }, (error) => {
         console.log('Uh-oh, an error occurred! : ' +JSON.stringify(error));
         alert('une erreur est survenue');
     });
 
   }

  showProfil(idUser): void{
    this.dialog.closeAll();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'contactpanel';
    dialogConfig.id = 'balchal718';
    dialogConfig.width = '550px';
    dialogConfig.autoFocus = false;
    dialogConfig.data = { idUser: idUser};
    const dialogRef = this.dialog.open(ProfileComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      /*
      if (result) {
        this.profile = result;
        this.router.navigate(['/profile/' + result]);
      }
      this.contactsicon = false;
      if(window.location.href.endsWith("/dashboard")){
        this.filicon = true;
      } else if(window.location.href.endsWith("/notificationsSetting") || window.location.href.endsWith("/statistique")){
        this.parametreicon = true;
      } else if(window.location.href.endsWith("/profile")){
        this.profilicon = true;
      }*/
    });
    //this.router.navigate(['/profile/'+idUser]);
    this.dialogRef.close();
    
  }


}
 

/////////////////// add contact view dialog ///////////////////

/* Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'addMember-dialog',
  templateUrl: './profileupd-dialog.html',
  styleUrls: ['./profileupd-dialog.scss']
})
export class ProfileupdDialog implements OnInit{

  currentUser : any;
  firstFormGroupOne: FormGroup;
  img: any;
  imageFil = null;
  imggroupe = null;

  constructor(
    public dialogRef: MatDialogRef<ProfileupdDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private messageService: MessageService,
    private socketService: SocketService,
    private cd: ChangeDetectorRef,
    private router: Router,
    public dialog: MatDialog) {
      this.imggroupe = this.data.img;
      console.log("image : "+this.imggroupe)
      var currentUserData = JSON.parse(localStorage.getItem('currentUser'));
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'))['id']; 

  }

  

  getAvatarlUrl(avatarLink){
    return environment.server_url+avatarLink;
  }

  ngOnInit(): void {
    this.firstFormGroupOne = new FormGroup({
      projetNameOne: new FormControl('')
   });
   this.firstFormGroupOne.get('projetNameOne').patchValue(this.data.libelle);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getbackGroundColor(){
    return "#57b031";
  }

  fileChangeEvent(event: any): void {
    const dialogRef = this.dialog.open(croppedimagedialog, {
      width: '700px',
      data: {event: event}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result !== null) {
        this.img = result.event;
        const reader = new FileReader();
        this.imageFil = result.fileToReturn;
        reader.readAsDataURL(this.imageFil);
          
        reader.onload = () => {
          this.cd.markForCheck();
        }; 
      }
    });
  }

  onProfilePicClick(): void {
    const fileInput = document.getElementById('fileInput') as HTMLElement;
    fileInput.click();
  }

  submit(customerData){
    this.authService.changeProjetName(customerData.projetNameOne, this.data.idProjet).subscribe(
      res => {
        if(this.imageFil) {
          this.authService.updateProject2(this.data.idProjet, this.imageFil).subscribe( data => {
             //console.log('retour saveProfile '+JSON.stringify(data));
             data['imagechanged'] = true;
            alert("updated");
            this.dialogRef.close(data);
              
          }, (error) => {
              //console.log('Uh-oh, an error occurred! : ' +JSON.stringify(error));
              alert('une erreur est survenue');
          });
        } else {
          res['imagechanged'] = false;
          alert("updated");
          this.dialogRef.close(res);
        }
      },
      (error) => {
        alert('Erreur ! : ' + error)
      }
    );
  }
}

@Component({
  selector: 'addMember-dialog',
  templateUrl: './addMember-dialog.html',
  styleUrls: ['./addMember-dialog.scss']
})
export class DialogAddMember implements AfterViewInit  {
 
  contacts = [];
  secteur = "";
  userStatus = null;
  showNewContactInput = false;
  searchContact: any;
  currentUser : any;
  username : any;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  matcher = new MyErrorStateMatcher();
  addingMember = false;
  @ViewChild('emailInput', {static: true}) emailInput: ElementRef;
  @Input() data: string;
  projectUsers = [];
  project_lib: string;

  constructor(
    //public dialogRef: MatDialogRef<DialogAddMember>,
    //@Inject(MAT_DIALOG_DATA) public data: any,
     private authService: AuthService,
     private messageService: MessageService,
     private socketService: SocketService) {
      console.log("dataaa : "+this.data)
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'))['id']; 
      this.username = JSON.parse(localStorage.getItem('currentUser'))['givenName'] +" "+ JSON.parse(localStorage.getItem('currentUser'))['familyName']; 
      /*this.authService.getContacts().subscribe( dataContact => {
        this.contacts = dataContact['hydra:member'];
      });*/
      console.log('Project11 :', this.data);
      
      this.contacts = [];
      this.secteur = localStorage.getItem('secteur');

      


      //console.log(' this.data.projectUsers ==> '+JSON.stringify(this.data.projectUsers));
      
  }

  ngAfterViewInit() {
    this.authService.idProjectMember.subscribe((res) => {
    this.loadContact();

      this.authService.getTheProject(this.data)
      .subscribe(res => {
        //console.log(res)
        this.project_lib = res['libelle'];
        var tmpArrayUser = res['userProject'];
        for (var i = 0; i < tmpArrayUser.length; i++) {
          if(tmpArrayUser[i].user){
            this.projectUsers.push(tmpArrayUser[i]);
          }
        }

        let userStatus = []; 
        for (let index = 0; index < this.projectUsers.length; index++) {
          console.log("length : "+userStatus.length)
          if(this.projectUsers[index]['user'])
          userStatus[this.projectUsers[index]['user']['id']] = this.projectUsers[index]['status'];

        }
        this.userStatus = userStatus;
        this.data = "/api/projects/"+this.data;
        //console.log(this.userStatus)
      }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
      });
    });
  }

  getbackGroundColor(){
    return "#57b031";/*
    if (this.data.projectAction) {
      return "#645994"
    }
    else
    {
      return "#57b031"
    }*/
  }

  loadContact(url?: string){
    console.log("idd "+this.data)
    console.log("name "+this.project_lib)
    url = url ? url : undefined;
    this.authService.getContacts(url).subscribe(res => {
      this.contacts = this.contacts.concat(res['hydra:member']);

      //this.contacts = res['hydra:member'];
      /*
      if(res['hydra:view']['hydra:next']){
        this.loadContact(res['hydra:view']['hydra:next'].replace("/api", ""));
      }*/
    }); 
  }


  newContact(){
    if (this.showNewContactInput == true)  
      this.showNewContactInput = false;
    else
      this.showNewContactInput = true;
  }


   fctAddcontactByMail(){

      // Process checkout data here
      //console.log('Your order has been submitted', customerData);
       // stop here if form is invalid
      if (this.emailFormControl.status == 'INVALID') {
        return;
      }

      var result = this.emailFormControl.value;

      this.authService.addUserProject(this.data, result, true).subscribe(res => {
         //console.log('retour ajout membre mail => '+JSON.stringify(res));
        this.searchContact = "";
        alert('Invitation envoyé'); 
        
        if(res['user'] && res['user']['id']){
          var newUser = res;
          this.userStatus[res['user']['id']] = res['status'];
          this.projectUsers.push(newUser);
          this.messageService.needRefreshFiltre();
         }

         //this.emailFormControl.value = null;
         this.emailInput.nativeElement.value = "";
      },
      (error) => {
       
        if(error.error && error.error.violations){
          alert(error.error.violations[0].message);
        } else if(error.error && error.error['hydra:description']){
          alert(error.error['hydra:description']);
        } else
        {
          alert(error.statusText);
        }

      });

   }

  onSubmitForm(customerData) {
     // Process checkout data here
    //console.log('Your order has been submitted', customerData);

     // stop here if form is invalid
    if (this.emailFormControl.status == 'INVALID') {
      return;
    }

    this.authService.addUserProject(this.data, customerData, true).subscribe(res => {
       //console.log('retour ajout membre mail => '+JSON.stringify(res));
      this.searchContact = "";
      alert('Invitation envoyé'); 
      
      if(res['user'] && res['user']['id']){
        var newUser = res;
        this.userStatus[res['user']['id']] = res['status'];
        this.projectUsers.push(newUser);
        this.messageService.needRefreshFiltre();
       }
    },
    (error) => {
     
      if(error.error && error.error.violations){
        alert(error.error.violations[0].message);
      }
      else
      {
        alert(error.statusText);
      }

    });

  }

  fctAdMember(userInfo){
    if(this.addingMember) return false;
    this.addingMember = true;
    this.authService.addUserProject(this.data, userInfo.id, null).subscribe(res => {
       //console.log('retour ajout membre '+JSON.stringify(res['user']));
       if(res){
        var newUser = res;
        this.userStatus[res['user']['id']] = res['status'];
        this.projectUsers.push(newUser);
        this.searchContact = "";
        console.log("sended : "+this.username);
        this.socketService.sendInstant({
          id: "0",
          action: "0",
          type: "INVITATION",
          author: this.currentUser,
          recipient: this.username,
          text: res['user']['id'],
          copy: "",
          private: false,
          res: res,
          project_lib: this.project_lib,
          date: res['dateCreated']
        });
       }
       this.addingMember = false;
    },
    (error) => {
      this.addingMember = false;
      if(error.error && error.error.violations){
        alert(error.error.violations[0].message);
      }
      else
      {
        alert(error.statusText);
      }

    });

  }

  getAvatarlUrl(avatarLink){
    return environment.server_url+avatarLink;
  }

  onNoClick(): void {
    //this.dialogRef.close();
  }

}


////////////////// dialog project info ////////////////////


@Component({
  selector: 'projectInfo-dialog',
  templateUrl: './projectInfo-dialog.html',
  styleUrls: ['./projectInfo-dialog.scss']
})
export class projectInfoDialog {
  
  imageFil = null;
  img = null;
  currentUser: any;
  secteur = "";
  isAdmin = false; 
  noChangeImage = true;
  modeChangeName = false;
  firstFormGroup: FormGroup;
  modeSupp = false;
  userData : any;
  nameproject = "";
  constructor(
    public dialogRef: MatDialogRef<projectInfoDialog>,
    @Inject(MAT_DIALOG_DATA) public projectData: any,
    private authService: AuthService,
    private messageService: MessageService,
    private cd: ChangeDetectorRef,
    private _formBuilder: FormBuilder) {
      this.isAdmin = true;
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'))['id']; 
      this.secteur = localStorage.getItem('secteur');
      this.nameproject = this.projectData.name;
      /*
      if(this.projectData.creator.id == this.currentUser) {
        this.isAdmin = true;
      }*/

      this.firstFormGroup = this._formBuilder.group({
        projetName: ['', Validators.required]
      });

  }

  ChoseImage(event): void{  

    const reader = new FileReader();
    var files = event.files;

    // loop through files
    for (var i = 0; i < files.length; i++) {
      this.imageFil = files[i];
    }

    reader.readAsDataURL(this.imageFil);
      
    reader.onload = () => {
      // need to run CD since file load runs outside of zone
      this.cd.markForCheck();
      this.img = reader.result;
    }; 

  }
  
  onClickChangeName(): void{
    this.modeChangeName = true;
  }


  onClickAnnuler(): void{
    this.modeChangeName = false;
  }


  onClickValidate(): void{
    if(this.imageFil != null){
     this.authService.projectImage(this.projectData.id, this.imageFil)
     .subscribe(  
      (success) => {
        //console.log('retour image success => '+JSON.stringify(success));
        alert('Modification enregistré');
      },
      (error) => {
        alert('Erreur ! : ' + error)
      });
    }
    else
    {
      alert('Veuillez choisir une image pour le projet')
    }
  }


  onSubmitProjectName(customerData){
  
    //console.log('firstFormGroup ==>' , customerData);

    if (this.firstFormGroup.get('projetName').value.trim().length == 0 ){
      this.firstFormGroup.patchValue({
        projetName: '' 
      });
    }

     // stop here if form is invalid
    if (this.firstFormGroup.invalid) {
      return;
    }

     // Creation du projet
    this.authService.changeProjetName(customerData.projetName, this.projectData.id).subscribe(
      res => {
        //console.log('retour firstFormGroup ==>'+ res);
        //this.stepper.selectedIndex = 1;
        this.projectData = res;
        this.dialogRef.close(res);
      },
      (error) => {
        alert('Erreur ! : ' + error)
      }
    );

     this.modeChangeName = true;

  }

  onClickdelete(): void {
    this.modeSupp = true;
  }

  onClickCancelSupp(): void{
    this.modeSupp = false;
  }
  
  onNoClick(): void {
    this.dialogRef.close();
  }

  onClickConfirmeDelete(): void{
    this.authService.deleteProject(this.projectData.id).subscribe(
      res => {   
        //this.messageService.messageUpdated();
        this.authService.obRefreshProjet.next();
        this.dialogRef.close({deleteReturn: true});
        //this.stepper.selectedIndex = 1;
      },
      (error) => {
        alert('Erreur ! : ' + error)
      }
    );
  }

}

////////////////// dialog transfert ///////////////////

@Component({
  selector: 'dialogTransfert',
  templateUrl: 'transfertdialog.html',
  styleUrls: ['./transfertdialog.scss']
})
export class transfertdialog {

  submitted = false;
  sendMessageForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<transfertdialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
     private authService: AuthService) {
    
      this.sendMessageForm = this.formBuilder.group({
        Destinataire: ['', Validators.required],
        EnCopie: '',
        message:  ''
     });
  }

  onSubmitForm(customerData) {

     // Process checkout data here
    // console.log(JSON.stringify(this.files));
    
    //console.log('Your order has been submitted', customerData);

    this.submitted = true;
     // stop here if form is invalid
    if (this.sendMessageForm.invalid) {
      return;
    }
    
    this.sendTransfert(customerData);

    this.submitted = false;
    //this.sendMessageForm.reset();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getbackGroundColor(){
    if (this.data.projectAction) {
      return "#6e73b2"
    }
    else if (this.data.projectMeetting){
      return "#5d97eb"
    }
    else
    {
      return "#57b031"
    }
  }

  getErrorMessage() {
    return this.sendMessageForm.controls.Destinataire.hasError('required') ? 'Veuillez entrer une adresse email' :
        this.sendMessageForm.controls.Destinataire.hasError('email') ? 'Email non valide' : '';
  }

  sendTransfert(customerData){
    //console.log({affichage:this.data});
    this.authService.transfertByMail(customerData, this.data.message).subscribe(  
    (success) => {
      //console.log('retour image success => '+JSON.stringify(success));
      alert('Message transferé');
      this.dialogRef.close();
    },
    (error) => {
      alert('Erreur ! : Veuillez vérifier le mail' /*+ error*/)
    });
  }


}

////////////////// dialog change subject ///////////////////



@Component({
  selector: 'subjectInfo-dialog',
  templateUrl: './subjectInfo-dialog.html',
  styleUrls: ['./subjectInfo-dialog.scss']
})
export class subjectInfodialog {
  
 
  currentUser: any;
  projectTag = [];
  isAdmin = false; 
  renameSubject = '';
  newSubject = '';
  editeSubjectMode = false;
  addSubjectMode = false;
  tagInfo: any;

  constructor(
    public dialogRef: MatDialogRef<subjectInfodialog>,
    @Inject(MAT_DIALOG_DATA) public dataGet: any,
    private authService: AuthService,
    private messageService: MessageService,
    private cd: ChangeDetectorRef,
    private _formBuilder: FormBuilder) {
     
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'))['id']; 
      if(this.dataGet.projectData.creator.id == this.currentUser) {
        this.isAdmin = true;
      }

      this.projectTag = this.dataGet.projectData['tags'];

      /*console.log(' les tag ==> '+ JSON.stringify(this.projectTag[0]));
      console.log(' les tag length ==> '+ this.projectTag.length);*/

  }

  fctChangeSubject(element){
    this.renameSubject = '';
    this.tagInfo = element;
    this.editeSubjectMode = true;
  }

  doTextareaValueChange(ev) {
    this.renameSubject = ev.target.value;
  }

  validerModif(tag){

    if (this.renameSubject.trim() != '') {
      this.authService.changeSubjectName(this.renameSubject, tag.id)
        .subscribe(res => {
          /*console.log(' retour validerModif => '+res);*/
          tag.libelle = this.renameSubject;
          if (tag.id == this.dataGet.sendMessageForm.controls.subProject.value.libelle ) {
            this.dataGet.sendMessageForm.patchValue({
              subProject: tag
            });
          }
          this.tagInfo = null;
          this.editeSubjectMode = false;
          this.renameSubject = '';
          //this.envoyerObs.next();
          this.messageService.messageRefreshConv();
          //this.messageService.messageUpdated();
          this.messageService.needRefreshFiltre();

        }, (error) => {
           //console.log(' getTheProject subscribe error '+error.statusText);
      }); 
    }
    else
    {
      this.tagInfo = null;
      this.editeSubjectMode = false;
      this.renameSubject = '';
    }

  }
  
  fctChoseTag(tag): void {
    this.dialogRef.close(tag);
  }

  fctaddSubjectMode(): void {
    this.addSubjectMode = true;  
  }

  addSubjectCancel(): void {
    this.addSubjectMode = false; 
  }

  addSubject(): void {

    this.authService.addNewSubject(this.newSubject, this.dataGet.projectData.id)
      .subscribe(res => {
        //console.log('res tagg => '+JSON.stringify(res));
        this.projectTag.push(res);
        this.messageService.messageRefreshConv();
        //this.messageService.messageUpdated();
        this.messageService.needRefreshFiltre();
        this.newSubject = '';
        this.addSubjectMode = false; 
      }, (error) => {
       alert('Erreur');
    }); 
  }

  onSelectNewSubProject(newSubProjetName){
    //console.log('newSubProjetName => '+newSubProjetName);
    this.newSubject = newSubProjetName;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

////////////////// dialog info write message ///////////////////


@Component({
  selector: 'infoWriteMessageDialog',
  templateUrl: 'infoWriteMessageDialog.html',
   styleUrls: ['infoWriteMessageDialog.scss']
})
export class infoWriteMessageDialog implements OnInit{
  lang='fr';

  constructor(
    public dialogRef: MatDialogRef<infoWriteMessageDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private messageService: MessageService,
    private _formBuilder: FormBuilder) {
     
  }

  ngOnInit() {
    this.messageService.getTranslate().subscribe((data) => {
      this.lang= data;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

////////////////// dialog info conv ///////////////////

@Component({
  templateUrl: './deadLineConvDialog.html',
  styleUrls: ['./deadLineConvDialog.scss'],
  providers: [DatePipe]
})
export class DeadLineConvDialog {
  
  deadline: Date | null;
  deadlineRelanceOne: Date | null;
  deadlineReLanceTwo: Date | null;
  deadlineReLanceTree: Date | null;
  public minDate = new Date();

  constructor(
    public dialogRef: MatDialogRef<DeadLineConvDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private datePipe: DatePipe) {


  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  
  validate(): void {

    if (!this.deadline) {
      alert('veuillez choisir un délai de réponse');
      return;
    }

    var deadLineJson : any = {
      'deadline': this.deadline,
      'Relance1': this.deadlineRelanceOne,
      'Relance2': this.deadlineReLanceTwo,
      'Relance3': this.deadlineReLanceTree,
    };

    /*if (this.relaunchSevenDaysBefore == true) {
      var temp = new Date(this.deadline);
      temp.setDate(temp.getDate()-7);
      deadLineJson['relaunchArray'].push(this.datePipe.transform(temp, 'yyyy-MM-dd'));
    }

    if (this.relaunchThreeDaysBefore == true) {
      var temp = new Date(this.deadline);
      temp.setDate(temp.getDate()-3);
      deadLineJson['relaunchArray'].push(this.datePipe.transform(temp, 'yyyy-MM-dd'));
    }

    if (this.relaunchOnenDayBefore == true) {
      var temp = new Date(this.deadline);
      temp.setDate(temp.getDate()-1);
      deadLineJson['relaunchArray'].push(this.datePipe.transform(temp, 'yyyy-MM-dd'));
    }*/

    this.dialogRef.close(deadLineJson);
  
  }
 

}



