import { Component, OnInit, Inject, ViewChild, ElementRef, Output, EventEmitter, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import { MatButton, MatDialogConfig, MatInput, MatList, MatRadioChange } from '@angular/material';
import { DashboardDialogComponent, DashboardDialogProjectNotif } from 'src/app/components/dashboard-menu/dashboard-menu.component';
import { MessageService } from 'src/app/services/message/message.service';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Validators, FormControl, FormGroup, FormBuilder} from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, Subject, forkJoin, Observable } from 'rxjs';
import {MatStepper} from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe, Location } from "@angular/common";
import * as introJs from 'intro.js/intro.js';
import { projectInfoDialog } from '../single-project/single-project.component';
import { takeUntil } from 'rxjs/operators';
import { addMemberCompany } from 'src/app/company-member/company-member.component';
import { ConditionalExpr } from '@angular/compiler';
import { SocketService } from 'src/app/services/socket/socket.service';
import { Event } from 'src/app/services/socket/model/event';import { Message } from 'src/app/services/socket/model/message';
import { ActivatedRouteSnapshot } from '@angular/router';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { LoadingScreenService } from 'src/app/services/loading-screen/loading-screen.service';
import { v4 as uuidv4 } from 'uuid';
import { croppedimagedialog } from 'src/app/pages/profile/profile.component';


export interface Projects {
  name: string;
  date: string;
  groupName: string;
  url: string;
}

export interface DialogData {
  objectif: string;
  projetName: string;
  projects: Array<any>;
}


@Component({
  selector: 'app-dashboardnav',
  templateUrl: './dashboardnav.component.html',
  styleUrls: ['./dashboardnav.component.scss']
})
export class DashboardnavComponent implements OnInit, OnDestroy {

  introJS = introJs();

  ngOnDestroy(): void {
    //this.subscription.unsubscribe();
  }

  subscription: Subscription;
  projects: Array<any> = [];
  projectLists: Array<any> = [];
  displayedProjects: Array<any> = [];
  pageSearch = 1;
  projectsIds: Array<any> = [];
  new_message: boolean = false;
  new_invitation: boolean = false;
  new_coadmin: boolean = false;
  new_message_instant: boolean = false;
  prob_cnx: boolean = false; 
  text_baniere = "New interaction(s) received nav";
  text_baniere_body = "";
  text_baniere_instant = "New interaction(s) received nav";
  text_baniere_instant_body = "";
  text_baniere_title = "";
  text_baniere_type = "";
  text_baniere_sujet = "";
  text_baniere_format = "";
  text_baniere_msg = "un message";
  text_baniere_cmn = "un commentaire";
  ban_type = null; 
  ban_proj = null; 
  ban_msg = null; 
  ifme = null;
  projectListsParent: Array<any> = [];
  total_project = 0;
  name: any;
  profile: any;
  commonRef: any;
  notificationload: any;
  //dialog: any;
  token: any;
  objectif: string;
  projetName: string;
  currentProjectId: string;
  projectId: string;
  currentProj : any;
  searchProject : any = null;
  currentUser: any;
  adminrole = false;
  lang = 'fr';
  displayedColumns: string[] = ['Columnsproject'];
  intro = null;
  stepProject = null;
  stepContact = null;
  stepNotif = null;
  stepInvit = null;
  stepBoard = null;
  skipLabel= null;
  doneLabel= null;
  prevLabel= null;
  nextLabel= null;
  showTool = false;
  inProject = false;
  inFace = false;
  route: string;
  userData : any;
  isCurrentuser:boolean = true;
  Url = environment.server_url
  profileImg : any
  @ViewChild('faceAndyouGroups', {static: true}) projectListDiv: ElementRef;
  //@ViewChild('allproject', {static: false}) allproject: ElementRef;
  @ViewChild('scrollMeproj', { static: false }) scrollMeproj: ElementRef;
  @ViewChild('scrollMeprojsearch', { static: false }) scrollMeprojsearch: ElementRef;
  isdataloaded = false;
  canloadagain = true;
  isdataloadedsearch = false;
  canloadagainsearch = true;
  nbrloadmsg = 0;
  pageIndex = 1;
  loadingMessagesTop = false;
  perPage = 20;
  startedPoint = false;
  canload = false;
  scrollposition = 0;
  scrollpositionsearch = 0;
  page = 1;
  updclicked = false;
  projectFull: any;
  secteur = "";
  ioConnection: any;
  socket_service_connection: Subscription;
  socket_service_onMessage: Subscription;
  projectMap = new Map<string, number>();
  projectMapFace = new Map<string, number>();
  filterRead = false;
  private subscribedGroups: string[] = [];
  showtooltip = false;
  private searchTerms = new Subject<string>();

  @Output() projectChosen = new EventEmitter();
  @Output() projectChanged = new EventEmitter();
  @Output() imageChanged = new EventEmitter();
  @Input() ongletId: string;
  createproject = null;
  imageFil = null;
  edit = false;
  fname = '';
  lname = '';
  dob = '';
  jobT = '';
  email = '';
  gender = 'male';
  description = '';
  phone = '';
  oldPassword = '';
  newPassword = '';
  img: any;
  idUser = null;
  contactInfo: any;

  constructor(
    public dialog: MatDialog,
    private authServices: AuthService, 
    private router: Router, 
    private routeAc: ActivatedRoute,
    private interceptor: LoadingScreenService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    location: Location, 
    public datepipe: DatePipe,
    private messageService: MessageService,
    //private routersnap: ActivatedRoute,
    private socketService: SocketService) {
      //this.socketService.initSocket();
      /*
      const snapshot: ActivatedRouteSnapshot = routersnap.snapshot;
      const params = snapshot.params;
      this.newProjectId=params.ID;
      */
    
    
    if (this.messageService.getListProject().length > 0) {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'))['id'];
      this.projectLists = this.messageService.getListProject();
      this.projectMap = this.messageService.getProjectMap();
      this.projectMapFace = this.messageService.getProjectMapFace();
      this.total_project = this.projectLists.length;
      /*
      this.projectLists.forEach((value,index)=>{
          //this.projectMap[value.id] = 0;
          //this.initIoConnection(value.id);
          if(value.type !=="CHILD") this.projectListsParent.push(value);
          value.userProject.forEach((value2,index)=>{
                if(value2.user && value2.user.id == this.currentUser){
                  this.projectMap[value.id] = value2.number;
                  console.log('value '+value2.number);
                } else console.log('no value ')
          });
      });*/
      //this.projectListsParent = this.projectLists;
      /*
      this.projectLists.forEach(element => {
        if(element.type !== "CHILD") this.projectListsParent.push(element);
      });*/
    }
    else
    {
      console.log("constructor load")
      this.loadProject();
      //console.log('tableau non rempli '+JSON.stringify(this.projectMap));
    }
    this.secteur = localStorage.getItem('secteur');
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))['id'];
    this.userData = JSON.parse(localStorage.getItem('currentUser'));
    this.isCurrentuser = (this.userData.id == this.idUser || this.idUser == null) ? true : false;
      if(this.idUser)
        {
          this.getCurrentUser();
        } else {
          this.getMyInfo();
      }
    if(this.userData.imageUrl){
      this.profileImg = this.Url + this.userData.imageUrl
    }
      //console.log({userData:this.userData})
    this.authServices.obProjetAdded.subscribe((result: any) =>{
      const id = result.id;
      console.log("iddddd "+id)
      this.authServices.getTheProject(id).subscribe((res)=>{
        console.log("in res "+JSON.stringify(res))
      /*
        console.log("before refresh load "+JSON.stringify(result.userProject))
        result.userProject.shift();
        console.log("in refresh load "+JSON.stringify(result.userProject))
        result.userProject = result.userProject.filter(userProject => {
          return userProject.user && userProject.user.id === this.currentUser;
        });
        console.log("after refresh load "+JSON.stringify(result.userProject))
        this.projectLists.unshift(result);*/
        res['userProject'] = res['userProject'].filter(userProject => {
          return userProject.user && userProject.user.id === this.currentUser;
        });
        this.projectLists.unshift(res)
        this.perPage += 1;
        this.projectLists[0].userProject.filter(userProject => {
          return userProject.user && userProject.user.id === this.currentUser;
        });
        this.projectMapFace[id]=0;
        this.initIoConnection(id);
        this.messageService.setProjectMapFace(this.projectMapFace);
        this.messageService.setListProject(this.projectLists);
    })
  })
    this.authServices.obRefreshProjet.subscribe(() =>{
      console.log("refresh load")
      this.loadProject();
    });
    

    /*
    this.subscription = this.messageService.getMessage().subscribe(() => { 
      this.loadNotifProjet();
    });*/
    this.messageService.obdisableMessage.subscribe((idProject: any) => {
      console.log("disable from nav")
        this.updateProjectNotifications(idProject).subscribe((total) => {
          this.projectMapFace[idProject]=total;
          //this.projectLists.filter(item => item.id == message.action)[0].userProject[0].face = total;
        })
    });
    // console.log("translate", this.translate.get('guide.intro').subscribe);
    
    
    this.setVar();
    this.setTheGuide();
    this.messageService.getTranslate().subscribe((data) => {
      this.setVar();
      this.setTheGuide();
    });
    

    this.messageService.getGuide().subscribe(() => { 
      this.introJS.start().oncomplete(function() {
        window.localStorage['guide'] = 1;
      });
    });
    
    if (this.messageService.getShowCreateProjectTuto() == true) {
      this.showTool = true; 
    }
    
    this.messageService.getMessageIsSend().subscribe(() => {
       this.showTool = false; 
       this.messageService.setShowCreateProjectTuto(false);
    });

    this.interceptor.getCnx().subscribe((isOffline) => {
      console.log("cnx received");
      if (isOffline) {
        this.ban_type = "connexion";
        this.text_baniere_title ="Problème de connexion";
        this.text_baniere = "vous avez un problème de connexion"; 
        this.prob_cnx = true;
        // Faites quelque chose lorsque la connexion est perdue
        console.log('La connexion est perdue. Affichez une alerte ou prenez une autre action.');
      } else {
        // Faites quelque chose lorsque la connexion est rétablie
        console.log('La connexion est rétablie.');
        //this.refreshPage();
      }
    });

    /*this.authServices.obreceiveType.subscribe((idproject : string) =>{
      console.log("idd "+idproject);
      console.log("json "+JSON.stringify(this.projectMap));
      if(this.projectMap[idproject].length > 0){
        const currentProj = this.projectLists.filter(item => item.id == idproject)[0];
        const userprojectid = currentProj.userProject.filter(item => item.user && item.user.id == this.currentUser)[0].id;
        //console.log("us proj "+JSON.stringify(userprojectid));
        this.authServices.setNbrMsgProjet(userprojectid).subscribe(dataAccepte => {
        this.projectMap[idproject] = 0;
        this.messageService.setProjectMap(this.projectMap);
        }, (error) => {
          alert('une erreur est survenue');
        });
      }
    });*/

  }

  getMyInfo(){

    const userData = JSON.parse(localStorage.getItem('currentUser'));
    console.log(' Avatar --> '+userData.imageUrl);
    this.img = (userData.imageUrl ? this.getAvatarlUrl(userData.imageUrl) : '../../../../assets/img/default-avatar.jpg');
    this.authServices.photoHeader.emit(userData.imageUrl);
    this.fname = userData.givenName;
    this.lname = userData.familyName;
    //console.log(' anniversaire -> '+JSON.stringify(userData.birthDate));
    if (userData.birthDate) {
      if (userData.birthDate.date) 
      {
        this.dob = userData.birthDate.date;
      }
      else
      {
        this.dob = userData.birthDate;
      }
    }
    this.jobT = userData.jobTitle;
    this.email = userData.email;
    this.gender = userData.gender;
    this.description = userData.description;
    this.phone = userData.telephone;
    

      //console.log('getCurrentUser this.gender  id ==> ',this.gender );
      
  }

  fctCancel(){
    this.edit = false;
    this.getMyInfo();
  }

  saveProfile() {
     
    let latest_date = null;

    if (this.dob) {
      latest_date = this.datepipe.transform(this.dob, 'dd-MM-yyyy');
    }

    // console.log('getCurrentUser paramètre id ==> '+this.idUser);
    this.authServices.udapteUser((this.fname ? this.fname : ''), (this.lname ? this.lname : ''), (latest_date ? latest_date : null), (this.jobT ? this.jobT : ''), (this.email ? this.email : '')
      ,(this.gender ? this.gender : ''), (this.description ? this.description : ''), (this.phone ? this.phone : ''), (this.oldPassword ? this.oldPassword : ''), (this.newPassword ? this.newPassword : '')).subscribe( data => {
       //console.log('retour saveProfile '+JSON.stringify(data));
       this.edit = false; 
       this.getMyInfo();
       alert('Modification(s) enregistré');
    }, (error) => {
        //console.log('Uh-oh, an error occurred! : ' +JSON.stringify(error));
        alert('une erreur est survenue');
    });


    if(this.imageFil) {
      this.authServices.udapteUserImage(this.imageFil).subscribe( data => {
         //console.log('retour saveProfile '+JSON.stringify(data));
        this.getMyInfo();  
      }, (error) => {
          //console.log('Uh-oh, an error occurred! : ' +JSON.stringify(error));
          alert('une erreur est survenue');
      });
    
    }

  }

  getCurrentUser(){
    // console.log('getCurrentUser paramètre id ==> '+this.idUser);
     this.authServices.getInfoContact(this.idUser)
     .subscribe( dataContact => {
       this.contactInfo = dataContact;
       this.img = (this.contactInfo.imageUrl ? this.getAvatarlUrl(this.contactInfo.imageUrl) : '../../../../assets/img/default-avatar.jpg');
       this.fname =  this.contactInfo.givenName;
       this.lname =  this.contactInfo.familyName;
       if(this.contactInfo.birthDate) {
         if (this.contactInfo.birthDate.date) 
         {
           this.dob = this.contactInfo.birthDate.date;
         }
         else
         {
           this.dob = this.contactInfo.birthDate;
         }
       }
       //this.dob =  this.contactInfo.birthDate;
       this.jobT =  this.contactInfo.jobTitle;
       this.email =  this.contactInfo.email;
       this.gender =  this.contactInfo.gender;
       this.description =  this.contactInfo.description;
       this.phone =  this.contactInfo.telephone;
 
     }, (error) => {
         console.log('Uh-oh, an error occurred! : ' +JSON.stringify(error));
         alert('une erreur est survenue');
     });
 
   }

  logout() {
    //console.log('logging out');
    this.authServices.logout();
  }

  openProfile(){
    this.createproject = 3;
  }

  openContacts(){
    this.createproject = 5;
  }

  openNotif(){
    this.createproject = 6;
  }

  refreshPage(): void {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([this.router.url]);
    });
  }

  private initIoConnection(id_project): void {
    // get All projects
    /*
    if (!this.subscribedGroups.includes(id_project)) {
      console.log("group " + id_project);
      this.subscribedGroups.push(id_project);  // Ajouter l'id_project au tableau
      this.socketService.subscribeToRoom(id_project);
    } else {
      console.log("Already subscribed to group " + id_project);
    }*/
    this.socketService.subscribeToRoom(id_project);
  }
  
  setVar(){
    this.translate.get('guide.intro').subscribe(value => { 
      this.intro = value;
   });
   this.translate.get('guide.stepProject').subscribe(value => { 
     this.stepProject = value;
   });
   this.translate.get('guide.stepContact').subscribe(value => { 
     this.stepContact = value;
   });
   this.translate.get('guide.stepNotif').subscribe(value => { 
     this.stepNotif = value;
   });
   this.translate.get('guide.stepInvit').subscribe(value => { 
     this.stepInvit = value;
   });
   this.translate.get('guide.stepBoard').subscribe(value => { 
     this.stepBoard = value;
   });
   this.translate.get('guide.skipLabel').subscribe(value => { 
    this.skipLabel = value;
    });
    this.translate.get('guide.doneLabel').subscribe(value => { 
      this.doneLabel = value;
    });
    this.translate.get('guide.prevLabel').subscribe(value => { 
      this.prevLabel = value;
    });
    this.translate.get('guide.nextLabel').subscribe(value => { 
      this.nextLabel = value;
    });
  }


  setTheGuide(){
    setTimeout(() => {
      this.introJS.setOptions({
        skipLabel: this.skipLabel,
        doneLabel: this.doneLabel,
        prevLabel: this.prevLabel,
        nextLabel: this.nextLabel,
        steps: [
          { 
            intro: this.intro,
          },
          {
            element: '#stepProject',
            intro: this.stepProject,
            position: 'left'
          },
          {
            element: '#stepContact',
            intro: this.stepContact,
            position: 'bottom'
          },
          {
            element: '#stepNotif',
            intro: this.stepNotif,
            position: 'bottom'
          },
          {
            element: '#stepInvit',
            intro: this.stepInvit,
            position: 'bottom'
          },
          {
            element: '#stepBoard',
            intro: this.stepBoard,
            position: 'bottom'
          }
        ]
      });

      // if(!window.localStorage['guide']){
      //   this.introJS.start().oncomplete(function() {
      //       window.localStorage['guide'] = 1;
      //   });
      // }

    }, 2000);
  }

  trierProject(projectid){
    const index = this.projectLists.findIndex(item => item.id === projectid);
    if (index !== -1 && index !== 0) {
      const elementAaa = this.projectLists.splice(index, 1)[0];
      this.projectLists.unshift(elementAaa);
      this.messageService.setListProject(this.projectLists);
    }
    this.scrollToTop();
  }

  getSupp(date1, date2){
    if(date1>= date2){
      return date1;
    } else return date2;
  }

  ngOnInit() {
    console.log("id onglet from dashnav "+this.ongletId);
    this.setupSearchSubscription();
    this.new_message = false;
    this.filterRead = false;
    this.new_message_instant = false;
    this.new_invitation = false;
    this.prob_cnx = false;
    this.new_coadmin = false;
    //this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.adminrole = this.userData.roles.includes('ROLE_MANAGER');

    this.authServices.newMessageSubject.subscribe((object: any) =>{ 
      this.new_message = object;
    });

    this.authServices.newMessageInvSubject.subscribe((object: any) =>{ 
      this.new_invitation = object;
    });

    this.authServices.ban_typeSubject.subscribe((object: any) =>{ 
      this.ban_type = object;
    });

    this.authServices.ban_projSubject.subscribe((object: any) =>{ 
      this.ban_proj = object;
    });

    this.authServices.text_baniere_titleSubject.subscribe((object: any) =>{ 
      console.log("before subject : "+object)
      this.text_baniere_title = object;
      console.log("after subject : "+this.text_baniere_title)
    });

    this.authServices.text_baniere_type.subscribe((object: any) =>{ 
      this.text_baniere_type = object;
    });

    this.authServices.text_baniere_sujet.subscribe((object: any) =>{ 
      this.text_baniere_sujet = object;
    });

    this.authServices.text_baniere_format.subscribe((object: any) =>{ 
      this.text_baniere_format = object;
    });

    this.authServices.text_baniereSubject.subscribe((object: any) =>{ 
      this.text_baniere = object;
    });

    this.authServices.text_baniere_instantSubject.subscribe((object: any) =>{ 
      this.text_baniere_instant = object;
    });

    this.authServices.text_baniere_instant_body.subscribe((object: any) =>{ 
      this.text_baniere_instant_body = object;
    });

    this.authServices.text_baniere_body.subscribe((object: any) =>{ 
      this.text_baniere_body = object;
    });

    this.authServices.newMessageInstantSubject.subscribe((object: any) =>{ 
      this.new_message_instant = object;
    });

    this.authServices.newMessageInvSubject.subscribe((object: any) =>{ 
      this.new_invitation = object;
    });

    this.authServices.newCoadminSubject.subscribe((object: any) =>{ 
      this.new_coadmin = object;
    });

    this.authServices.initNotifInstant.subscribe((projectId) =>{ 
      console.log("proj rec "+projectId)
      this.projectLists.filter(item => item.id == projectId)[0].userProject[0].number = 0;
      //console.log('dans le smarnumber idmessage ==> '+idMessage);
    });

    this.authServices.selectProject.subscribe((res) => {
      console.log("reees : "+res)
      if(res == "select"){
        this.updclicked = !this.updclicked;
      } else if(res == "params"){
        this.createproject = 2;
      }
    });

    //this.calculateDate();
    //this.total_project = 0;

    
    this.authServices.obinstantMsgsent.subscribe((object: any) =>{ 
      this.ifme = object.idmsg;
      
      this.projectLists.filter(item => item.id == object.id)[0].userProject[0].lasttext = object.text;
      this.projectLists.filter(item => item.id == object.id)[0].userProject[0].lastmsg = object.date;
      this.trierProject(object.id);
      
        
    });

    this.authServices.obreceiveMsgsent.subscribe((object: any) =>{ 
      //this.projectLists.filter(item => item.id == idProject)[0].userProject[0].face += 1;
      this.updateProjectNotifications(object.id).subscribe((total) => {
        this.projectMapFace[object.id]=total;
        this.projectLists.filter(item => item.id == object.id)[0].userProject[0].lastface = object.date;
        this.projectLists.filter(item => item.id == object.id)[0].userProject[0].lasttext = object.text;
        this.projectLists.filter(item => item.id == object.id)[0].userProject[0].lastmsg = object.date;
        this.trierProject(object.id);
        //this.projectLists.filter(item => item.id == message.action)[0].userProject[0].face = total;
      });
    });

    this.authServices.obreceiveAsread.subscribe((idProject: any) =>{ 
      if(this.projectMapFace[idProject] > 0){
        this.projectMapFace[idProject]--;
        this.messageService.setProjectMapFace(this.projectMapFace);
        console.log("comment enlevé "+this.projectMapFace[idProject]);
      } 
      
      
      /*
      console.log("readedd")
      this.updateProjectNotifications(idProject).subscribe((total) => {
        this.projectMapFace[idProject]=total;
      });*/
    });

    
    this.authServices.obreceiveType.subscribe((idproject : any) =>{
      //console.log("obrecei "+this.projectLists.find(item => item.id == idproject)[0].userProject[0].number);
      //this.projectLists.filter(item => item.id == idproject)[0].userProject[0].number = 0;
      /*if(this.projectMap[idproject].length > 0){
        console.log("one one")
        const currentProj = this.projectLists.filter(item => item.id == idproject)[0];
        const userprojectid = currentProj.userProject.filter(item => item.user && item.user.id == this.currentUser)[0].id;
        //console.log("us proj "+JSON.stringify(userprojectid));
        this.authServices.setNbrMsgProjet(userprojectid).subscribe(dataAccepte => {
        this.projectMap[idproject] = 0;
        this.messageService.setProjectMap(this.projectMap);
        }, (error) => {
          alert('une erreur est survenue');
        });
      } */
    });

    

  this.authServices.obProjectDeleted.subscribe((project : any) =>{ 
    this.socketService.sendInstant({
        id: project.id,
        action: project.id,
        type: "DELPROJECT",
        author: this.userData.fullName,
        recipient: this.userData.id,
        text: '',
        copy: "",
        private: false,
        res: "",
        project_lib: project.libelle,
        date: ""
    });
    /*
    document.getElementById('project_'+project.id).parentElement.parentElement.parentElement.remove();
    const projectElement = document.getElementById('project_'+project.id);
    if (projectElement) {
      this.perPage -= 1;
        projectElement.parentElement.parentElement.parentElement.remove();
    }*/
    //alert("Le projet a été supprimé");
  });

    this.routeAc.params.subscribe(paramsId => {
      this.currentProjectId = paramsId.ID;
      this.projectId = paramsId.ID;
      this.messageService.setProjectid(paramsId.ID)
      //this.newProjectId = paramsId.ID;
      console.log("param  "+this.currentProjectId)
    });

  }
  calculateDate(date) {
    var result = 0;
    var today = new Date().setHours(0, 0, 0, 0);
    var yesterday = new Date(new Date().setDate(new Date().getDate()-1)).setHours(0, 0, 0, 0);
    date = new Date(date);
    if (date >= today) {
      result = 1;
    } else if (date >= yesterday) {
      result = 2;
    } else result = 3;
    return result;
  }

  setProjectNotif(idproject){

  }


  changeLang(){
    this.translate.use(this.lang);
    this.messageService.setTranslate(this.lang);
  }

  userIsAdmin(project){
    if (project.creator.id == this.currentUser) {
        return 'admin';
    }
    var member = project['userProject'];
    for (var i = 0; i < member.length; i++) {
      if(member[i].user && member[i].user.id == this.currentUser && member[i].isAdmin == true){
        return 'co-admin';
      }
    }
    return 'membre';
  }

  updateProjectNotifications(id) {
    var total = 0;
    const observables = [
      this.authServices.getAllProjectNotification(0, 1, 10, id)
    ]
    return forkJoin(observables).pipe(
      map((responses: any[]) => { // Définir le type explicite ici
        let total = 0;
        responses.forEach((res) => {
          total += res['hydra:member']['all'];
        });
        return total;
    }));

  }

  updateProjectNotificationsOld(id) {
    /*var sentNotifProjectCount = 0;
    var receiveNotifProjectCount = 0;
    var copyNotifProjectCount = 0;
    var commentNotifProjectCount = 0;*/
    var total = 0;
    const observables = [
      this.authServices.getOnlyProjectNotification(0, 1, 1000, id),

      this.authServices.getOnlyProjectNotification(1, 1, 1000, id),

      this.authServices.getOnlyProjectNotification(2, 1, 1000, id),

      this.authServices.getOnlyProjectNotification(3, 1, 1000, id),
    ]
    return forkJoin(observables).pipe(
      map((responses: any[]) => { // Définir le type explicite ici
        let total = 0;
        responses.forEach((res) => {
         // console.log("--json-- " + JSON.stringify(res));
          total += res['hydra:totalItems'];
        });
        return total;
      })
    );

  }

  isChromeBrowser() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    console.log(userAgent);
    return !userAgent.includes('chrome');
  }

  requestPermission(): Promise<NotificationPermission> {
    if (!("Notification" in window)) {
      console.error("This browser does not support desktop notification");
      return Promise.resolve('denied');
    } else {
      return Notification.requestPermission();
    }
  }

  showNotification() {
    console.log("showen");
    if (document.visibilityState !== 'visible') {
      console.log("isvisible");
      const iconUrl = "../../../../assets/img/face-switch.png";
  
      // Afficher la notification avec une icône de taille plus grande
      const notification = new Notification('Nouveau message', {
        body: 'Vous avez reçu un nouveau message sur votre application.',
        icon: iconUrl
      });
    }
  }

  showNotificationComment() {
    console.log("showen");
    if (document.visibilityState !== 'visible') {
      console.log("isvisible");
      const iconUrl = "../../../../assets/img/face-switch.png";
  
      // Afficher la notification avec une icône de taille plus grande
      const notification = new Notification('Nouveau message', {
        body: 'Vous avez reçu un nouveau commentaire sur votre application.',
        icon: iconUrl
      });
    }
  }

  showNotificationResponse() {
    console.log("showen");
    if (document.visibilityState !== 'visible') {
      console.log("isvisible");
      const iconUrl = "../../../../assets/img/face-switch.png";
  
      // Afficher la notification avec une icône de taille plus grande
      const notification = new Notification('Nouveau message', {
        body: 'Vous avez reçu une réponse sur votre application.',
        icon: iconUrl
      });
    }
  }


  loadProject(url?: string){
    //console.log("init load : ");
    url = url ? url : undefined;
    //var progress = 0;
    //this.socketService.initSocket();
    this.socketService.unsubscribeFromRoom("0");
    this.initIoConnection(0);
    this.messageService.setListProject([]);
    this.projectLists = [];
    this.projectsIds = [];
    this.loadPartProjects();
    this.authServices.getProjectsIds().subscribe(res => {
      var lengthresults = res['hydra:totalItems'] ;
      this.total_project = lengthresults;
      this.projectsIds = res['hydra:member'];
      console.log("long "+this.projectsIds.length)
      this.projectsIds.map((value, index) => {
        this.initIoConnection(value.id);
      });
      if (this.ioConnection) {
        
      } else {
        this.socket_service_onMessage = this.ioConnection = this.socketService.onMessage()
        .subscribe((message: Message) => {
          const projectbtn = document.getElementById('typeprojectin') as HTMLButtonElement;
          const instantbtn = document.getElementById('checkinstantyes') ;
          if (projectbtn) {
            this.inProject = true
            if (instantbtn) {
              this.inFace = false;
            } else {
              this.inFace = true;
            }
          } else {
            this.inProject = false;
          }
          console.log("inProject : "+this.inProject)
          console.log("inFace : "+this.inFace)
          console.log("socket reçu "+JSON.stringify(message));
          //console.log("res : "+JSON.stringify(message));
          var project = this.projectLists.filter(item => item.id == message.action)[0];
          if(!project && ((message.type == "INSTANT") || 
              (message.type == "FACE" && (message.copy.includes(this.currentUser) || (message.recipient == this.currentUser) || (message.author == this.currentUser))) ||
              (message.type == "COMMENT" && message.author !== this.currentUser) ||
              (message.type == "RESPONSE" && message.author !== this.currentUser && (message.copy.includes(this.currentUser) || (message.recipient == this.currentUser)))
              )){
            console.log("project hors liste ");
            this.authServices.getTheProject(message.action)
              .subscribe(response => {
                this.initIoConnection(message.action);
                this.projectLists.unshift(response);
                this.perPage += 1; 
                this.projectLists[0].userProject.filter(userProject => {
                  return userProject.user && userProject.user.id === this.currentUser;
                });
                this.updateProjectNotifications(message.action).subscribe((total) => {
                  this.projectMapFace[message.action]=total;
                });
                this.projectLists = this.projectLists.sort(function (a, b) {
                  if (a.userProject[0].lastmsg > b.userProject[0].lastmsg) { return -1; }
                  if (a.userProject[0].lastmsg < b.userProject[0].lastmsg) { return 1; }
                  return 0;
                })
                this.messageService.setListProject(this.projectLists);
                console.log("proj : "+JSON.stringify(response))
              });
          } else if (message.type == "READMSG" && message.author === this.currentUser && ((this.messageService.getProjectid() !== message.action) || this.inProject == false || (this.inProject == true && this.inFace == true))/* || this.newProjectId == undefined)*/) {
            this.projectLists.filter(item => item.id == message.action)[0].userProject[0].number = 0;
            this.messageService.setListProject(this.projectLists);
          } else if (message.type == "INSTANT" /*&& this.ifme != message.id*/ && ((this.messageService.getProjectid() !== message.action) || this.inProject == false || (this.inProject == true && this.inFace == true))/* || this.newProjectId == undefined)*/) {
            if(message.author !== this.currentUser) this.projectLists.filter(item => item.id == message.action)[0].userProject[0].number += 1;
            this.projectLists.filter(item => item.id == message.action)[0].userProject[0].lasttext = message.text;
            this.projectLists.filter(item => item.id == message.action)[0].userProject[0].lastmsg = message.date;
            this.projectLists.filter(item => item.id == message.action)[0].userProject[0].lastinstant = message.date;
            this.trierProject(message.action);
            this.new_message = false;
            this.new_invitation = false;
            this.new_coadmin = false;
            /*
            this.ban_type = "instant";
            this.ban_proj = message.action;
            this.text_baniere_title = message.res["author"].fullName;
            this.text_baniere_instant = message.project_lib+" - INSTANTANE";
            this.text_baniere_instant_body = "Message : "+(message.res["text"] ? message.res["text"] : "document");
            //this.text_baniere_instant_body = " vous a envoyé "+this.text_baniere_msg;
            this.new_message_instant = true;
            */
            this.authServices.newMessageSubject.next(false);
            this.authServices.ban_typeSubject.next("instant");
            this.authServices.ban_projSubject.next(message.action);
            this.authServices.text_baniere_titleSubject.next(message.res["author"].fullName);
            this.authServices.text_baniere_instantSubject.next(message.project_lib);
            this.authServices.text_baniere_type.next("INSTANTANEE");
            this.authServices.text_baniere_instant_body.next((message.res["text"] ? message.res["text"] : "document"));
            this.authServices.text_baniere_format.next("Message");
            this.authServices.newMessageInstantSubject.next(true);
            this.showNotification();
            console.log("instant 1"+this.new_message_instant);
            /*
            if((this.inProject == true && this.inFace == true && (this.messageService.getProjectid() === message.action))) {
              this.authServices.obreceiveBaniere.next("instant");
            }*/
            this.messageService.setListProject(this.projectLists);
            if(message.author !== this.currentUser) this.playSound();
          } else if(message.type == "INSTANT" && this.ongletId != message.recipient /*&& message.author !== this.currentUser*/ && this.messageService.getProjectid() === message.action){
            this.authServices.obreceiveMessage.next(message.res);
            this.ifme = null;
            console.log("recived instant");
            this.projectLists.filter(item => item.id == message.action)[0].userProject[0].lasttext = message.text;
            this.projectLists.filter(item => item.id == message.action)[0].userProject[0].lastmsg = message.date;
            this.trierProject(message.action);
            if(message.author !== this.currentUser) {
              this.playSound();
              this.showNotification();
              this.new_message = false;
              this.new_invitation = false;
              this.new_coadmin = false;
              /*
              this.text_baniere_title = message.res["author"].fullName;
              this.text_baniere_instant = message.project_lib+" - INSTANTANE";
              this.text_baniere_instant_body = "Message : "+(message.res["text"] ? message.res["text"] : "document");
              //this.text_baniere_instant_body = " vous a envoyé "+this.text_baniere_msg;
              this.new_message_instant = true;
              this.ban_type = "instant";
              this.ban_proj = message.action;
              */
              this.authServices.newMessageSubject.next(false);
              this.authServices.ban_typeSubject.next("instant");
              this.authServices.ban_projSubject.next(message.action);
              this.authServices.text_baniere_titleSubject.next(message.res["author"].fullName);
              this.authServices.text_baniere_instantSubject.next(message.project_lib);
              this.authServices.text_baniere_type.next("INSTANTANEE");
              this.authServices.text_baniere_instant_body.next((message.res["text"] ? message.res["text"] : "document"));
              this.authServices.text_baniere_format.next("Message");
              this.authServices.newMessageInstantSubject.next(true);

              this.socketService.sendInstant({
                id: '',
                action: message.action,
                type: "READMSG",
                author: this.currentUser,
                author_name: '',
                recipient: '',
                text: '',
                copy: "",
                private: false,
                res: '',
                project_lib: '',
                date: ''
              });
              /*
              console.log("instant 2"+this.new_message_instant);
              const userprojectid = this.projectLists.filter(item => item.id == message.action)[0].userProject[0].id;
              
              this.authServices.setNbrMsgProjet(userprojectid).subscribe(dataAccepte => {
              }, (error) => {
                alert('une erreur est survenue');
              });*/
            }
          } else if(message.type == "FACE" /*&& message.author !== this.currentUser*/ && (message.copy.includes(this.currentUser) || (message.recipient == this.currentUser) || (message.author == this.currentUser))){
            if((this.messageService.getProjectid() === message.action) && (this.inProject == true && this.inFace == true)){
              this.authServices.obreceiveFace.next(message.res);
              console.log("json  : "+JSON.stringify(message.res))
            } /*else if((this.messageService.getProjectid() === message.action) && (this.inProject == true && this.inFace == false)){
              this.authServices.obreceiveBaniere.next("face");
            }*/
            if(message.author !== this.currentUser) {
              this.playSound();
              this.showNotification();
              /*
              this.new_message_instant = false;
              this.text_baniere_title = message.res["author"].fullName;
              this.text_baniere = message.project_lib+" - ORGANISE -\t \tsujet: "+message.res["tags"][0].libelle;
              this.text_baniere_body = "Message : "+(message.res["text"] ? message.res["text"] : "document");
              //this.text_baniere_body = " vous a envoyé "+this.text_baniere_msg+" sur le sujet "+message.res["tags"][0].libelle;
              this.new_message = true;
              this.ban_type = "face";
              this.ban_proj = message.action;
              */
              this.authServices.newMessageSubject.next(true);
              this.authServices.ban_typeSubject.next("face");
              this.authServices.ban_projSubject.next(message.action);
              this.authServices.text_baniere_titleSubject.next(message.res["author"].fullName);
              this.authServices.text_baniereSubject.next(message.project_lib);
              this.authServices.text_baniere_sujet.next(message.res["tags"][0].libelle);
              this.authServices.text_baniere_type.next("ORGANISEE");
              this.authServices.text_baniere_body.next((message.res["text"] ? message.res["text"] : "document"));
              this.authServices.text_baniere_format.next("Message");
              this.authServices.newMessageInstantSubject.next(false);
              console.log("face 1"+this.new_message);
            }
            this.projectLists.filter(item => item.id == message.action)[0].userProject[0].lastface = message.date;
            this.projectLists.filter(item => item.id == message.action)[0].userProject[0].lasttext = message.text;
            this.projectLists.filter(item => item.id == message.action)[0].userProject[0].lastmsg = message.date;
            this.trierProject(message.action);
            this.updateProjectNotifications(message.action).subscribe((total) => {
              console.log("total "+total);
              this.projectMapFace[message.action]=total;
            });
          } /*else if(message.type == "FACE" && (message.private == false)){
            if((this.messageService.getProjectid() === message.action) && (this.inProject == true && this.inFace == true)){
              this.authServices.obreceiveFace.next(message.res);
            } 
            this.projectLists.filter(item => item.id == message.action)[0].userProject[0].lastmsg = message.date;
            this.projectLists.filter(item => item.id == message.action)[0].userProject[0].lasttext = message.text;
            this.authServices.newMessageSubject.next(true);
            this.authServices.ban_typeSubject.next("face");
            this.authServices.ban_projSubject.next(message.action);
            this.authServices.text_baniere_titleSubject.next(message.res["author"].fullName);
            this.authServices.text_baniereSubject.next(message.project_lib+" - ORGANISE -\t \tsujet: "+message.res["tags"][0].libelle);
            this.authServices.text_baniere_body.next("Message : "+(message.res["text"] ? message.res["text"] : "document"));
            this.authServices.newMessageInstantSubject.next(false);
            console.log("face 1"+this.new_message);
            this.trierProject(message.action);
          }*/ else if(message.type == "DISABLE" && message.author !== this.currentUser && (message.copy.includes(this.currentUser) || (message.recipient == this.currentUser))){
            if((this.messageService.getProjectid() === message.action) && (this.inProject == true && this.inFace == true)){
              this.authServices.obreceiveResponse.next(message.id);
            }
            this.updateProjectNotifications(message.action).subscribe((total) => {
              this.projectMapFace[message.action]=total;
            });
          } else if(message.type == "DISABLE" && message.author !== this.currentUser && (message.private == false) ){
            if((this.messageService.getProjectid() === message.action) && (this.inProject == true && this.inFace == true)){
              this.authServices.obreceiveResponse.next(message.id);
            }
          } else if(message.type == "MARKADMIN" && message.res == this.currentUser && (this.messageService.getProjectid() == message.action)){
            this.authServices.obreceiveAdmin.next("1");
            this.new_message = false;
            this.new_message_instant = false;
            this.new_invitation = false;
            this.new_coadmin = true;
            this.authServices.newCoadminSubject.next(true);
            this.authServices.text_baniere_titleSubject.next(message.recipient);
            this.authServices.ban_typeSubject.next("vous a marqué comme Co-Admin dans ce Projet");
          } else if(message.type == "DELETEADMIN" && message.res == this.currentUser && (this.messageService.getProjectid() == message.action)){
            this.authServices.obDeletedmin.next("1");
            this.new_message = false;
            this.new_message_instant = false;
            this.new_invitation = false;
            this.new_coadmin = true;
            this.authServices.newCoadminSubject.next(true);
            this.authServices.text_baniere_titleSubject.next(message.recipient);
            this.authServices.ban_typeSubject.next("vous a enlevé le droit Co-Admin dans ce Projet");
          } else if(message.type == "MARKREADRESPONSE" && (this.messageService.getProjectid() === message.action) && message.author == this.currentUser && this.ongletId != message.recipient ){
            console.log("MARQUER LU SOCKET RESPONSE");
            if(this.inProject == true && this.inFace == true){
              console.log("DANS ORGANISE");
              this.authServices.obResponseLu.next(message.id);
            }
          } else if(message.type == "MARKREADQUESTION" && (this.messageService.getProjectid() === message.action)  && message.author == this.currentUser && this.ongletId != message.recipient ){
            console.log("MARQUER LU SOCKET QUESTION");
            if(this.inProject == true && this.inFace == true){
              console.log("DANS ORGANISE");
              this.authServices.obQuestionLu.next(message.id);
            }
          } else if(message.type == "MARKREADCPYRESPONSE" && (this.messageService.getProjectid() === message.action) && message.author == this.currentUser && this.ongletId != message.recipient ){
            console.log("MARQUER LU SOCKET RESPONSE");
            if(this.inProject == true && this.inFace == true){
              console.log("DANS ORGANISE");
              this.authServices.obResponseCpyLu.next(message.id);
            }
          } else if(message.type == "MARKREADCPYQUESTION" && (this.messageService.getProjectid() === message.action)  && message.author == this.currentUser && this.ongletId != message.recipient ){
            console.log("MARQUER LU SOCKET QUESTION");
            if(this.inProject == true && this.inFace == true){
              console.log("DANS ORGANISE");
              this.authServices.obQuestionCpyLu.next(message.id);
            }
          } else if(message.type == "MARKREADCOMMENT" && (this.messageService.getProjectid() === message.action)  && message.author == this.currentUser && this.ongletId != message.recipient ){
            console.log("MARQUER LU SOCKET COMMENT");
            if(this.inProject == true && this.inFace == true){
              console.log("DANS ORGANISE");
              this.authServices.obCommentLu.next(message);
            }
          } else if((message.type == "ADDSUBJECT") && (this.messageService.getProjectid() === message.action) && (this.ongletId != message.recipient) && (this.inProject == true && this.inFace == true)){
            this.authServices.obSubAdd.next(message.res);
          } else if((message.type == "RENAMESUBJECT") && (this.messageService.getProjectid() === message.action) && (this.ongletId != message.recipient) && (this.inProject == true && this.inFace == true)){
            this.authServices.obSubRename.next(message.res);
          } else if (message.type == "COMMENT" && message.author !== this.currentUser && ((this.messageService.getProjectid() !== message.action) || this.inProject == false || (this.inProject == true && this.inFace == false))/* || this.newProjectId == undefined)*/) {
            this.updateProjectNotifications(message.action).subscribe((total) => {
              this.projectMapFace[message.action]=total;
              this.projectLists.filter(item => item.id == message.action)[0].userProject[0].lastmsg = message.date;
              this.projectLists.filter(item => item.id == message.action)[0].userProject[0].lasttext = message.text;
              this.ban_msg = message.res.number;
              this.authServices.newMessageSubject.next(true);
              this.authServices.ban_typeSubject.next("comment");
              this.authServices.ban_projSubject.next(message.action);
              this.authServices.text_baniere_titleSubject.next(message.res["author"].fullName);
              this.authServices.text_baniereSubject.next(message.project_lib);
              this.authServices.text_baniere_sujet.next(message.res["tags"][0].libelle);
              this.authServices.text_baniere_type.next("ORGANISEE");
              this.authServices.text_baniere_body.next((message.res["text"] ? message.res["text"] : "document"));
              this.authServices.text_baniere_format.next("Commentaire");
              this.playSound();
              this.showNotificationComment();
              this.authServices.newMessageInstantSubject.next(false);
              this.trierProject(message.action);
            });
          } else if(message.type == "COMMENT" && message.author !== this.currentUser && this.messageService.getProjectid() === message.action){
            this.authServices.obreceiveComment.next(message.id);
            
            this.updateProjectNotifications(message.action).subscribe((total) => {
              this.projectMapFace[message.action]=total;
              this.projectLists.filter(item => item.id == message.action)[0].userProject[0].lastmsg = message.date;
              this.projectLists.filter(item => item.id == message.action)[0].userProject[0].lasttext = message.text;
              this.authServices.newMessageSubject.next(true);
              this.authServices.ban_typeSubject.next("comment");
              this.authServices.ban_projSubject.next(message.action);
              this.authServices.text_baniere_titleSubject.next(message.res["author"].fullName);
              this.authServices.text_baniereSubject.next(message.project_lib);
              this.authServices.text_baniere_sujet.next(message.res["tags"][0].libelle);
              this.authServices.text_baniere_type.next("ORGANISEE");
              this.authServices.text_baniere_body.next((message.res["text"] ? message.res["text"] : "document"));
              this.authServices.text_baniere_format.next("Commentaire");
              this.playSound();
              this.showNotificationComment();
              this.authServices.newMessageInstantSubject.next(false);
              this.trierProject(message.action);
            });
          } else if(message.type == "RESPONSE" && message.author !== this.currentUser && (message.copy.includes(this.currentUser) || (message.recipient == this.currentUser))){
            if((this.messageService.getProjectid() === message.action) && (this.inProject == true && this.inFace == true)){
              this.authServices.obreceiveResponse.next(message.id);
            }
            this.playSound();
            this.showNotificationResponse();
            this.projectLists.filter(item => item.id == message.action)[0].userProject[0].lastface = message.date;
            this.projectLists.filter(item => item.id == message.action)[0].userProject[0].lasttext = message.text;
            this.projectLists.filter(item => item.id == message.action)[0].userProject[0].lastmsg = message.date;
            if(message.copy.includes(this.currentUser)){
              this.updateProjectNotifications(message.action).subscribe((total) => {
                this.projectMapFace[message.action]=total;
              })
            }
              this.authServices.newMessageSubject.next(true);
              this.authServices.ban_typeSubject.next("face");
              this.authServices.ban_projSubject.next(message.action);
              this.authServices.text_baniere_titleSubject.next(message.res["author"].fullName);
              this.authServices.text_baniereSubject.next(message.project_lib);
              this.authServices.text_baniere_type.next("ORGANISEE");
              this.authServices.text_baniere_sujet.next(message.res["tags"][0].libelle);
              this.authServices.text_baniere_body.next((message.res["text"] ? message.res["text"] : "document"));
              this.authServices.text_baniere_format.next("Réponse");
              this.authServices.newMessageInstantSubject.next(false);
            this.trierProject(message.action);
            this.authServices.obreceiveResponse.next(message.id);
            
          } else if(message.type == "INVITATION" && message.author !== this.currentUser && message.text === this.currentUser){
            this.authServices.obInviteInstant.next(1);
              this.authServices.newMessageInvSubject.next(true);
              this.authServices.ban_typeSubject.next("invitation");
              this.authServices.ban_projSubject.next(null);
              this.authServices.text_baniere_titleSubject.next(message.recipient);
              this.authServices.text_baniereSubject.next(message.project_lib);
              this.authServices.text_baniere_type.next("Invitation");
              this.authServices.text_baniere_body.next("Invitation");
              this.authServices.text_baniere_format.next("Invitation");
              this.authServices.newMessageInstantSubject.next(false);
            this.playSound();
          } else if(message.type == "INVITATIONPROJ" && message.author !== this.currentUser && (message.copy.includes(this.currentUser))){
            //console.log("invitations "+JSON.stringify(message));
            this.authServices.obInviteInstant.next(1);
              this.authServices.newMessageInvSubject.next(true);
              this.authServices.ban_typeSubject.next("invitation");
              this.authServices.text_baniere_type.next("invitation");
              this.authServices.ban_projSubject.next(null);
              this.authServices.text_baniere_titleSubject.next(message.recipient);
              this.authServices.text_baniereSubject.next(message.project_lib);
              this.authServices.text_baniere_body.next("Invitation");
              this.authServices.text_baniere_format.next("Invitation");
              this.authServices.newMessageInstantSubject.next(false);
            this.playSound();
          } else if(message.type == "DELPROJECT"){
            console.log("received del "+JSON.stringify(message));
            const index = this.projectLists.findIndex(item => item.id === message.action);
            if (index !== -1) {
              const elementAaa = this.projectLists.splice(index, 1)[0];
              this.perPage -= 1;
              if(this.messageService.getProjectid() !== message.action){
                this.authServices.newMessageSubject.next(true);
                this.authServices.ban_typeSubject.next("delproject");
                this.authServices.ban_projSubject.next(null);
                //this.authServices.text_baniere_titleSubject.next(message.author);
                this.authServices.text_baniereSubject.next(null);
                this.authServices.text_baniere_titleSubject.next("Le projet a été supprimé");
                this.authServices.newMessageInstantSubject.next(false);
              }
            }
            if(this.messageService.getProjectid() == message.action){
              this.authServices.newMessageSubject.next(true);
              //alert("Le projet a été supprimé !!");this.authServices.newMessageSubject.next(true);
                this.authServices.ban_typeSubject.next("delproject");
                this.authServices.ban_projSubject.next(null);
                //this.authServices.text_baniere_titleSubject.next(message.author);
                this.authServices.text_baniereSubject.next(null);
                this.authServices.text_baniere_titleSubject.next("Le projet a été supprimé");
                this.authServices.newMessageInstantSubject.next(false);
                setTimeout(() => {
              this.router.navigate(['/homes/wlc']);
                }, 2000);
            }
          }
          
        })
      }
      /*
      this.total_project = res['hydra:totalItems'];
      if(res['hydra:view']['hydra:next']){
        //this.loadProject(res['hydra:view']['hydra:next'].replace("/api", ""));
      }
      else if(res['hydra:totalItems'] == this.projectLists.length){
        this.messageService.setListProject(this.projectLists);
        this.messageService.setProjectMap(this.projectMap);
        this.loadNotifProjet();
      }*/
      this.messageService.setProjectMapFace(this.projectMapFace);
    }); 
  }

  redirectBaniere(){
    var path = null;
    this.new_message = false;
    this.new_message_instant = false;
    this.new_invitation = false;
    this.new_coadmin = false;
    if(this.ban_type == "instant"){
      path = '/project/'+this.ban_proj+'/openmsg';
    } else if(this.ban_type == "face"){
      path = '/project/details/'+this.ban_proj+'/'+this.ban_msg+'/QUESTION';
    } else if(this.ban_type == "comment"){
      path = '/project/details/'+this.ban_proj+'/'+this.ban_msg+'/COMMENT';
    } else if(this.ban_type == "invitation"){
      this.authServices.obInviteClic.next("1");
      return;
    } else {
      return;
    } 
    if(path){
      this.router.navigate([path]);
    }
  }

  closeCnx(){
    this.prob_cnx = false;
    this.interceptor.setConnexionAlert(false);
  }

  closeScreen(){
    this.new_message = false;
    this.new_message_instant = false;
    this.new_invitation = false;
    this.new_coadmin = false;
  }

  closeScreenInstant(){
    this.new_message_instant = false;
    this.new_message = false;
    this.new_invitation = false;
    this.new_coadmin = false;
  }

  closeInvitations(){
    this.new_message_instant = false;
    this.new_message = false;
    this.new_invitation = false;
    this.new_coadmin = false;
  }

  closeCoadmin(){
    this.new_message_instant = false;
    this.new_message = false;
    this.new_invitation = false;
    this.new_coadmin = false;
  }

  playSound(): void {
    var sound = this.messageService.getSound();
    if(sound){
      const audioPlayer = document.getElementById('audioPlayer') as HTMLAudioElement;
      audioPlayer.play();
    }
    console.log("played"+sound)
  }

  Total(a, b){
    return Number(a) + Number(b);
  }

  filterProjects(val : boolean){
    console.log("val "+val)
    if(val == true){
      this.filterRead = true;
      this.authServices.getProjectsNotified().subscribe(res => {
        var result = res['hydra:member'];
        result = result.sort(function (a, b) {
          if (a.userProject[0].lastmsg > b.userProject[0].lastmsg) { return -1; }
          if (a.userProject[0].lastmsg < b.userProject[0].lastmsg) { return 1; }
          return 0;
        })
        this.projectLists = result;
      });
    } else {
      this.filterRead = false;
      this.projectLists = this.messageService.getListProject();
    }
    //this.projectLists.filter(item => (this.projectMapFace[item.id] > 0));
  }

  setupSearchSubscription() {
    console.log("setupSearchSubscription : ");
    this.searchTerms.pipe(
      debounceTime(50), // Adjust the debounce time as needed
      switchMap(() => this.searchProjects())
    ).subscribe(results => {
      this.displayedProjects = [];
      this.displayedProjects = results['hydra:member'];
      //console.log("jssson : "+JSON.stringify(this.displayedProjects))
      console.log("jssson 1111 : "+JSON.stringify(this.displayedProjects))
      this.displayedProjects.map((value, index) => {
        console.log("jssson 222 : "+JSON.stringify(value))
          value.userProject = value.userProject.filter(userProject => {
            return userProject.user && userProject.user.id === this.currentUser;
          });
          this.updateProjectNotifications(value.id).subscribe((total) => {
            this.projectMapFace[value.id]=total;
          });
      });
    });
  }

  /*onSearch() {
    if (this.searchProject.trim() === '' || this.searchProject == null) {
      this.searchProject = null;
      return;
    }
    var shownprojects = [...this.projectLists];
    this.displayedProjects = this.filterLocalProjects(shownprojects, this.searchProject);
    
    this.authServices.searchProjects(this.searchProject).subscribe(serverResults => {
      this.displayedProjects = [];
      this.displayedProjects = this.mergeResults(this.displayedProjects, serverResults['hydra:member']);
      //this.displayedProjects = serverResults['hydra:member'];
    });
  }*/

  onScrollsearch(event: any): void { // TO DO 
    const container = this.scrollMeprojsearch.nativeElement;
    const atTop = container.scrollTop > (container.scrollHeight - (container.scrollHeight / 3));
    //if (atTop && !this.loadingMessagesTop && this.dataloaded == false && this.startedPoint == false && this.canload == true) {
    if (atTop && this.canloadagainsearch && this.isdataloadedsearch == false) {
      this.isdataloadedsearch = true;
      this.scrollpositionsearch = container.scrollHeight;
      this.getSearchProjects();
    }
  }

  onSearch() {
    console.log("okok : "+this.searchProject)
    if (this.searchProject.trim() === '' || this.searchProject == null) {
      this.searchProject = null;
    } else this.searchTerms.next(this.searchProject);
    /*
    var shownprojects = [...this.projectLists];
    this.displayedProjects = this.filterLocalProjects(shownprojects, this.searchProject);
    
    this.authServices.searchProjects(this.searchProject).subscribe(serverResults => {
      this.displayedProjects = [];
      this.displayedProjects = this.mergeResults(this.displayedProjects, serverResults['hydra:member']);
      //this.displayedProjects = serverResults['hydra:member'];
    });*/
  }

  searchProjects() {
    this.pageSearch = 1;
    return this.authServices.searchProjects(this.searchProject).pipe(
      map(serverResults => {
        return serverResults;
      })
      //map(serverResults => this.mergeResults(this.displayedProjects, serverResults['hydra:member']))
    );
  }

  getSearchProjects() {
    //var shownprojects = [...this.displayedProjects];
    console.log("length brefore : "+this.displayedProjects.length);
    this.pageSearch += 1;
    this.authServices.searchProjects(this.searchProject, this.pageSearch).subscribe((res)=>{
      //this.displayedProjects.concat(res['hydra:member']);
    console.log("length res member : "+res['hydra:member']);
      //.displayedProjects = [...this.displayedProjects, ...res['hydra:member']]
      this.displayedProjects = res['hydra:member']
      if(res['hydra:member'].length < 20){
        this.canloadagainsearch = false;
      }
      console.log("length after : "+this.displayedProjects.length);
      setTimeout(() => {
        this.restoreScrollPositionSearch();
      }, 0);
    });
  }

  restoreScrollPositionSearch() {
    const container = this.scrollMeprojsearch.nativeElement;
    container.scrollTop = this.scrollpositionsearch - 50;
    this.isdataloadedsearch = false;
    console.log("position search : "+this.scrollpositionsearch);
    console.log("scrollHeight search : "+container.scrollHeight);
  }

  filterLocalProjects(shownprojects, searchText: string): any[] {
    searchText = searchText.toLowerCase();
    return shownprojects.filter(it => it['libelle'].toLowerCase().includes(searchText));
  }

  mergeResults(localResults: any[], serverResults: any[]): any[] {
    const existingIds = new Set(localResults.map(item => item.id));
    const filteredServerResults = serverResults.filter(item => !existingIds.has(item.id));
    return [...localResults, ...filteredServerResults];
  }

  openDialogProjectNotif(event, idprojet, libelle, url, face_nbr){
    const tab = { notifmode : true, id: idprojet}
    this.authServices.obNotifiedMode.next(tab);
    // TO DOOOOOO 
    //alert("nbr : "+face_nbr)
    //if(face_nbr == 0) {
      this.currentProjectId = idprojet;
      this.projectId = idprojet;
      this.messageService.setProjectid(idprojet);
      this.projectChosen.emit(idprojet);
      this.projectChanged.emit(libelle);
      localStorage.setItem('notified', "true");
      if(url){
        this.imageChanged.emit(url);
      } else {
        this.imageChanged.emit(null);
      }
      this.dialog.closeAll();
      this.router.navigate(['/project/'+idprojet]);
     // return;
    //}
    /*
    var sentNotifCount = 0;
    var receiveNotifCount = 0;
    var copyNotifCount = 0;
    var commentNotifCount = 0;
    event.stopPropagation();
    console.log("teciii "+idprojet);
    if(face_nbr > 0) {
      if (this.showTool == true) {
        this.showTool = false;
        this.messageService.setShowNotifTuto(false);
      }
      this.authServices.getAllProjectNotification(0, 1, 10, idprojet).subscribe((res) => {
        sentNotifCount = res['hydra:member']['sent'];
        receiveNotifCount = res['hydra:member']['receive'];
        copyNotifCount = res['hydra:member']['copy'];
        commentNotifCount = res['hydra:member']['comment'];

        const dialogRef = this.dialog.open(DashboardDialogProjectNotif, {
          width: '1400px',
          data: { sentNotifCount: sentNotifCount, receiveNotifCount: receiveNotifCount, copyNotifCount: copyNotifCount, commentNotifCount: commentNotifCount, idprojet: idprojet }
        });
    
        dialogRef.afterClosed().subscribe(result => {
          //console.log({result:result});
          if (result)
          {
            if (result.type == 'COMMENT')
            {
              console.log("slip 1")
              this.router.navigate(['/project/details/' + result.id + '/' + result.number + '/' + result.type], {
                skipLocationChange: true
              });
            }
            else
            {
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['project/details/' + result.id + '/' + result.consult + '/' + result.type]);
              });
            }
          }
        });
      });
    }
    this.router.navigate(['/project/'+idprojet]);
    */
    
  }
  

  loadNotifProjet(){
    this.authServices.getNotification().subscribe(res => {
      this.notificationload = <any>res;
      for (var i = 0; i < this.projectLists.length; i++) {
        for (var index = 0; index < this.notificationload.length; index++) {
          if(this.notificationload[index].id == this.projectLists[i].id) {
            this.projectLists[i].notif = this.notificationload[index].total;
          }
        }
      }
    });
  }

  updDialog(): void{
    this.updclicked = !this.updclicked;
  }

  quitCreateProject(){
    this.createproject = null;
    this.edit = false;
  }

  quitContact(){
    this.createproject = 2;
    this.edit = false;
  }

  onGroupCreated(result: any): void {
    this.createproject = null; 
    if (result){ 
      this.projectLists.unshift(result);
      console.log("resuuuult    "+JSON.stringify(result.userProject));
      var usersinvited = [];
      if(result.userProject.length > 0){
        result.userProject.forEach(element => {
          if(element.user && element.user.id) usersinvited.push(element.user.id);
          
        })
      }
      this.socketService.sendInstant({
        id: "0",
        action: "0",
        type: "INVITATIONPROJ",
        author: this.currentUser,
        recipient: result.creator.fullName,
        text: "",
        copy: usersinvited,
        private: false,
        res: "",
        project_lib: result.libelle,
        date: result.dateCreated
      });
      if(result.type !== "CHILD") this.projectListsParent.unshift(result)
      this.currentProjectId = result.id;
      this.projectChosen.emit(result.id);
      this.initIoConnection(result.id);
      this.projectMap[result.id] = 0;
      this.projectMapFace[result.id] = 0;
      this.messageService.setProjectMap(this.projectMap);
      this.messageService.setProjectMapFace(this.projectMapFace);
      this.router.navigate(['/project/'+result.id+'/openmsg']);
    }
  }
  
  openDialog(): void {
      this.createproject = 1;
      /*
      this.showtooltip = false;
      this.showTool = false; 
      this.messageService.setShowCreateProjectTuto(false);
      this.messageService.setShowCreateProjectTutoIsFalse();
      if (this.messageService.getListProject().length > 0) {
        this.projectListsParent = [];
        this.projectLists = this.messageService.getListProject();
        this.projectLists.forEach(element => {
          if(element.type !== "CHILD") this.projectListsParent.push(element);
        });
      }
      const dialogRef = this.dialog.open(dashboarddialogCompo, {
        panelClass: 'dashboarddialog-container',
        width: '700px',
        data: {projetName: this.projetName, objectif: this.objectif, projects: this.projectListsParent}
      });

      dialogRef.afterClosed().subscribe(result => {
         if (result){ 
          this.projectLists.unshift(result);
          console.log("resuuuult    "+JSON.stringify(result.userProject));
          var usersinvited = [];
          if(result.userProject.length > 0){
            result.userProject.forEach(element => {
              if(element.user && element.user.id) usersinvited.push(element.user.id);
              
            })
          }
          this.socketService.sendInstant({
            id: "0",
            action: "0",
            type: "INVITATIONPROJ",
            author: this.currentUser,
            recipient: result.creator.fullName,
            text: "",
            copy: usersinvited,
            private: false,
            res: "",
            project_lib: result.libelle,
            date: result.dateCreated
          });
          if(result.type !== "CHILD") this.projectListsParent.unshift(result)
          this.currentProjectId = result.id;
          this.projectChosen.emit(result.id);
          this.initIoConnection(result.id);
          this.projectMap[result.id] = 0;
          this.projectMapFace[result.id] = 0;
          this.messageService.setProjectMap(this.projectMap);
          this.messageService.setProjectMapFace(this.projectMapFace);
          this.router.navigate(['/project/'+result.id+'/openmsg']);
        }
      })*/
  }

  fctinfoProjet(idproject: any, libelle: string): void{
    
    const dialogRef = this.dialog.open(projectInfoDialog, {
      width: '500px',
      data: {"id" : idproject, "name": libelle}
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed fctinfoProjet '+result); 
        if (result && result.deleteReturn) {
        }else if (result) {
          (<HTMLInputElement>document.getElementById('project_'+idproject)).innerHTML = result.libelle;
          if(this.currentProjectId && this.currentProjectId == idproject){
            console.log("msg detect");
            this.messageService.setLibelleProject(result.libelle);
            //(<HTMLInputElement>document.getElementById('libproject_'+idproject)).innerHTML = result.libelle;
          }
          this.projectFull = result;
          this.updclicked = false;
          this.projectLists.forEach((value,index)=>{
            if(value.id==idproject) this.projectLists[index].libelle = result.libelle;
          });
          //this.authService.obRefreshProjet.next();
       }  

    });
    
  }

  clickSuppMethod(id, libelle) {
    if(confirm("Voulez vraiment suppprimer le projet "+libelle)) {
      this.onClickConfirmeDelete(id, libelle);
    }
  }

  onClickConfirmeDelete(id, libelle): void{
    this.authServices.deleteProject(id).subscribe(
      res => {  
        this.perPage -= 1;
        this.socketService.sendInstant({
            id: id,
            action: id,
            type: "DELPROJECT",
            author: this.userData.fullName,
            recipient: this.userData.id,
            text: '',
            copy: "",
            private: false,
            res: "",
            project_lib: libelle,
            date: ""
        });
        /*
        document.getElementById('project_'+id).parentElement.parentElement.parentElement.remove();
        //alert("Le projet a été supprimé par l'admin!!");
        if(this.messageService.getProjectid() == id){
          this.router.navigate(['/homes']);
        }*/
      },
      (error) => {
        alert('Erreur ! : ' + error)
      }
    );
  }
 
  fctprojectChosen(projectId, libelle, url){
    if(this.messageService.getProjectid() == projectId && (this.inProject == true) && (this.inFace == true)){
      this.authServices.obchangeinterface.next(1);
    }
    //this.authServices.initHeader.emit("1");
    this.currentProjectId = projectId;
    this.projectId = projectId;
    this.messageService.setProjectid(projectId);
    this.projectChosen.emit(projectId);
    this.projectChanged.emit(libelle);
    if(url){
      console.log("url sent "+url)
      this.imageChanged.emit(url);
    } else {
      console.log("url not sent ")
      this.imageChanged.emit(null);
    }
    //this.authServices.obNotifiedMode.next(false);
    const instantbtn = document.getElementById('instant_lib') ;
    if (!instantbtn) {
      this.inFace = true;
    }
    if(this.inFace) {
      this.authServices.scrollTop.next("1")
    }
    const nbr_messages = this.projectLists.filter(item => item.id == projectId)[0].userProject[0].number;
    //console.log("iddd : "+this.currentProjectId);
    //this.messageService.setProjectMap(this.projectMap);
    this.authServices.obreadMessage.next(nbr_messages);
    this.authServices.updateNotifications(nbr_messages);
    //this.projectMap[projectId] = 0;
    this.projectLists.filter(item => item.id == projectId)[0].userProject[0].number = 0;
    //this.router.navigate(['/project/'+projectId]);
    this.router.navigate(['/project/'+projectId+'/openmsg']);
    
  }

  fctprojectChosenSearch(projectId, libelle, url){
    if(this.messageService.getProjectid() == projectId && (this.inProject == true) && (this.inFace == true)){
      this.authServices.obchangeinterface.next(1);
    }
    //this.authServices.initHeader.emit("1");
    this.currentProjectId = projectId;
    this.projectId = projectId;
    this.messageService.setProjectid(projectId);
    this.projectChosen.emit(projectId);
    this.projectChanged.emit(libelle);
    if(url){
      console.log("url sent "+url)
      this.imageChanged.emit(url);
    } else {
      console.log("url not sent ")
      this.imageChanged.emit(null);
    }
    const instantbtn = document.getElementById('instant_lib') ;
    if (!instantbtn) {
      this.inFace = true;
    }
    if(this.inFace) {
      this.authServices.scrollTop.next("1")
    }
    const nbr_messages = this.displayedProjects.filter(item => item.id == projectId)[0].userProject[0].number;
    if(this.projectLists.filter(item => item.id == projectId)[0]){
      const nbr_messages2 = this.projectLists.filter(item => item.id == projectId)[0].userProject[0].number;
      this.authServices.obreadMessage.next(nbr_messages);
      this.authServices.updateNotifications(nbr_messages);
      this.projectLists.filter(item => item.id == projectId)[0].userProject[0].number = 0;
    }
    this.displayedProjects.filter(item => item.id == projectId)[0].userProject[0].number = 0;
    //this.router.navigate(['/project/'+projectId]);
    this.router.navigate(['/project/'+projectId+'/openmsg']);
    
  }

  
  loadPartProjects(loaded = null) {
      var progress = 0;
      if(this.projectLists.length > 0){
        this.perPage = this.projectLists.length;
        this.pageIndex = 2;
        this.loadingMessagesTop = true;
      }
      var uri = '/projects?perPage='+this.perPage+'&page='+this.pageIndex+'&order[motionAt]=desc';
      console.log("start project "+this.projectLists.length)
      this.authServices.getProjects(uri).subscribe(res => {
        var result = res['hydra:member'];
        var lengthresults = res['hydra:totalItems'] ;
        
        if(result.length == 0 && this.pageIndex == 1) {
          this.showtooltip = true;
          //this.authServices.endLoading.emit("1");
        }
        progress += 5;  // 100 / 20
        console.log("prooog "+progress)
        if(lengthresults == 0 && this.pageIndex == 1) this.authServices.endLoading.emit("1");
        const observables = result.map((value, index) => {
          return new Observable((observer) => {
            value.userProject = value.userProject.filter(userProject => {
              return userProject.user && userProject.user.id === this.currentUser;
            });
            this.updateProjectNotifications(value.id).subscribe((total) => {
              this.projectMapFace[value.id]=total;
              if(this.pageIndex === 1) this.authServices.updateProgress(progress);
              observer.next(); // Signaler la fin de l'observable
              observer.complete();
            });
          });
        });
        forkJoin(observables).subscribe(() => {
          result = result.sort(function (a, b) {
            if (a.userProject[0].lastmsg > b.userProject[0].lastmsg) { return -1; }
            if (a.userProject[0].lastmsg < b.userProject[0].lastmsg) { return 1; }
            return 0;
          })
          this.loadingMessagesTop = false;
          this.authServices.endLoading.emit("1");
          this.projectLists = this.projectLists.concat(result);
          this.messageService.setListProject(this.projectLists);
          this.messageService.setProjectMapFace(this.projectMapFace);
          this.isdataloaded == false;
          if(!result || result.length == 0 || result.length < this.perPage) this.canloadagain = false;
          setTimeout(() => {
            this.restoreScrollPosition();
          }, 0);
        });
        //if(this.projectLists.length == 0) this.authServices.endLoading.emit("1");
      });
    }

  onScroll(event: any): void { // TO DO 
    console.log("scrolleddd")
    if(this.filterRead == true) return;
    const container = this.scrollMeproj.nativeElement;
    //const atTop = container.scrollTop > (container.scrollHeight - (container.scrollHeight / 3));
    const atTop = container.scrollHeight - container.scrollTop - 100 < container.clientHeight;
    console.log("atTop 0 "+atTop)
    //if (atTop && !this.loadingMessagesTop && this.dataloaded == false && this.startedPoint == false && this.canload == true) {
    if (atTop && this.canloadagain && this.isdataloaded == false) {
      this.isdataloaded = true;
      this.scrollposition = container.scrollHeight;
      this.loadPartProjects(true);
    }
  }

  restoreScrollPosition() {
    if(this.scrollMeproj){
      const container = this.scrollMeproj.nativeElement;
      container.scrollTop = this.scrollposition - 50;
      this.isdataloaded = false;
    }
  }

  scrollToTop() {
    //window.scrollTo({ top: 0, behavior: 'smooth' });
    //this.myList.scrollToIndex(0, 'smooth');
    //const container = this.scrollMeproj.nativeElement;
    //this.scrollMeproj.nativeElement.scrollTop = -50;
    if (this.scrollMeproj) {
      this.scrollMeproj.nativeElement.scrollTop = 0;
      //this.scrollMeproj.nativeElement.scrollToTop();
    }
    /*
    const element = document.getElementById('proj_'+this.projectLists[0].id);
    this.allproject.nativeElement.scrollIntoView({block: "center"});
    */
    //window.scroll(0,0);
    /*
    if (element) {
      console.log("scrolled"+this.dataSource[0].id)
      element.classList.add('smooth-scroll');
      element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      element.scrollTop -= 60;
      element.classList.remove('smooth-scroll'); // Retirez la classe après l'animation
    }
  */
  }

  getAvatarlUrl(avatarLink){
    return environment.server_url+avatarLink;
  }

  openDialogInfo(){
    const dialogRef = this.dialog.open(tutoCreateProjectdialog, {
      width: '850px',
      data:{}
    });
    dialogRef.afterClosed().subscribe(result => {
    })
  }


}


@Component({
  selector: 'app-dashboarddialog',
  templateUrl: 'dashboarddialog.component.html',
   styleUrls: ['dashboarddialog.component.scss']
})
export class dashboarddialogCompo implements OnInit{
  

  //@ViewChild('stepper') stepper: MatStepper;
  @ViewChild('addProjectForm', {static: true}) addProjectForm;
  firstFormGroup: FormGroup;
  firstFormGroupOne: FormGroup;
  firstFormGroupTwo: FormGroup;
  projetNameOnemodel = "";
  addingMember = [];
  hasMemberAdding = false;
  projectUsers = [];
  userStatus = [];
  subjectGroup = [];
  projectId = null;
  projects: Array<any> = [];
  members: Array<any> = [];
  tags: Array<any> = [];
  projectDetails = null;
  contacts = [];
  fromcsv = false;
  searchContact: any;
  arrayEmailInvitation = [];
  arrayEmailInvitationWithId = [];
  arraySubject = [];
  groupselected = 0;
  public projectCtrl: FormControl = new FormControl();
  saveProject = null;
  firstLoad = false;
  newProjectSubject = '';
  secteur = 'clients'
  currenteDate: any;
  showComfirmMessage = false;
  protected _onDestroy = new Subject<void>();
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  tagFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  createProjectMode = 1;
  memberdialog = 0;
  dupplicatestyle = 0;
  stepstyle = 0;
  projectFilter = "0";
  typeproject = '0';
  mailadded = "";
  duppselected = 1;
  projecttype = "PARENT";
  currentUser = null;
  @ViewChild('firstnextstep', {static: false}) firstnextstep: MatButton;
  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;
  //@ViewChild('fileInput') fileInput;
  btnclicked = false;
  @Input() data: any;
  img: any;
  imageFil = null;
  @Output() groupCreated = new EventEmitter<void>();
  pageIndex = 1;
  perPage = 50;
  @ViewChild('scrollprojDupp', { static: false }) scrollprojDupp: ElementRef;
  @ViewChild('scrollprojDuppSearch', { static: false }) scrollprojDuppSearch: ElementRef;
  isdataloaded = false;
  canloadagain = true;
  isdataloadedsearch = false;
  loadingDuppTop = false;
  scrollposition = 0;
  scrollpositionsearch = 0;
  searchProjectDupp : any = null;
  private searchTermsDupp = new Subject<string>();
  pageSearch = 1;
  displayedProjectsDupp: Array<any> = [];
  canloadagainsearch = true;

  constructor(
    //public dialogRef: MatDialogRef<dashboarddialogCompo>,
    //@Inject(MAT_DIALOG_DATA) public data: DialogData,
    private authService: AuthService,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private _formBuilder: FormBuilder) {

      /*this.authService.getContacts().subscribe( dataContact => {
        this.contacts = dataContact['hydra:member'];
      });*/
      this.contacts = [];
      this.loadPartProjects();
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      //this.projects = data.projects;
      this.loadContact();
      this.projectCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterProject();
      });
      

      /*
      let userStatus = []; 
        for (let index = 0; index < this.projectUsers.length; index++) {
          if(this.projectUsers[index]['user'])
          userStatus[this.projectUsers[index]['user']['id']] = this.projectUsers[index]['status'];
        }
      this.userStatus = userStatus;*/
      
    }

    onScrollsearch(event: any): void { // TO DO 
      const container = this.scrollprojDuppSearch.nativeElement;
      const atTop = container.scrollTop > (container.scrollHeight - (container.scrollHeight / 3));
      //if (atTop && !this.loadingMessagesTop && this.dataloaded == false && this.startedPoint == false && this.canload == true) {
      if (atTop && this.canloadagainsearch && this.isdataloadedsearch == false) {
        this.isdataloadedsearch = true;
        this.scrollpositionsearch = container.scrollHeight;
        this.getSearchProjects();
      }
    }

    onSearchDupp() {
      console.log("dupp : "+this.searchProjectDupp)
      if (this.searchProjectDupp.trim() === '' || this.searchProjectDupp == null) {
        this.searchProjectDupp = null;
      } else this.searchTermsDupp.next(this.searchProjectDupp);
      /*
      var shownprojects = [...this.projectLists];
      this.displayedProjects = this.filterLocalProjects(shownprojects, this.searchProject);
      
      this.authServices.searchProjects(this.searchProject).subscribe(serverResults => {
        this.displayedProjects = [];
        this.displayedProjects = this.mergeResults(this.displayedProjects, serverResults['hydra:member']);
        //this.displayedProjects = serverResults['hydra:member'];
      });*/
    }

    getSearchProjects() {
      var shownprojects = [...this.displayedProjectsDupp];
      this.pageSearch += 1;
      this.authService.searchProjects(this.searchProjectDupp, this.pageSearch).subscribe((res)=>{
        //this.displayedProjects.concat(res['hydra:member']);
        this.displayedProjectsDupp = [...this.displayedProjectsDupp, ...res['hydra:member']]
        if(res['hydra:member'].length < 20){
          this.canloadagainsearch = false;
        }
        setTimeout(() => {
          this.restoreScrollPositionSearchDupp();
        }, 0);
      });
    }

    restoreScrollPositionSearchDupp() {
      const container = this.scrollprojDuppSearch.nativeElement;
      container.scrollTop = this.scrollpositionsearch - 50;
      this.isdataloadedsearch = false;
    }

    onScrollDupp(event: any): void { // TO DO 
      const container = this.scrollprojDupp.nativeElement;
      //const atTop = container.scrollTop > (container.scrollHeight - (container.scrollHeight / 3));
      const atTop = container.scrollHeight - container.scrollTop - 100 < container.clientHeight;
      console.log("atTop 0 "+atTop)
      //if (atTop && !this.loadingMessagesTop && this.dataloaded == false && this.startedPoint == false && this.canload == true) {
      if (atTop && this.canloadagain && this.isdataloaded == false) {
        this.isdataloaded = true;
        this.scrollposition = container.scrollHeight;
        this.loadPartProjects(true);
      }
    }

    onScrollDuppSearch(event: any): void { // TO DO 
      const container = this.scrollprojDuppSearch.nativeElement;
      //const atTop = container.scrollTop > (container.scrollHeight - (container.scrollHeight / 3));
      const atTop = container.scrollHeight - container.scrollTop - 100 < container.clientHeight;
      console.log("atTop 0 "+atTop)
      //if (atTop && !this.loadingMessagesTop && this.dataloaded == false && this.startedPoint == false && this.canload == true) {
      if (atTop && this.canloadagain && this.isdataloaded == false) {
        this.isdataloaded = true;
        this.scrollposition = container.scrollHeight;
        this.loadPartProjects(true);
      }
    }

    loadPartProjects(loaded = null) {
      var progress = 0;
      if(this.projects.length > 0){
        this.perPage = this.projects.length;
        this.pageIndex = 2;
        this.loadingDuppTop = true;
      }
      var uri = '/projects?perPage='+this.perPage+'&page='+this.pageIndex+'&order[motionAt]=desc';
      this.authService.getProjects(uri).subscribe(res => {
        var result = res['hydra:member'];
        var lengthresults = res['hydra:totalItems'] ;
          this.loadingDuppTop = false;
          this.projects = this.projects.concat(result);
          this.isdataloaded == false;
          if(!result || result.length == 0 || result.length < this.perPage) this.canloadagain = false;
          setTimeout(() => {
            this.restoreScrollPositionDupp();
          }, 0);
        });
    }

    restoreScrollPositionDupp() {
      if(this.scrollprojDupp && this.scrollprojDupp.nativeElement){
        const container = this.scrollprojDupp.nativeElement;
        container.scrollTop = this.scrollposition - 50;
        this.isdataloaded = false;
      }
    }

    triggerFileInputClick(): void {
      this.fileInput.nativeElement.click();
    }
  
    onFileSelected(event: any): void {
      const file: File = event.target.files[0];
  
      if (file) {
        const reader = new FileReader();
  
        reader.onload = (e: any) => {
          const csv: string = e.target.result;
          this.processCSV(csv);
        };
  
        reader.readAsText(file);
      }
    }

    onProfilePicClick(): void {
      const fileInput = document.getElementById('fileInput') as HTMLElement;
      fileInput.click();
    }

    fctAddByCsvValue(email=null, name=null, lastName=null, metier=null) {
      // stop here if form is invalid
      if (email == '' || (email == JSON.parse(localStorage.getItem('currentUser'))['email'])) {
        return;
      }
  
      if (this.contacts.find(i => i.email === email)){
        console.log("useer "+this.contacts.find(i => i.email === email))
        //this.addingMember.push(newUser);
        //this.addingMember.push(this.contacts.find(i => i.id === newUser.id));
        var newUser = this.contacts.find(i => i.email === email);
        this.addingMember.push(newUser);
        this.userStatus[newUser['email']] = 'PENDING';
        this.hasMemberAdding = true;
      } else {
        this.addingMember.push({"givenName": email, "familyName": "", "interne": false, "name": name, "lastName": lastName, "metier": metier}); 
        this.hasMemberAdding = true;
      }
  
    }

    processCSV(csv: string): void {
      const lines = csv.split('\n'); // Séparer par lignes
      this.members = [];
      this.addingMember = [];
      this.fromcsv = true;

      for (let i = 1; i < lines.length; i++) {
        const line = lines[i];
        const fields = line.split(';'); // Séparer par colonnes
        console.log("fields : "+fields);
        if (fields.length === 4) { // Assurez-vous qu'il y a trois colonnes
          const email = fields[0].trim();
          const name = fields[1].trim();
          const lastName = fields[2].trim();
          const metier = fields[3].trim();
          console.log("email : "+email+ " name : "+name+" lastName : "+lastName+" metier : "+metier);
          this.fctAddByCsvValue(email, name, lastName, metier);
        }
      }
      return;
    }
  
    processCSV2(csv: string): void {
      const emails = csv.split(';');
      this.members = [];
      this.addingMember = [];
      //emails.shift();
      emails.forEach(email => {
        this.fctAddByCsvValue(email.trim());  
        //console.log(email.trim())
      });
    }


/*
    ngAfterViewInit() {
      var firststep = document.getElementById("firststep");
      var parent =  this.chooseTypeProject();
      firststep.addEventListener("keydown", function (e) {
        if (e.code === "Enter") { 
          console.log("yeah");
          parent;
          e.stopPropagation()
          parent;
          
        } 
    });
    }*/

    protected filterProject() {
      if(this.saveProject == null){
        this.saveProject = this.projects;
      }
  
      let search = this.projectCtrl.value;
      if (!search) {
        this.projects = this.saveProject;
        return;
      } else {
        search = search.toLowerCase();
        this.projects = this.saveProject.filter(bank => bank.libelle.toLowerCase().indexOf(search) > -1);
      }
    }

    ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
    }

    radioButtonChange(data: MatRadioChange) {
      this.typeproject = data.value;
      this.createProjectMode = 3;
      if(data.value === '2'){
        this.projecttype = "CHILD";
      }
    }

    chooseTypeProject(){
      if (this.firstFormGroupOne.get('projetNameOne').value.trim().length > 0 ){
        this.authService.existProject(this.firstFormGroupOne.get('projetNameOne').value.trim()).subscribe(res => {
          //console.log('retour liste project!!! ');
          var resultat = ''+res;
          if(resultat === 'true'){
            alert("Ce Projet existe déjà");
          } else {
            this.createProjectMode = 3; 
            this.typeproject = '2';
            this.dupplicatestyle = 1;
            this.projecttype = "CHILD";
          }
        });  
       
      }
    }

    nextStepGroupMember(){
      this.createProjectMode = 3; 
      this.typeproject = "2";
    }

    mergeResults(localResults: any[], serverResults: any[]): any[] {
      const existingIds = new Set(localResults.map(item => item.id));
      const filteredServerResults = serverResults.filter(item => !existingIds.has(item.id));
      return [...localResults, ...filteredServerResults];
    }

    searchProjects() {
      this.pageSearch = 1;
      console.log("seach dupp "+this.searchProjectDupp)
      return this.authService.searchProjects(this.searchProjectDupp).pipe(
        map(serverResults => {
          return serverResults;
        })
        //map(serverResults => this.mergeResults(this.displayedProjectsDupp, serverResults['hydra:member']))
      );
    }

    setupSearchSubscription() {
      console.log("setupSearchSubscription dupp : ");
      this.searchTermsDupp.pipe(
        debounceTime(50), // Adjust the debounce time as needed
        switchMap(() => this.searchProjects())
      ).subscribe(results => {
        this.displayedProjectsDupp = [];
        this.displayedProjectsDupp = results['hydra:member'];
        //this.displayedProjectsDupp = results;
      });
    }

    ngOnInit() {
      this.secteur = localStorage.getItem('secteur');
      console.log("secteuuur   "+this.secteur);
      this.setupSearchSubscription();
      this.arrayEmailInvitation = [];
      this.arrayEmailInvitationWithId = [];
      this.addingMember = [];
      this.createProjectMode = 1;
      this.currenteDate = new Date();
      /*
      this.firstFormGroup = new FormGroup({
        projetNameOne: new FormControl(''),
        projetName: new FormControl(''),
        clickCreate: new FormControl(''),
        objectif: new FormControl(''),
        deadLine: new FormControl(''),
        sujectList: new FormControl(''),
     });*/
      this.firstFormGroup = this._formBuilder.group({
        projetName: ['', ],
        clickCreate: ['', [Validators.required, Validators.requiredTrue]],
        objectif: [''],
        deadLine: [''],
        sujectList: [[]]
      });
      
      this.firstFormGroupOne = new FormGroup({
        projetNameOne: new FormControl('')
     });
     this.firstFormGroupOne = new FormGroup({
        projetNameOne: new FormControl('')
     });
      this.firstFormGroupOne = this._formBuilder.group({
        projetNameOne: ['', Validators.required]
      });
      this.firstFormGroupTwo = this._formBuilder.group({
        mailadded: ['', ]
      });
    }

    getGroupData(idProject){
      this.addingMember = [];
      //this.search()
      if (idProject != this.projectId) {
        this.projectId = idProject;
        this.loadMembers(idProject);
        this.groupselected = 1;
      } else {
        this.groupselected = 0;
        this.projectId = null;
      }
    }

    

    loadFilterElement(){
      console.log("on loadFilterElement");
      if (this.firstLoad == true) {
        this.loadProject();
      }
      this.firstLoad = false;
    }

    getTags(){
         this.authService.getAllTags().then((res)=>{
           console.log({allTags:res})
         }).catch((err)=>{
           console.log(err)
         })
    }

    loadProject(url?: string){
      url = url ? url : undefined;
      this.authService.getProjects(url).subscribe(res => {
        this.projects = this.projects.concat(res['hydra:member']);
        this.projects = this.projects.sort(function (a, b) {
            if (a.libelle.toLowerCase() < b.libelle.toLowerCase()) { return -1; }
            if (a.libelle.toLowerCase() > b.libelle.toLowerCase()) { return 1; }
            return 0;
        })
        if(res['hydra:view']['hydra:next']){
          console.log("next load")
          this.loadProject(res['hydra:view']['hydra:next'].replace("/api", ""));
        }
      }); 
    }

    loadContact(url?: string) {
      url = url ? url : undefined;
      //this.authService.getContacts(url).subscribe(res => {
      this.authService.getCompagnyMember(this.currentUser['compId']).subscribe(res => {
        this.contacts = this.contacts.concat(res['hydra:member']);
        this.contacts = this.contacts.sort(function (a, b) {
          if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) { return -1; }
          if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) { return 1; }
          return 0;
      })
      this.contacts.forEach((value,index)=>{
        value.interne = true;
        //console.log("value "+JSON.stringify(value));
        if(value.id== this.currentUser.id) this.contacts.splice(index,1);
      });
      });
    }

    onNoClick(): void {
      //this.dialogRef.close();
    }

    goBack(): void {
      this.createProjectMode = 1;
      this.addingMember = [];
    }

    goBackListe(): void {
      console.log("clicked")
      this.addingMember = [];
      this.groupselected = 0;
      this.createProjectMode = 3;
      this.dupplicatestyle = 0;
      this.typeproject = '2';
      this.fromcsv = false;
    }


    onSubmitProject(){
      this.btnclicked = true;
      this.firstFormGroup.patchValue({
        clickCreate: true 
      });

      //console.log('firstFormGroup value => ' , this.firstFormGroup.value);

      if (this.firstFormGroupOne.get('projetNameOne').value.trim().length == 0 ){
        this.firstFormGroup.patchValue({
          projetName: '' 
        });
      } else {
        this.firstFormGroup.patchValue({
          projetName: this.firstFormGroupOne.get('projetNameOne').value.trim() 
        });
      }

       // stop here if form is invalid
      if (this.firstFormGroup.invalid) {
        this.firstFormGroup.patchValue({
          clickCreate: '' 
        });
        return;
      }

     
      let result = this.firstFormGroup.value;
      
      let arrayUserProject = [];
      let arrayTagProject = [];

      if(this.dupplicatestyle === 1 || this.dupplicatestyle === 2 || this.createProjectMode === 2){
        for (var i = 0; i < this.addingMember.length; i++) {
          if(this.addingMember[i].interne === true){
            arrayUserProject.push({"user":"/api/users/"+this.addingMember[i].id});
          } else if(this.addingMember[i].interne === false){
            //arrayUserProject.push({"email":this.arrayEmailInvitation[i]});
            arrayUserProject.push({"email":this.addingMember[i].givenName, "prenom": this.addingMember[i].name, "nom": this.addingMember[i].lastName, "metier": this.addingMember[i].metier});
          }
        }
      }

      /*
      if(this.dupplicatestyle === 1 || this.dupplicatestyle === 2 || this.createProjectMode === 2){
        for (var i = 0; i < this.arrayEmailInvitation.length; i++) {
          arrayUserProject.push({"email":this.arrayEmailInvitation[i]});
        }
      }*/
      /*
      arrayTagProject.push({"libelle":"General"});

      if(this.dupplicatestyle === 1 || this.dupplicatestyle === 3){
        for (var i = 0; i < this.subjectGroup.length; i++) {
          arrayTagProject.push({"libelle":this.subjectGroup[i]});
        }
      }
      */
      
      this.authService.createProject(result.projetName, result.objectif, result.deadLine, arrayTagProject, arrayUserProject, this.projecttype, this.projectId, this.imageFil, this.fromcsv).subscribe(
        res => {
          this.projectDetails = res;
          this.projectId = res['id'];
          this.groupCreated.emit(this.projectDetails);
          this.fromcsv = false;
          //this.dialogRef.close(this.projectDetails);
          //this.stepper.selectedIndex = 1;
        },
        (error) => {
          alert('Erreur ! : ' + error)
        }
      );

    }

    validateClick(): void{
      this.addProjectForm.nativeElement.submit();
    // this.dialogRef.close(result);
    }


  fctAddcontactByMail() {

    // stop here if form is invalid
    if (this.emailFormControl.status == 'INVALID') {
      return;
    }

    var result = this.emailFormControl.value;

 
     this.authService.getUserInfo(result).subscribe(
        res => {
          
          var arrayUser = res["hydra:member"];
          
          if (arrayUser.length > 0) {
            var newUser = arrayUser[0];
            this.addingMember.push(newUser);
            this.userStatus[newUser['id']] = 'PENDING';
            this.hasMemberAdding = true;
            this.emailFormControl.reset();
            this.arrayEmailInvitationWithId.push(result);  
          }
          else
          {
            this.arrayEmailInvitation.push(result);   
            this.hasMemberAdding = true;
            this.emailFormControl.reset();
          }
          
          this.searchContact = "";
         
        },
        (error) => {
          alert('Erreur ! : ' + error)
        }
      );

  }


  fctAddByMailValue(mail) {

    // stop here if form is invalid
    if (mail == '') {
      return;
    }

    var result = mail;

 
     this.authService.getUserInfo(result).subscribe(
        res => {
          
          var arrayUser = res["hydra:member"];
          
          if (arrayUser.length > 0 ) {
            var newUser = arrayUser[0];
            console.log("given  "+JSON.stringify(newUser))
            newUser.interne = true;
            if (this.contacts.find(i => i.id === newUser.id)){
            //this.addingMember.push(newUser);
            //this.addingMember.push(this.contacts.find(i => i.id === newUser.id));
              if(!(this.addingMember.includes(this.contacts.find(i => i.id === newUser.id)))){
                this.addingMember.push(this.contacts.find(i => i.id === newUser.id));
                console.log("user  =  "+JSON.stringify(this.addingMember));
                console.log("cont  =  "+JSON.stringify(this.contacts));
                this.userStatus[newUser['id']] = 'PENDING';
                this.hasMemberAdding = true;
                this.emailFormControl.reset();
                this.arrayEmailInvitationWithId.push(result); 
              }
            }  else { 
              this.addingMember.push(newUser);
              this.userStatus[newUser['id']] = 'PENDING';
              this.hasMemberAdding = true;
              this.emailFormControl.reset();
            }
          }
          else
          {
            //this.arrayEmailInvitation.push();  
            console.log("res "+result);
            this.addingMember.push({"givenName": result, "familyName": "", "interne": false}); 
            this.hasMemberAdding = true;
            this.emailFormControl.reset();
          }
          
          this.searchContact = "";
         
        },
        (error) => {
          alert('Erreur ! : ' + error)
        }
      );

  }


  clickConfirm(){
    //this.dialogRef.close(this.projectDetails);
    this.createProjectMode = 4;
    this.showComfirmMessage = false; 
  }

  clickRefus(){
    this.showComfirmMessage = false;
  }

  goToSubject(){
    //console.log("Project ID go : "+this.projectDetails);
    if (this.firstFormGroupOne.get('projetNameOne').value.trim().length > 0 ){
      this.authService.existProject(this.firstFormGroupOne.get('projetNameOne').value.trim()).subscribe(res => {
        //console.log('retour liste project!!! ');
        var resultat = ''+res;
        if(resultat === 'true'){
          alert("Ce Projet existe déjà");
        } else {
          this.createProjectMode = 2; 
        }
      });  
     
    }
    else
    {
      alert("Veuillez entrer un nom pour l'activité");
    }
   
  } 

  goToDupplicate(id: any){
    this.fromcsv = false;
    if ((this.firstFormGroupOne.get('projetNameOne').value.trim().length > 0 ) && id !== "0"){
      this.authService.existProject(this.firstFormGroupOne.get('projetNameOne').value.trim()).subscribe(res => {
        //console.log('retour liste project!!! ');
        var resultat = ''+res;
        if(resultat === 'true'){
          alert("Ce Projet existe déjà");
        } else {
          this.createProjectMode = 3; 
          this.dupplicatestyle = id;
          if(id === 1){
            this.stepstyle = 1;
          }
        }
      }); 
    } else if (this.firstFormGroupOne.get('projetNameOne').value.trim().length === 0)
    {
      this.projectFilter = "0";
      alert("Veuillez entrer un nom pour l'activité" +this.projectFilter);
    } 
    /*
    //console.log("Project ID go : "+this.projectDetails);
    if (this.firstFormGroup.get('projetName').value.trim().length > 0 ){
       this.createProjectMode = 3; 
       this.dupplicatestyle = id;
    }
    else
    {
      alert("Veuillez entrer un nom pour l'activité");
    }*/
   
  } 

  nextStep(){
    this.stepstyle = 2;
  } 

  prevStep(){
    this.stepstyle = 1;
  } 

  goToDupp(){
    //this.projectId = projectId;
    this.createProjectMode = 4 ;
    this.groupselected = 0;
    this.projectId = null;
    //this.loadMembers(projectId);
  }

  goToGroup(projectId){
    //this.projectId = projectId;
    this.createProjectMode = 4 ;
    this.loadMembers(projectId);
  }

  loadMembers(id: string){
    this.authService.getDataDuplicated(id).subscribe(res => {
      //console.log('retour liste project!!! ');
      this.members = [];
      this.addingMember = [];
      this.members = this.members.concat(res['userProject']);
      this.tags = this.tags.concat(res['tag']);
      let userStatus = []; 
      let subjectGroup = []; 
        for (let index = 0; index < this.members.length; index++) {
          if(this.members[index]['user']){
            var user = this.members[index]['user'];
            var id = user.substr(user.lastIndexOf('/') + 1);
            userStatus.push(id);
            if(this.contacts.find(i => i.id === id)){
              this.addingMember.push(this.contacts.find(i => i.id === id));
            }  
          } else if (this.members[index]['email']){
            this.addingMember.push({"givenName": this.members[index]['email'].replace("/api/users/", ""), "familyName": "", "interne": false}); 
          }
          if(index == this.members.length - 1){
            this.addingMember = this.addingMember.sort(function (a, b) {
              if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) { return -1; }
              if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) { return 1; }
              return 0;
            })
          }
        }
        
        this.userStatus = userStatus;
        for (let index = 0; index < this.tags.length; index++) {
          if(this.tags[index]['libelle']){
            var tag = this.tags[index]['libelle'];
            //var id = tag.substr(tag.lastIndexOf('/') + 1);
            subjectGroup.push(tag);
          }
        }
        this.subjectGroup = subjectGroup;
      //this.total_project = res['hydra:totalItems'];
    }); 
  }

  goToAddMember(){
    //console.log("Project ID go : "+this.projectDetails);
    this.addSujet();
    this.createProjectMode = 3; 
  }

  goToGoal(){
    //console.log("Project ID go : "+this.projectDetails);
     if (this.hasMemberAdding == false) {
        this.showComfirmMessage = true;
     }
     else
     {
        this.createProjectMode = 4;
        this.showComfirmMessage = false; 
     }

  }

  goToDeadline(){
    this.createProjectMode = 5;
    //console.log(" goToDeadline ==> : "+this.createProjectMode);
  }

  addSujet(){
    if (this.newProjectSubject.trim().length > 0) {
      var newTheme = this.newProjectSubject; 
      this.arraySubject.push({"libelle":newTheme});
      this.firstFormGroup.patchValue({
        sujectList: this.arraySubject   
      });
      this.newProjectSubject = '';
    }
    //console.log('firstFormGroup value => ' , this.firstFormGroup.value);
  }

  deleteSuject(sujectItem){
    
    const index = this.arraySubject.indexOf(sujectItem, 0);
    if (index > -1) {
        this.arraySubject.splice(index, 1);
    }

    this.firstFormGroup.patchValue({
      sujectList: this.arraySubject   
    });
    
    //console.log('deleteSuject firstFormGroup value => ' , this.firstFormGroup.value);
  
  }

  goToProject(){
    //console.log("Project ID go : "+this.projectDetails);
     if (this.hasMemberAdding == false) {
        this.showComfirmMessage = true;
     }
     else
     {
        //this.dialogRef.close(this.projectDetails);
     }
    
  }

  getFormattedName(member: { givenName: string; familyName: string }): string {
    const firstInitial = member.familyName.charAt(0).toUpperCase();
    return `${member.givenName} .${firstInitial}`;
  }

  fileChangeEvent(event: any): void {
    const dialogRef = this.dialog.open(croppedimagedialog, {
      width: '700px',
      data: {event: event}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result !== null) {
        this.img = result.event;
        const reader = new FileReader();
        this.imageFil = result.fileToReturn;
        /*
        var files = event.target.files;

        // loop through files
        for (var i = 0; i < files.length; i++) {
          this.imageFil = files[i];
        }
        this.imageFil = files[i];
        */

        reader.readAsDataURL(this.imageFil);
          
        reader.onload = () => {
          // need to run CD since file load runs outside of zone
          this.cd.markForCheck();
          //this.img = reader.result;
        }; 
      }
    });
      //this.imageChangedEvent = event;
  }

  fctAddMember(){
      
    //this.showTool = false; 
    //this.messageService.setShowCreateProjectTuto(false);
    //this.messageService.setShowCreateProjectTutoIsFalse();
    this.memberdialog = 1;
    
    const dialogRef = this.dialog.open(dashboardmemberdialog, {
      panelClass: 'dashboarddialog-container',
      data: {idcompany: 1, companyUser: this.contacts}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.memberdialog = 0;
      this.mailadded = result;
      this.fctAddByMailValue(this.mailadded);  
    });
    /*
    if (this.mailadded != ""){
      this.fctAddByMailValue(this.mailadded);
      this.mailadded = "";
    }*/
  }

  fctCheckMember(userInfo){ 
    if(this.addingMember && !this.addingMember.includes(userInfo)){
      this.fctAdMember(userInfo);
    } else this.fctRemoveUser(userInfo);
  }

  fctAdMember(userInfo){ 
    this.addingMember.push(userInfo);
    this.userStatus[userInfo['id']] = 'PENDING';
    this.hasMemberAdding = true;
    this.searchContact = "";
  }

  fctAdTag(userInfo){ 
    this.subjectGroup.push(userInfo);
  }

  fctAdTagRow(){ 
    if (this.tagFormControl.value == '') {
      return;
    }
    var result = this.tagFormControl.value;
    this.subjectGroup.push(result);
    this.tags.push({"libelle": result});
    this.tagFormControl.reset();
  }

  fctRemoveTag(userInfo){
    const index = this.subjectGroup.indexOf(userInfo);
    if (index > -1) {
      this.subjectGroup.splice(index, 1);
    }
  }

  fctRemoveUser(userInfo){
    const index = this.addingMember.indexOf(userInfo);
    if (index > -1) {
      this.addingMember.splice(index, 1);
      delete this.userStatus[userInfo['id']];
      if (this.addingMember.length > 0) {
       this.hasMemberAdding = true;
      }
      else
      {
        this.hasMemberAdding = false;
      }
      this.searchContact = "";
    }
  }

  getAvatarlUrl(avatarLink){
    return environment.server_url+avatarLink;
  }

  openDialogInfo(){
    //console.log(' dans la fonction openDialogInfo');
    const dialogRef = this.dialog.open(tutoCreateProjectdialog, {
      width: '850px',
      data:{}
    });
    dialogRef.afterClosed().subscribe(result => {
    })
  }

}


@Component({
  selector: 'tutoCreateProjectdialog',
  templateUrl: 'tutoCreateProjectdialog.html',
   styleUrls: ['tutoCreateProjectdialog.scss']
})
export class tutoCreateProjectdialog implements OnInit{
  lang='fr';

  constructor(
    public dialogRef: MatDialogRef<tutoCreateProjectdialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private messageService: MessageService,
    private _formBuilder: FormBuilder) {
     
  }

  ngOnInit() {
    this.messageService.getTranslate().subscribe((data) => {
      this.lang= data;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}



@Component({
  selector: 'dashboardmemberdialog.component',
  templateUrl: 'dashboardmemberdialog.component.html',
   styleUrls: ['dashboardmemberdialog.component.scss']
})
export class dashboardmemberdialog implements OnInit{
  

  //@ViewChild('stepper') stepper: MatStepper;
  @ViewChild('addProjectForm', {static: true}) addProjectForm;
  firstFormGroup: FormGroup;
  addingMember = [];
  hasMemberAdding = false;
  projectUsers = [];
  userStatus = [];
  subjectGroup = [];
  projectId = null;
  projects: Array<any> = [];
  members: Array<any> = [];
  tags: Array<any> = [];
  projectDetails = null;
  contacts = [];
  searchContact: any;
  arrayEmailInvitation = [];
  arrayEmailInvitationWithId = [];
  arraySubject = [];
  groupselected = 0;
  public projectCtrl: FormControl = new FormControl();
  saveProject = null;
  firstLoad = false;
  newProjectSubject = '';
  currenteDate: any;
  showComfirmMessage = false;
  protected _onDestroy = new Subject<void>();
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  tagFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  createProjectMode = 1;
  dupplicatestyle = 0;
  stepstyle = 0;
  projectFilter = "0";
  typeproject = '0';
  duppselected = 1;

  constructor(
    public dialogRef: MatDialogRef<dashboarddialogCompo>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private authService: AuthService,
    private translate: TranslateService,
    location: Location, 
    private messageService: MessageService,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder) {

      /*this.authService.getContacts().subscribe( dataContact => {
        this.contacts = dataContact['hydra:member'];
      });*/
      this.contacts = [];
      this.projects = data.projects;

      /*
      let userStatus = []; 
        for (let index = 0; index < this.projectUsers.length; index++) {
          if(this.projectUsers[index]['user'])
          userStatus[this.projectUsers[index]['user']['id']] = this.projectUsers[index]['status'];
        }
      this.userStatus = userStatus;*/
      
    }

    ngOnDestroy() {
      this._onDestroy.next();
      this._onDestroy.complete();
    }

    ngOnInit() {
      this.arrayEmailInvitation = [];
      this.arrayEmailInvitationWithId = [];
      this.addingMember = [];
      this.createProjectMode = 1;
      this.currenteDate = new Date();
      this.firstFormGroup = this._formBuilder.group({
        projetName: ['', Validators.required],
        clickCreate: ['', [Validators.required, Validators.requiredTrue]],
        objectif: [''],
        deadLine: [''],
        sujectList: [[]]
      });

      this.messageService.getTranslate().subscribe((data) => {
        this.setVar();
        this.setTheGuide();
      });
    }

    setVar(){
      
    }
  
  
    setTheGuide(){
      setTimeout(() => {}, 2000);
    }


  fctAddcontactByMail() {

    // stop here if form is invalid
    if (this.emailFormControl.status == 'INVALID') {
      return;
    }

    var result = this.emailFormControl.value;

 
     this.authService.getUserInfo(result).subscribe(
        res => {
          
          var arrayUser = res["hydra:member"];
          
          if (arrayUser.length > 0) {
            var newUser = arrayUser[0];
            this.addingMember.push(newUser);
            this.userStatus[newUser['id']] = 'PENDING';
            this.hasMemberAdding = true;
            this.emailFormControl.reset();
            this.arrayEmailInvitationWithId.push(result);  
          }
          else
          {
            this.arrayEmailInvitation.push(result);   
            this.hasMemberAdding = true;
            this.emailFormControl.reset();
          }
          
          this.searchContact = "";
         
        },
        (error) => {
          alert('Erreur ! : ' + error)
        }
      );

  }

  validate(){
    if (this.firstFormGroup.get('projetName').value.trim().length > 0 ){
      this.projectDetails = this.firstFormGroup.get('projetName').value;
      this.dialogRef.close(this.projectDetails);
    }
    
    
    
    
  }


  onNoClick(): void {
    this.dialogRef.close();
  }


  nextStep(){
    this.stepstyle = 2;
  } 

  prevStep(){
    this.stepstyle = 1;
  } 

  goToProject(){
    //console.log("Project ID go : "+this.projectDetails);
     if (this.hasMemberAdding == false) {
        this.showComfirmMessage = true;
     }
     else
     {
        this.dialogRef.close(this.projectDetails);
     }
    
  }
/*
  fctAdMember(userInfo){ 
    this.addingMember.push(userInfo);
    this.userStatus[userInfo['id']] = 'PENDING';
    this.hasMemberAdding = true;
    this.searchContact = "";
  }*/


  fctAddMember(){
      
    //this.showTool = false; 
    //this.messageService.setShowCreateProjectTuto(false);
    //this.messageService.setShowCreateProjectTutoIsFalse();
    const dialogRef = this.dialog.open(addMemberCompany, {
      width: '700px',
      data: {idcompany: 1, companyUser: this.contacts}
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed result add membre '+result);    
    });
}


}

